import { action, observable, runInAction, makeObservable } from 'mobx';
import { differenceBy, uniq } from 'lodash';

import { parseParamString } from 'App/components/Filters/filterStoreUtils';
import { searchUsers } from 'Settings/Sections/Users/services/UsersService';
import { handleErrorResponse } from 'App/services/utilities/error.utils';
import errorFormatter from 'App/services/utilities/errorFormatter';
import ManageUsersServiceFactory from './ManageUsersServiceFactory';

const initialData = {
  lists: {
    users: [],
    bindUsers: [],
  },
  scrollProps: {
    users: {
      hasMore: false,
      query: { cursor: '', ordering: '-id' },
    },
    bindUsers: {
      hasMore: false,
      query: { offset: 0, limit: 20 },
    },
  },
};

export default class ManageUsersStore {
  constructor(endpoint) {
    makeObservable(this, {
      requestInProgress: observable,
      errors: observable,
      lists: observable,
      scrollProps: observable,
      addUserBindings: action.bound,
      removeUserBindings: action.bound,
      removeAllBindings: action.bound,
      updateUserBindings: action.bound,
      fetchListData: action.bound,
      resetLists: action.bound,
    });

    this.service = new ManageUsersServiceFactory(endpoint);
  }

  requestInProgress = {
    users: false,
    bindUsers: false,
    updateBindings: false,
  };

  errors = {
    users: null,
    bindUsers: null,
    updateBindings: null,
  };

  lists = {
    users: [],
    bindUsers: [],
    bindCount: 0,
  };

  scrollProps = {
    users: {
      hasMore: false,
      query: { cursor: '', ordering: '-id' },
    },
    bindUsers: {
      hasMore: false,
      query: { offset: 0, limit: 20, ordering: '-id' },
    },
  };

  initialBindings = [];

  // manage users modal actions
  async addUserBindings(selectedUser) {
    this.lists.bindUsers = [selectedUser, ...this.lists.bindUsers];
    this.lists.users = filterSelectedUsers(this.lists.users, this.lists.bindUsers);
  }

  removeUserBindings(selectedUser) {
    this.lists.users = [selectedUser, ...this.lists.users];
    this.lists.bindUsers = filterSelectedUsers(this.lists.bindUsers, this.lists.users);

    return this.lists.bindUsers.length;
  }

  removeAllBindings() {
    this.lists.bindUsers = [];
  }

  async updateUserBindings({ addedUsers, removedUsers, id, isRemoveAll }) {
    const promises = [];
    const added = differenceBy(addedUsers, removedUsers, 'id');
    const removed = differenceBy(removedUsers, addedUsers, 'id');
    if (added.length && !isRemoveAll) {
      promises.push(this.service.addBindings(id, formatUsersPayload(added)));
    }
    if (removed.length && !isRemoveAll) {
      promises.push(this.service.removeBindings(id, formatUsersPayload(removed)));
    }
    if (isRemoveAll) {
      promises.push(this.service.removeAllBindings(id));
    }
    if (promises.length) {
      this.requestInProgress.updateBindings = true;
      this.errors.updateBindings = null;
      try {
        await Promise.all(promises);
        runInAction(() => {
          this.requestInProgress.updateBindings = false;
        });
      } catch (err) {
        runInAction(() => {
          const errorData = Array.isArray(err.response.data[0])
            ? err.response.data[0]
            : errorFormatter(err.response.data);
          this.errors = { ...this.errors, updateBindings: errorData };
          this.requestInProgress.updateBindings = false;
        });
        handleErrorResponse(err);
      }
    }
  }

  async fetchListData(key, searchTerm, isOnScroll, id) {
    if (this.requestInProgress[key] && !this.scrollProps[key].hasMore) return;
    if (!isOnScroll) this.scrollProps[key].query.cursor = '';
    this.requestInProgress[key] = true;
    try {
      const params = {
        search: searchTerm,
        ...this.scrollProps[key].query,
      };
      let response = {};
      if (id) {
        response = await this.service.fetchUserBindings(id, params);
      } else {
        response = await searchUsers(params);
      }
      const { results, next, count } = response.data;
      const { cursor, offset } = parseParamString(next);

      const allUsersIds = [...this.lists.users, ...this.lists.bindUsers].map(item => item.id);
      const filteredUsers = results.filter(item => !allUsersIds.includes(item.user?.id || item.id));

      runInAction(() => {
        this.scrollProps[key].hasMore = !!next;
        this.scrollProps[key].query = getQuery(this.scrollProps, key, cursor, offset);
        if (key === 'bindUsers') {
          this.lists.bindCount = count || 0;
          this.initialBindings = isOnScroll
            ? uniq([...this.initialBindings, ...filteredUsers.map(user => user.id)])
            : filteredUsers.map(user => user.id);
        }
        const formattedData = key === 'users'
          ? filterSelectedUsers(filteredUsers, this.lists.bindUsers)
          : formatBindUsers(filteredUsers);
        this.lists[key] = isOnScroll ? [...this.lists[key], ...formattedData] : formattedData;
        this.requestInProgress[key] = false;
      });
    } catch (errors) {
      runInAction(() => {
        this.errors[key] = errors.data;
        this.requestInProgress[key] = false;
      });
      handleErrorResponse(errors);
    }
  }

  resetLists() {
    this.lists = initialData.lists;
    this.scrollProps = initialData.scrollProps;
    this.searchListTerm = initialData.searchListTerm;
    this.errors = {
      users: null,
      bindUsers: null,
      updateBindings: null,
    };
  }
}

function filterSelectedUsers(users, bindUsers) {
  return differenceBy(users, bindUsers, 'id');
}

function formatBindUsers(data) {
  return data.map(record => ({
    bindingId: record.id,
    ...record.user,
  }));
}

function getQuery(scrollProps, key, cursor, offset) {
  if (key === 'users') return { ...scrollProps[key].query, cursor: cursor || '' };
  if (key === 'bindUsers') return { ...scrollProps[key].query, offset: offset || 0 };
  return scrollProps[key];
}

function formatUsersPayload(users) {
  return users.map(user => ({ user: user.id }));
}
