import { currencyFormater } from 'App/services/utilities/formatters/currency';
import SidepanelFooter from 'App/components/SidepanelFooter';
import { array, bool, func, object } from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { get, isEmpty, isEqual } from 'lodash';
import { Checkbox, InfoBox, Input, Select, Sidepanel, Space } from 'tc-biq-design-system';
import { cleanEmptyValues } from 'App/services/utilities/form.utils';
import { CONTACT_STATUS_LABEL as contactStateLabel } from 'App/enums/contactStatusLabel';
import withErrorBoundary from 'App/components/hoc/withErrorBoundary';
import { inject, observer } from 'mobx-react';
import { handleErrorResponse } from 'App/services/utilities/error.utils';
import Loader from 'App/components/Loader';
import { formatBiqFields } from 'App/services/utilities/formatters/format-biq-fields';
import GridComponentFactory from 'App/components/grid/GridComponentFactory';
import { isFeatureEnabled } from 'App/services/utilities/isFeatureEnabled.utils';
import { toJS } from 'mobx';

const text = {
  TITLE: contact => `Edit trading accounts for ${contact.first_name} ${contact.last_name}`,
  SUCCESS: 'Trading account changed.',
  SUCCESS_CREATE: 'Added new trading account for contact.',
  CHOOSE_ACCOUNT: 'Choose account',
  CHOOSE_GROUP: 'Choose group',
  CURRENCY: 'Currency',
  SERVICE: 'Service',
  ACCOUNT_TYPE: 'Account type',
  LEVERAGES: 'Leverages',
  LABEL: 'Trading Account Type',
  TRADING_ACCOUNT_ID: 'Trading Account Id',
  CANNOT_EDIT: 'This account cannot be edited',
  GENERAL_ERROR: 'Something went wrong!',
  CANNOT_CREATE: 'Sorry, you can\'t create more accounts for this trading platform',
  BUTTON_LABELS: {
    confirm: 'Confirm',
    cancel: 'Cancel',
  },
};

const FORM_KEY = 'tradingAccountForm';

export function getCurrency(account, hideTypeCurrency) {
  return (account && account.currency && !hideTypeCurrency) ? (account.currency.symbol || '') : '';
}

export function getBackendFromAccount(account, hideTypeCurrency) {
  let backend = '';
  if (account && account.backend && account.backend.name) {
    backend = account.backend.name;
    if (!hideTypeCurrency) {
      backend += ` - ${account.backend.mode}`;
    }
  }
  return backend;
}

export function tradingAccountDisplay(account, hideTypeCurrency, withBalance) {
  if (account) {
    const currency = getCurrency(account, hideTypeCurrency);
    let balance = '';
    if (withBalance) {
      balance = account.balance ? account.balance.WithdrawableAmount : '';
    }

    return `${getId(account)}, ${currency && `${currency}, `}${getBackendFromAccount(account, hideTypeCurrency)}${balance && `, ${currencyFormater(balance, currency)}`}`;
  }
  return '';
}

export function getId(account = {}) {
  let id = account.display_external_id || account.external_id;
  if (account.backend && account.backend.type && account.backend.type === 'cloudtrade') {
    if (account.meta && account.meta.Alphacode) id = account.meta.Alphacode;
  }

  return id || '';
}

const customFooter = (execute, close, submitInProgress, disableSubmit) => () => (
  <SidepanelFooter
    submitInProgress={submitInProgress}
    disableSubmit={disableSubmit}
    execute={execute}
    close={close}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
    formId={FORM_KEY}
  />
);

const tableConfig = contactId => ({
  tableUrl: `/contacts/${contactId}/wallets/`,
  tableKey: 'contactWallets',
});

const propTypes = {
  sidepanelManager: object.isRequired,
  contact: object.isRequired,
  formFields: object,
  fieldErrors: object,
  formValues: object,
  tradingAccounts: array,
  backends: array.isRequired,
  leverages: array.isRequired,
  labels: array.isRequired,
  isNew: bool.isRequired,
  requestInProgress: object.isRequired,
  fetchTradingAccountFields: func.isRequired,
  fetchTradingAccounts: func.isRequired,
  fetchTradingBackends: func.isRequired,
  fetchLeverages: func.isRequired,
  fetchLabels: func.isRequired,
  updateFieldValue: func.isRequired,
  resetFieldsData: func.isRequired,
  createTradingAccount: func.isRequired,
  editTradingAccount: func.isRequired,
};

const defaultProps = {
  formFields: null,
  fieldErrors: null,
  formValues: null,
  tradingAccounts: [],
};

/**
 * Component user as a form for creating/editing trading accounts
 * Added as a part of BIQ actions implementation
 */
const TradingAccountForm = ({
  sidepanelManager,
  contact,
  formFields,
  fieldErrors,
  formValues,
  tradingAccounts,
  backends,
  leverages,
  labels,
  isNew,
  fetchTradingAccountFields,
  fetchTradingAccounts,
  fetchTradingBackends,
  fetchLeverages,
  fetchLabels,
  updateFieldValue,
  setFieldsErrors,
  resetFieldsData,
  createTradingAccount,
  editTradingAccount,
  requestInProgress,
  ...props
}) => {
  const [groups, setGroups] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [account_types, setAccountTypes] = useState([]);
  const [isAccountVisible, setIsAccountVisible] = useState(false);
  const [isBackendVisible, setIsBackendVisible] = useState(false);
  const [isGroupVisible, setIsGroupVisible] = useState(false);
  const [isLeverageVisible, setIsLeverageVisible] = useState(false);
  const [isLabelVisible, setIsLableVisible] = useState(false);
  const [isAccountTypeVisible, setIsAccountTypeVisible] = useState(false);
  const [isExternalIdVisible, setIsExternalIdVisible] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isAccountEditable, setIsAccountEditable] = useState(true);
  const [isMt4Visible, setIsMt4Visible] = useState(false);
  const [mt4Fields, setMT4Fields] = useState({});
  const [previousSelectedCurrency, setPreviousSelectedCurrency] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const isAvaTrade = process.env.WHITELABEL === 'AvaTrade';

  useEffect(() => {
    initForm();
  }, []);

  useEffect(() => {
    if (backends?.length > 0 && tradingAccounts && !isEmpty(formFields)) {
      const accountOptions = prepareAccounts(tradingAccounts);
      setAccounts(accountOptions);

      if (isNew) {
        setBackend(backends[0]);
      } else {
        setAccount(accountOptions[0]);
      }

      generateMt4Fields();
      changeCurrency();
    }
  }, [backends, tradingAccounts, formFields]);

  const initForm = () => {
    setIsLoading(true);
    Promise.all([
      fetchTradingAccountFields(contact.external_id),
      fetchTradingAccounts(contact.external_id),
      !isAvaTrade && fetchTradingBackends(),
    ])
      .finally(() => setIsLoading(false));
  };

  const onSubmit = async () => {
    const { external_id } = contact;
    const accountId = get(formValues, 'trading_account.value');
    const request = getRequestPayload(formValues);

    try {
      if (isNew) {
        // Create
        await createTradingAccount(external_id, request);
      } else {
        // Edit
        await editTradingAccount(external_id, accountId, request);
      }

      onSuccess();
    } catch (err) {
      handleErrorResponse(err);
    }
  };

  const onSuccess = () => {
    reloadTradingAccountsTable();
    onClose();
  };

  const onClose = () => {
    sidepanelManager.close();
  };

  const onBackendChange = (backend) => {
    setBackend(backend);
  };

  const onGroupChange = (group) => {
    setGroup(group);
  };

  const onAccountChange = (account) => {
    setAccount(account);
  };

  const onCurrencyChange = (currency) => {
    setCurrency(currency);
  };

  const changeCurrency = () => {
    const currency = get(formValues, 'currency');

    if (!isEqual(previousSelectedCurrency, currency)) {
      const backend = getBackend();
      updateGroup(backend);
    }
  };

  const onAccountTypeChange = (accountType) => {
    setAccountType(accountType);
  };

  const onLeverageChange = (leverage) => {
    setLeverage(leverage);
  };

  const onLabelChange = (label) => {
    setLabel(label);
  };

  const onExternalIdChange = (externalId) => {
    setExternalId(externalId);
  };

  const getRequestPayload = (rawValues) => {
    const values = cleanEmptyValues(rawValues);
    const MANDATORY_KEYS = ['currency', 'backend'];
    const nestedFieldKeys = Object.values(formFields)
      .filter(field => !!field.children)
      .map(field => field.key)
      .filter(key => !MANDATORY_KEYS.includes(key));
    const getNestedKey = fieldKey => nestedFieldKeys.find(key => fieldKey.startsWith(key));
    const getFieldValue = field => (!!field && typeof field === 'object' ? field.value : field);
    const groupId = get(values, 'group.id');
    const externalId = values.external_id;
    const request = {
      backend: !isNew ? values.trading_account.backend.id : values.backend.backend.id,
      currency: get(values, 'currency.value'),
      group: values.group ? values.group.id : undefined,
      read_only: values.read_only ? values.read_only : undefined,
      account_type: values.account_type ? values.account_type.value : undefined,
    };

    Object.keys(values)
      .forEach((key) => {
        const field = values[key];
        const parentKey = getNestedKey(key);
        if (parentKey) {
          const fieldKey = key.replace(`${parentKey}_`, '');
          request[parentKey] = {
            ...request[parentKey],
            [fieldKey]: getFieldValue(field),
          };
        }
      });

    if (groupId) request.group = groupId;
    if (externalId) request.external_id = externalId;

    if (isLeverageVisible) {
      request.leverage = values.leverage.id;
    }
    if (isLabelVisible && values.label) {
      request.label = values.label.name;
    }

    if (!isMt4Visible) {
      delete request.mt4_options;
    } else {
      request.read_only = values.read_only;
    }

    removeNonAvaFields(MANDATORY_KEYS, request);

    return request;
  };

  const setAccount = (account) => {
    if (!account) return;
    const defaultCurrency = account.currency.symbol;
    const defaultBackend = backends.find(backend => account.backend.id === backend.backend.id);
    const defaultGroup = account.group;
    const defaultLeverageId = account.leverage;
    const defaultLabelId = account.label;
    setIsAccountEditable(getIsAccountEditable(defaultBackend, account));

    setField('trading_account', account);

    setIsAccountVisible(!isNew);
    setIsSubmitDisabled(!isAccountEditable);
    setIsAccountEditable(getIsAccountEditable(defaultBackend, account));

    updateCurrency(defaultBackend, defaultCurrency);
    updateGroup(defaultBackend, defaultGroup);
    updateLeverage(defaultBackend, defaultLeverageId, account);
    updateLabels(defaultBackend, defaultLabelId, account);
    updateMt4(defaultBackend, account);
  };

  const getIsAccountEditable = (defaultBackend, account) => (!!defaultBackend && account.backend.type !== 'cloudtrade') || isAvaTrade;

  const getBackend = () => {
    const { backend } = formValues || {};
    const tradingAccountBackendId = get(formValues, 'trading_account.backend.id');

    return isNew ? backend : backends.find(b => tradingAccountBackendId === b.backend.id);
  };

  const setBackend = (backend) => {
    resetFieldsData();
    setField('backend', backend);

    setIsBackendVisible(isNew);

    updateCurrency(backend);
    updateAccountType(backend);
    updateGroup(backend);
    updateLeverage(backend);
    updateExternalId(backend);
    updateMt4(backend);
    checkBackendLimit(backend);
  };

  const setGroup = (group) => {
    setField('group', group || null);
  };

  const setAccountType = (accountType) => {
    setField('account_type', accountType);
  };

  const setLeverage = (leverage) => {
    setField('leverage', leverage);
  };

  const setLabel = (label) => {
    setField('label', label);
  };

  const setExternalId = (externalId) => {
    setField('external_id', externalId);
  };

  const setCurrency = (currency) => {
    setField('currency', currency);
  };

  const setField = (id, value) => {
    updateFieldValue(id, value);
  };

  const getBackends = () => {
    if (contact.state === contactStateLabel.DEMO) {
      return backends.filter(({ backend }) => backend.mode === contactStateLabel.DEMO);
    }

    return backends;
  };

  const canCreate = (selectedBackend = {}) => {
    const selectedBackendId = get(selectedBackend, 'backend.id');
    const selectedBackendLimit = get(selectedBackend, 'backend.accounts_per_user_limit');
    const count = tradingAccounts
      .filter(({ backend }) => backend.id === selectedBackendId)
      .length;
    return parseInt(selectedBackendLimit, 10) === 0
      || count < selectedBackendLimit;
  };

  const checkBackendLimit = (backend) => {
    if (isNew && !canCreate(backend)) {
      setIsSubmitDisabled(true);
      setFieldsErrors({ backend: text.CANNOT_CREATE });
    } else {
      setIsSubmitDisabled(false);
      setFieldsErrors({ });
    }
  };

  const reloadTradingAccountsTable = () => {
    const { id } = contact;
    const { gridActions } = GridComponentFactory(tableConfig(id));
    gridActions.fetchTableData();
  };

  const removeNonAvaFields = (backendMandatoryFields, request) => {
    if (isAvaTrade) {
      const { includeFields = [] } = { includeFields: [] }; // Todo: getFormConfig() check this part
      const avaFields = [...includeFields, ...backendMandatoryFields];

      Object.keys(request)
        .forEach((key) => {
          if (avaFields.length && !avaFields.includes(key)) {
            delete request[key];
          }
        });
    }
  };

  const updateGroup = (backend, defaultGroup) => {
    const { currency } = formValues;
    const defaultGroupId = get(defaultGroup, 'id');
    const groupHasType = be => be.backend.type === 'ctrader' || be.backend.type === 'dx_trade';
    const allGroups = backend
      ? backend?.groups?.filter(group => groupHasType(backend)
        || group.currency === currency?.value)
      : [defaultGroup];

    setGroups(allGroups);

    const selectedGroup = (defaultGroupId && allGroups.find(group => group.id === defaultGroupId))
      || (!isEmpty(allGroups) && allGroups[0]);
    setGroup(selectedGroup);

    setIsGroupVisible(isAccountEditable);
  };

  const updateAccountType = (backend) => {
    const accountTypes = backend?.account_types.map(value => ({
      value,
      display_name: value,
    }));

    setAccountTypes(accountTypes);
    setIsAccountTypeVisible(!['mt4', 'xmt4'].includes(backend.backend.type) && backend.backend.platform_type !== 'MT4');

    if (!isEmpty(accountTypes)) setAccountType(accountTypes[0]);
  };

  const updateLeverage = (backend, defaultLeverageId, account) => {
    fetchLeverages(backend.backend.id)
      .then(() => {
        let selectedLeverage = leverages.find(leverage => leverage.id === defaultLeverageId);

        const isVisible = leverages.length > 0;
        // eslint-disable-next-line prefer-destructuring
        if (isVisible && !selectedLeverage) selectedLeverage = leverages[0];

        setIsLeverageVisible(isVisible);
        setLeverage(selectedLeverage);
      });
  };

  const updateLabels = (backend, defaultLabelId, account) => {
    if (!isAvaTrade) return;
    fetchLabels(backend || account)
      .then(() => {
        let selectedLabel = labels.find(label => label.id === defaultLabelId);

        const isVisible = labels.length > 0;
        if (isVisible && !selectedLabel) selectedLabel = null;

        setIsLableVisible(isVisible);
        setLabel(selectedLabel);
      });
  };

  const updateExternalId = (backend) => {
    const backendMode = backend.backend.mode;
    const backendPlatformType = backend.backend.platform_type;
    const isVisible = backendMode !== 'Demo'
      && (backendPlatformType === 'MT4' || backendPlatformType === 'MT5' || backendPlatformType === 'DXTrade')
      && isFeatureEnabled()('TRADING_ACCOUNT_NUMBER');
    setIsExternalIdVisible(isVisible);
  };

  const updateCurrency = (backend, defaultCurrency) => {
    const { currency } = formFields;
    const currencyOptions = currency.choices;
    const selectedCurrency = currencyOptions.find(curr => curr.value === defaultCurrency);

    setCurrency(selectedCurrency || currencyOptions[0]);
    setPreviousSelectedCurrency(selectedCurrency);
  };

  const updateMt4 = (backend, account) => {
    const selectedBackend = backend || account;
    const isVisible = (selectedBackend.backend.type === 'mt4_server' || selectedBackend.backend.type === 'mt4' || selectedBackend.backend.type === 'mt5' || selectedBackend.backend.type === 'mt5_server');
    setIsMt4Visible(isVisible);

    if (!account) return;
    const fields = {
      mt4_options_agent: get(account, 'mt4_options.agent'),
      mt4_options_enable_otp: get(account, 'mt4_options.enable_otp'),
      mt4_options_enable_change_password: get(account, 'mt4_options.enable_change_password'),
      mt4_options_send_reports: get(account, 'mt4_options.send_reports'),
      read_only: get(account, 'read_only'),
    };

    Object.keys(fields)
      .forEach(key => setField(key, fields[key]));
  };

  const prepareAccounts = allAccounts => allAccounts?.map(account => ({
    ...account,
    display_name: tradingAccountDisplay(account),
    value: account.id,
  }));

  const generateMt4Fields = () => {
    if (isEmpty(mt4Fields)) {
      const MT4_OPTIONS = 'mt4_options';
      const fields = formatBiqFields(formFields[MT4_OPTIONS]?.children?.fields);

      setMT4Fields(fields);
    }
  };

  return (
    <Sidepanel
      {...props}
      footerRender={customFooter(
        onSubmit,
        onClose,
        requestInProgress.createTradingAccount || requestInProgress.editTradingAccount,
        isSubmitDisabled,
      )}
    >
      { isLoading ? <Loader visible /> : (
        <>
          <Space size={16} />

          <Fragment>
            {/* Backend select control */}
            { isBackendVisible && formValues.backend && formFields.backend && (
              <Select
                clearable={false}
                key="backend"
                label={text.SERVICE}
                placeholder={text.SERVICE}
                name="backend"
                labelKey="display_name"
                valueKey="value"
                value={formValues.backend}
                options={getBackends()}
                onChange={onBackendChange}
                formId={FORM_KEY}
              />
            ) }

            {/* Account select control */}
            { isAccountVisible && (
              <Select
                clearable={false}
                label={text.CHOOSE_ACCOUNT}
                placeholder={text.CHOOSE_ACCOUNT}
                labelKey="display_name"
                valueKey="value"
                value={formValues.trading_account}
                options={accounts}
                onChange={onAccountChange}
              />
            ) }

            <Space size={12} />

            {/* Currency select control */}
            { formFields.currency && (
              <Fragment>
                <Select
                  clearable={false}
                  label={text.CURRENCY}
                  placeholder={text.CURRENCY}
                  labelKey="display_name"
                  valueKey="value"
                  value={formValues.currency}
                  options={formFields.currency.choices}
                  onChange={onCurrencyChange}
                />
                <Space size={12} />
              </Fragment>
            ) }

            {/* Account type select control */}
            { isAccountTypeVisible && formFields.account_type && account_types.length > 0 && (
              <Fragment>
                <Select
                  clearable={false}
                  label={text.ACCOUNT_TYPE}
                  placeholder={text.ACCOUNT_TYPE}
                  labelKey="display_name"
                  valueKey="value"
                  value={formValues.account_type}
                  options={account_types}
                  onChange={onAccountTypeChange}
                />
                <Space size={12} />
              </Fragment>
            ) }

            {/* Group select control */}
            { formFields.group && formValues.group && (
              <Fragment>
                <Select
                  clearable={false}
                  label={text.CHOOSE_GROUP}
                  placeholder={text.CHOOSE_GROUP}
                  labelKey="name"
                  valueKey="id"
                  value={formValues.group}
                  options={groups}
                  onChange={onGroupChange}
                  disabled={!isGroupVisible}
                />
                <Space size={12} />
              </Fragment>
            ) }

            {/* Leverage select control */}
            {isLeverageVisible && formValues.leverage && formFields.leverage && (
              <Fragment>
                <Select
                  clearable={false}
                  label={text.LEVERAGES}
                  placeholder={text.LEVERAGES}
                  labelKey="value"
                  valueKey="id"
                  value={formValues.leverage}
                  options={leverages}
                  onChange={onLeverageChange}
                />
                <Space size={12} />
              </Fragment>
            )}

            {/* Label select control */}
            {isLabelVisible && isAvaTrade && formFields.label && (
              <Fragment>
                <Select
                  clearable
                  label={text.LABEL}
                  placeholder={text.LABEL}
                  labelKey="display_name"
                  valueKey="value"
                  value={formValues.label}
                  options={labels}
                  onChange={onLabelChange}
                />
                <Space size={12} />
              </Fragment>
            )}

            {/* External id control */}
            { isExternalIdVisible && !isAvaTrade && formFields.external_id && (
              <Fragment>
                <Input
                  label={text.TRADING_ACCOUNT_ID}
                  placeholder={text.TRADING_ACCOUNT_ID}
                  value={formValues.external_id ?? ''}
                  onChange={e => onExternalIdChange(e.target.value)}
                />
                <Space size={12} />
              </Fragment>
            ) }

            {/* MT4 and MT5 controls */}
            {!isEmpty(mt4Fields) && isMt4Visible && !isAvaTrade && (
              <Fragment>
                { mt4Fields.agent && (
                  <Fragment>
                    <Input
                      label={mt4Fields.agent.label}
                      placeholder={mt4Fields.agent.label}
                      value={formValues.mt4_options_agent}
                      onChange={e => setField('mt4_options_agent', e.target.value)}
                    />
                    <Space size={12} />
                  </Fragment>
                ) }

                { mt4Fields.enable_change_password && (
                  <Fragment>
                    <Checkbox
                      checked={formValues.mt4_options_enable_change_password}
                      onChange={e => setField('mt4_options_enable_change_password', e.target.checked)}
                    >
                      { mt4Fields.enable_change_password.label }
                    </Checkbox>
                    <Space size={12} />
                  </Fragment>
                ) }

                { mt4Fields.enable_otp && (
                  <Fragment>
                    <Checkbox
                      checked={formValues.mt4_options_enable_otp}
                      onChange={e => setField('mt4_options_enable_otp', e.target.checked)}
                    >
                      { mt4Fields.enable_otp.label }
                    </Checkbox>
                    <Space size={12} />
                  </Fragment>
                ) }

                { mt4Fields.send_reports && (
                  <Fragment>
                    <Checkbox
                      checked={formValues.mt4_options_send_reports}
                      onChange={e => setField('mt4_options_send_reports', e.target.checked)}
                    >
                      { mt4Fields.send_reports.label }
                    </Checkbox>
                    <Space size={12} />
                  </Fragment>
                ) }
              </Fragment>
            )}

            {/* Read only checkbox control */}
            {formFields.read_only && (
              <Fragment>
                <Checkbox
                  checked={formValues.read_only}
                  onChange={e => setField('read_only', e.target.checked)}
                >
                  { formFields.read_only.label }
                </Checkbox>
              </Fragment>
            )}

            {!isAccountEditable && <InfoBox>{text.CANNOT_EDIT}</InfoBox>}
          </Fragment>

          <Space size={16} />
        </>
      ) }
    </Sidepanel>
  );
};

TradingAccountForm.propTypes = propTypes;
TradingAccountForm.defaultProps = defaultProps;

export default withErrorBoundary(inject(stores => ({
  fetchTradingAccountFields: stores.contact.tradingAccountsStore.fetchTradingAccountFields,
  fetchTradingAccounts: stores.contact.tradingAccountsStore.fetchTradingAccounts,
  fetchTradingBackends: stores.contact.tradingAccountsStore.fetchTradingBackends,
  fetchLeverages: stores.contact.tradingAccountsStore.fetchLeverages,
  fetchLabels: stores.contact.tradingAccountsStore.fetchLabels,
  updateFieldValue: stores.forms.tradingAccountForm.updateFieldValue,
  fieldErrors: stores.forms.tradingAccountForm.fieldErrors,
  setFieldsErrors: stores.forms.tradingAccountForm.setFieldsErrors,
  resetFieldsData: stores.forms.tradingAccountForm.resetFieldsData,
  createTradingAccount: stores.contact.tradingAccountsStore.createTradingAccount,
  editTradingAccount: stores.contact.tradingAccountsStore.editTradingAccount,
  formFields: stores.contact.tradingAccountsStore.tradingAccountFields,
  formValues: stores.forms.tradingAccountForm.data,
  requestInProgress: stores.contact.tradingAccountsStore.requestInProgress,
  leverages: stores.contact.tradingAccountsStore.leverages,
  backends: stores.contact.tradingAccountsStore.tradingBackends,
  tradingAccounts: stores.contact.tradingAccountsStore.tradingAccounts,
  labels: stores.contact.tradingAccountsStore.labels,
  contact: stores.contact.profile.contactData,
}))(observer(TradingAccountForm)));
