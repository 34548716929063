/* eslint-disable react/prop-types */
import React from 'react';

import { StatusCell, RouteCell } from 'App/components/gridCellRenderers';
import hideCols from 'App/services/utilities/hideCols';

const text = {
  COMPLETED_STATUS: 'Completed',
  CANCELED_STATUS: 'Cancelled',
  FAILED_STATUS: 'Failed',
  STARTED: 'Started',
};

const renderNextStep = ({ data }) => {
  if (data.status === text.COMPLETED_STATUS) return <div>{text.COMPLETED_STATUS}</div>;
  if (data.status === text.FAILED_STATUS) return <div>{text.FAILED_STATUS}</div>;
  if (data.status === text.CANCELED_STATUS) return <div>/</div>;
  return <div>{`${data.next_step.name} - ${data.next_step.type}`}</div>;
};

const WorkflowNameCell = ({ value }) => {
  if (value.status === 'Draft') {
    return RouteCell('/marketing/workflows/')({ hrefValue: value.id, value: value.name });
  }

  return RouteCell('/marketing/workflows-overview/')({ hrefValue: value.id, value: value.name });
};

export default ({ onOpenJourneyPreview }) => ({
  ...hideCols(['contact']),
  id: {
    pinned: 'left',
    width: 80,
    cellRendererFramework: ({ value, data }) => (
      <div className="like-a-link" onClick={() => onOpenJourneyPreview(data)}>
        {value}
      </div>
    ),
  },
  workflow: {
    headerName: 'Workflow',
    pinned: 'left',
    cellRendererFramework: WorkflowNameCell,
  },
  status: {
    cellRendererFramework: StatusCell,
  },
  next_step: {
    cellRendererFramework: renderNextStep,
  },
  created: {
    headerName: text.STARTED,
  },
});
