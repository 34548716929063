import { action, observable, runInAction, makeObservable } from 'mobx';
import { notifier } from 'tc-biq-design-system';
import { History } from 'history';
import axios from 'axios';
import displayError from 'App/services/utilities/displayError';

import {
  fetchSegment,
  patchSegment,
  createSegment,
  fetchFieldsDef,
  deleteSegment,
  fetchSegmentMetadata,
  createAudience,
  pauseAudience,
  resumeAudience,
  deleteAudience,
} from 'Automation/services/segmentsService';
import formsStore from 'App/rootStore/formsStore';
import formatQueryRulesArrayValues from 'App/services/utilities/formatQueryRulesArrayValues';
import {
  formatEventLabel,
  formatEventNestedKeys,
  queryFlattener,
} from 'App/components/QueryBuilderFactory/queryBuilderStoreUtils';
import { handleErrorResponse } from 'App/services/utilities/error.utils';

const text = {
  SEGMENT_FAILED: 'Failed to fetch segment data',
  CREATE_SUCCESS: 'Successfully created segment',
  CREATE_FAILED: 'Failed to create segment',
  EDIT_SUCCESS: 'Successfully edited segment',
  EDIT_FAILED: 'Failed to edit segment',
  DELETE_SUCCESS: 'Successfully deleted segment',
  DELETE_FAILED: 'Failed to delete segment',
  FIELDS_FAILED: 'Failed to fetch fields definition',
  CREATE_AUDIENCE_SUCCESS: 'Successfully created an audience',
  CREATE_AUDIENCE_FAILED: 'Failed to create an audience',
  AD_NETWORK_AUDIENCE_ALREADY_EXIST: ' audience already exist for segment!',
  DELETE_AUDIENCE_SUCCESS: 'Successfully deleted an audience',
  PAUSE_AUDIENCE_SUCCESS: 'Successfully paused an audience',
  RESUME_AUDIENCE_SUCCESS: 'Successfully resumed an audience',
};

export interface User {
  avatar: string;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
}

export interface Query {
  ondition: 'AND' | 'OR';
  event_type_id: number | null;
  last_n_days: number | null;
  no_times: number | null;
  rules: any[];
}

export interface Audience {
  ad_network: string;
  id: number;
  sync_status: string;
}

export interface Segment {
  audiences: Audience[];
  contacts: number;
  created: string;
  created_by: User;
  description: string;
  id: number;
  name: string;
  query: Query;
  status: string;
  updated: string;
  updated_by: Query | null;
}

export interface ISegmentsStore {
  requestInProgress: { [key: string]: boolean };
  segment: Segment | null;
  fieldsDef: any;
}

export default class SegmentsStore implements ISegmentsStore {
  queryBuilder: any;

  constructor(queryBuilder: any) {
    makeObservable(this, {
      requestInProgress: observable,
      segment: observable,
      fieldsDef: observable,
      fetchSegment: action.bound,
      fetchFieldsDef: action.bound,
      createSegment: action.bound,
      editSegment: action.bound,
      removeSegment: action.bound,
      createAudience: action.bound,
      pauseAudience: action.bound,
      resumeAudience: action.bound,
      deleteAudience: action.bound,
    });

    this.queryBuilder = queryBuilder;
  }

  requestInProgress = {
    fetchSegment: false,
    fieldsDef: false,
    create: false,
    edit: false,
  };

  segment: Segment | null = null;

  fieldsDef = {};

  async fetchSegment(id: number) {
    formsStore.segmentForm.resetFieldsData();
    this.queryBuilder.resetQueries();
    runInAction(() => {
      this.requestInProgress.fetchSegment = true;
    });
    try {
      const response = await fetchSegment(id);
      runInAction(() => {
        this.segment = response.data;
        formsStore.segmentForm.setFieldsData(response.data);
        this.queryBuilder.setQueries(response.data.query);
      });
    } catch (e) {
      handleErrorResponse(e, text.SEGMENT_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.fetchSegment = false;
      });
    }
  }

  async fetchFieldsDef() {
    this.requestInProgress.fieldsDef = true;
    try {
      const response = await Promise.all([fetchFieldsDef(), fetchSegmentMetadata()]);
      const [segmentMetadata, contactAttributesMetadata] = response.map(item => item.data);
      runInAction(() => {
        this.fieldsDef = segmentMetadata.actions.GET;
      });
      this.queryBuilder.setFields(queryFlattener(contactAttributesMetadata));
      this.queryBuilder.setFieldsMetadata(
        formatEventNestedKeys(contactAttributesMetadata),
        formatEventLabel,
      );
    } catch (e) {
      handleErrorResponse(e, text.FIELDS_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.fieldsDef = false;
      });
    }
  }

  async createSegment(history: History) {
    this.requestInProgress.create = true;
    try {
      const {
        name,
        description,
      } = formsStore.segmentForm.data;
      await createSegment({
        name,
        description,
        query: formatQueryRulesArrayValues(this.queryBuilder.queries),
      });
      notifier.success(text.CREATE_SUCCESS);
      history.push('/automation/segments');
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data) {
        formsStore.segmentForm.setFieldsErrors(error.response.data);
      }
      handleErrorResponse(error, text.CREATE_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.create = false;
      });
    }
  }

  async editSegment(id: number, history: History) {
    this.requestInProgress.edit = true;
    const {
      name,
      description,
    } = formsStore.segmentForm.data;
    try {
      await patchSegment(id, {
        name,
        description,
        query: formatQueryRulesArrayValues(this.queryBuilder.queries),
      });
      notifier.success(text.EDIT_SUCCESS);
      history.push('/automation/segments');
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data) {
        formsStore.segmentForm.setFieldsErrors(error.response.data);
      }
      handleErrorResponse(error, text.EDIT_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.edit = false;
      });
    }
  }

  removeSegment = async (segmentID: number, onSuccess: () => void) => {
    try {
      await deleteSegment(segmentID);
      notifier.success(text.DELETE_SUCCESS);
      onSuccess();
    } catch (error) {
      handleErrorResponse(error, text.DELETE_FAILED);
    }
  };

  createAudience = async (
    segmentID: number, existingAudiences: Audience[], adNetwork: string, onSuccess: () => void,
  ) => {
    if (existingAudiences.find((a: Audience) => a.ad_network === adNetwork)) {
      notifier.error(adNetwork + text.AD_NETWORK_AUDIENCE_ALREADY_EXIST);
      return;
    }
    try {
      await createAudience(segmentID, { ad_network: adNetwork });
      notifier.success(text.CREATE_AUDIENCE_SUCCESS);
      onSuccess();
    } catch (error) {
      handleErrorResponse(error, text.CREATE_AUDIENCE_FAILED);
    }
  };

  pauseAudience = async (segmentID: number, audienceID: number, onSuccess: () => void) => {
    try {
      await pauseAudience(segmentID, audienceID);
      notifier.success(text.PAUSE_AUDIENCE_SUCCESS);
      onSuccess();
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  resumeAudience = async (segmentID: number, audienceID: number, onSuccess: () => void) => {
    try {
      await resumeAudience(segmentID, audienceID);
      notifier.success(text.RESUME_AUDIENCE_SUCCESS);
      onSuccess();
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  deleteAudience = async (segmentID: number, audienceID: number, onSuccess: () => void) => {
    try {
      await deleteAudience(segmentID, audienceID);
      notifier.success(text.DELETE_AUDIENCE_SUCCESS);
      onSuccess();
    } catch (error) {
      handleErrorResponse(error);
    }
  };
}
