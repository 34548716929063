import get from 'lodash/get';
import { trimEnd, upperFirst } from 'lodash';
import extractNestedFields from 'App/services/utilities/extractNestedFields';
import fieldTypes from 'App/enums/fieldTypes';

export {
  getAffectedObject,
  removeAffectedCondition,
  formatEventLabel,
  formatEventNestedKeys,
  formatFields,
  queryFlattener,
};

function getAffectedObject(target, identifier) {
  const affectedObject = get(target, getDataPath(identifier));
  return affectedObject || {};
}

function removeAffectedCondition(target, identifier) {
  const temp = target.slice();
  if (identifier.length === 1) {
    temp.splice(identifier[0], 1);
  } else {
    const parentIdentifier = [...identifier];
    const conditionIndex = parentIdentifier.pop();
    const parentDataPath = getDataPath(parentIdentifier);
    const parentCondition = get(temp, parentDataPath);
    parentCondition.rules.splice(conditionIndex, 1);
  }
  return temp;
}

function getDataPath(identifier) {
  return identifier
    .map((position, index) => {
      if (index + 1 === identifier.length) return `[${position}]`;
      return `[${position}].rules`;
    })
    .join('');
}

function removeAndFormat(label, stringMatch) {
  return label
    .replace('custom properties', '')
    .replace(stringMatch, '')
    .replace('Old', 'Previous')
    .split('_')
    .map((string, index) => {
      if (index === 0) {
        return string.charAt(0).toUpperCase() + string.substring(1);
      }
      return string;
    })
    .join(' ');
}

function formatEventLabel(label) {
  if (label.includes('Payload ')) {
    return removeAndFormat(label, 'Payload ');
  }
  if (label.includes(' payload')) {
    return removeAndFormat(label, ' payload');
  }
  return label;
}

function formatEventNestedKeys(fields) {
  const temp = {};
  Object.keys(fields)
    .filter(key => !!fields[key])
    .forEach((prefixKey) => {
      const childrenFields = extractNestedFields(fields[prefixKey].children);
      Object.keys(childrenFields).forEach((fieldKey) => {
        temp[`${prefixKey}.${fieldKey.split('__').join('.')}`] = childrenFields[fieldKey];
      });
    });
  return temp;
}

function formatLabelFromKey(key, type) {
  const formattedKey = upperFirst(
    key.replace('.id', '')
      .replace('custom_properties', '')
      .split('.')
      .join('_')
      .toLowerCase()
      .split('_')
      .join(' ')
      .trim(),
  );

  // TODO: Try to find a better way to represent multi-related field labels, as
  // this is just a hack to remove plural form from the attribute.
  return type === fieldTypes.MULTI_RELATED_FIELD ? trimEnd(formattedKey, 's') : formattedKey;
}

function formatFields(fieldsDef, labelFormatter) {
  const fields = {};
  Object.keys(fieldsDef).forEach((key) => {
    const { type } = fieldsDef[key];
    fields[key] = {
      ...fieldsDef[key],
      name: key,
      id: key,
      label: labelFormatter
        ? labelFormatter(formatLabelFromKey(key, type))
        : formatLabelFromKey(key, type),
    };
  });
  return fields;
}

function queryFlattener(metadataFields) {
  return Object.keys(metadataFields).reduce((acc, key) => {
    if (metadataFields[key].children) {
      return {
        ...acc,
        ...formatEventNestedKeys(metadataFields),
      };
    }

    acc[key] = {
      ...metadataFields[key],
      label: key
        .split('.')[0]
        .toLowerCase()
        .split('_')
        .map((string, index) => {
          if (index === 0) {
            return string.charAt(0).toUpperCase() + string.substring(1);
          }
          return string;
        })
        .join(' '),
    };
    return acc;
  }, {});
}
