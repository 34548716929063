import React, { useEffect } from 'react';
import { number, oneOfType, string, func, object } from 'prop-types';
import { observer, inject } from 'mobx-react';
import { isEmpty } from 'lodash';
import withErrorBoundary from 'App/components/hoc/withErrorBoundary';
import { hasAccess } from 'App/services/permissionsService';

import GridComponentFactory from 'App/components/grid';
import modifiers from './modifiers';

const propTypes = {
  segmentId: oneOfType([number, string]).isRequired,
  segment: object.isRequired,
  pauseAudience: func.isRequired,
  resumeAudience: func.isRequired,
  deleteAudience: func.isRequired,
};

const text = {
  RESUME: 'Resume',
  PAUSE: 'Pause',
  REMOVE: 'Remove',
};

const tableConfig = segmentId => ({
  tableUrl: `/automations/segments/${segmentId}/audiences/`,
  tableKey: 'segmentAudiences',
});

const actions = ({ pauseAudience, onSuccess, deleteAudience, resumeAudience }) => [
  {
    label: text.RESUME,
    action: ({ segment, id }) => resumeAudience(segment.id, id, onSuccess),
    icon: 'Play',
    visibility: hasAccess('automations_segment', 'update'),
  },
  {
    label: text.PAUSE,
    action: ({ segment, id }) => pauseAudience(segment.id, id, onSuccess),
    icon: 'Pause',
    visibility: hasAccess('automations_segment', 'update'),
  },
  {
    label: text.REMOVE,
    action: ({ segment, id }) => deleteAudience(segment.id, id, onSuccess),
    icon: 'Reject',
    visibility: hasAccess('automations_segment', 'update'),
  },
];

const customColumns = [{ key: 'actions', headerName: '' }];

const sizeOptions = [10, 20, 50, 100, 200];

const AudiencesTable = ({ segment, segmentId, pauseAudience, resumeAudience, deleteAudience }) => {
  if (isEmpty(segment)) return null;

  const { GridComponent, gridActions } = GridComponentFactory(tableConfig(segmentId));
  const onSuccess = gridActions.fetchTableData;
  const segmentContacts = segment.contacts;

  return (
    <GridComponent
      hideColumnManager
      sizeOpitons={sizeOptions}
      height="calc(100vh - 400px)"
      customColumns={customColumns}
      modifiers={modifiers(
        segmentContacts,
        actions({ pauseAudience, resumeAudience, deleteAudience, onSuccess }),
      )}
    />
  );
};

AudiencesTable.propTypes = propTypes;

export default inject(stores => ({
  pauseAudience: stores.segments.pauseAudience,
  resumeAudience: stores.segments.resumeAudience,
  deleteAudience: stores.segments.deleteAudience,
  segment: stores.segments.segment,
}))(observer(withErrorBoundary(AudiencesTable)));
