import React from 'react';
import { Button, Space } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import { object, func, bool } from 'prop-types';
import { inject, observer } from 'mobx-react';

import { hasAccess } from 'App/services/permissionsService';

import './CustomActionFooter.scss';

const propTypes = {
  match: object.isRequired,
  createCustomAction: func.isRequired,
  history: object.isRequired,
  loading: bool.isRequired,
  updateCustomAction: func.isRequired,
  resetCustomActions: func.isRequired,
  validateForm: func.isRequired,
};

const text = {
  DISCARD: 'Discard',
  DISCARD_CHANGE: 'Discard changes',
  SAVE: 'Save changes',
  ADD: 'Create custom action',
};

const CustomActionFooter = ({
  match,
  history,
  loading,
  createCustomAction,
  updateCustomAction,
  resetCustomActions,
  validateForm,
}) => {
  const { customActionId, cloneId } = match.params;
  const isAdd = customActionId === 'create' || cloneId;
  const goToCustomActionsTable = () => {
    history.push('/automation/custom-actions');
    resetCustomActions();
  };
  const addRule = () => validateForm() && createCustomAction(history);
  const editRule = () => validateForm() && updateCustomAction({ id: customActionId, history });
  const createPermission = hasAccess('automations_rule', 'create');
  const updatePermission = hasAccess('automations_rule', 'update');
  const hasPermission = isAdd ? createPermission : updatePermission;
  return (
    <div className="fiq-rule-footer">
      <Button onClick={goToCustomActionsTable} color="ghost">
        {isAdd ? text.DISCARD : text.DISCARD_CHANGE}
      </Button>
      <Space size={4} />
      <Button disabled={!hasPermission} loading={loading} onClick={isAdd ? addRule : editRule}>
        {isAdd ? text.ADD : text.SAVE}
      </Button>
    </div>
  );
};

CustomActionFooter.propTypes = propTypes;

export default inject(stores => ({
  loading: stores.customActions.requestInProgress.createCustomAction,
  createCustomAction: stores.customActions.createCustomAction,
  updateCustomAction: stores.customActions.updateCustomAction,
  resetCustomActions: stores.customActions.resetCustomActions,
  validateForm: stores.forms.ruleForm.validateForm,
}))(observer(withRouter(CustomActionFooter)));
