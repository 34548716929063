import http from 'App/services/http';

const fetchEmailLogData = () => http.get('/communications/email-logs');

const fetchEmailLog = id => http.get(`/communications/email-logs/${id}/`);

const fetchSmsLogData = () => http.get('/communications/sms-logs/');

const fetchSmsLog = id => http.get(`/communications/sms-logs/${id}/`);

const fetchWhatsAppLogData = () => http.get('/communications/whatsapp-logs/');

const fetchWhatsAppLog = id => http.get(`/communications/whatsapp-logs/${id}/`);

// TEMPLATES
const fetchTemplate = id => http.get(`/communications/templates/${id}/`);

const fetchTemplates = query => http.get('/communications/templates/', { params: query });

const fetchTemplatesOptions = () => http.options('/communications/templates/');

const createTemplate = payload => http.post('/communications/templates/', payload);

const updateTemplate = (payload, id) => http.put(`/communications/templates/${id}/`, payload);

const deleteTemplate = id => http.delete(`/communications/templates/${id}/`);

const fetchContactAttributes = () => http.get('/communications/templates/contact-attributes/');

export {
  fetchEmailLogData,
  fetchEmailLog,
  fetchSmsLogData,
  fetchSmsLog,
  fetchWhatsAppLogData,
  fetchWhatsAppLog,
  fetchTemplate,
  fetchTemplatesOptions,
  createTemplate,
  updateTemplate,
  deleteTemplate,
  fetchTemplates,
  fetchContactAttributes,
};
