import actionTypes from '../types';
import ManualEnrollToWorkflow from './ManualEnrollToWorkflow';

const text = {
  LABEL: 'Manual enroll to workflow',
};

const ACTION_ICON = 'Logs';

export default {
  name: 'manualEnrollToWorkflow',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: actionTypes.DROPDOWN,
  handler: (sidepanelManager) => {
    console.log('sidepanel manager =>', sidepanelManager);
    const sidepanelOptions = {
      icon: ACTION_ICON,
      title: text.LABEL,
      sidepanelManager,
      visible: true,
    };

    sidepanelManager.add(ManualEnrollToWorkflow, sidepanelOptions);
  },
};
