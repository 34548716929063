import React, { useEffect, useState } from 'react';
import { DataGrid } from 'tc-biq-design-system';
import { func } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { actionWrapper, SmartlookCell } from 'App/components/gridCellRenderers';

import './CustomActionsTable.scss';
import { withRouter } from 'react-router-dom';

const propTypes = {
  fetchTableData: func.isRequired,
};

const singleActions = ({ onEdit, onDelete }) => [
  {
    icon: { name: 'Edit', color: 'text-neutral-900', size: 'small' },
    onClick: onEdit,
    label: 'Edit',
  },
  {
    icon: { name: 'Delete', color: 'text-neutral-900', size: 'small' },
    onClick: onDelete,
    label: 'Delete',
  },
];

const columns = actions => [
  {
    headerName: 'Id',
    field: 'id',
    sortable: true,
    cellRendererFramework: SmartlookCell,
  },
  {
    headerName: 'Name',
    field: 'name',
    sortable: true,
    cellRendererFramework: SmartlookCell,
  },
  {
    headerName: 'Description',
    field: 'description',
    sortable: true,
    cellRendererFramework: SmartlookCell,
  },
  {
    pinned: 'right',
    width: 80,
    suppressSizeToFit: true,
    enableFiltering: false,
    cellRendererFramework: actionWrapper(actions),
    headerName: '',
    field: 'actions',
  },
];

const CustomActionsTable = ({ fetchTableData, history, removeCustomAction }) => {
  const [gridApi, setGridApi] = useState(null);
  const [customActions, setCustomActions] = useState([]);

  useEffect(() => {
    const actions = fetchTableData();
    setCustomActions(actions);
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    sizeColumnsToFit();
  };

  const sizeColumnsToFit = () => {
    if (gridApi) {
      gridApi.setRowData(customActions);
      gridApi.redrawRows(customActions);
      gridApi.sizeColumnsToFit();
    }
  };

  const onEdit = ({ id }) => {
    history.push(`/automation/custom-actions/${id}`);
  };

  const onDelete = ({ id }) => {
    removeCustomAction(id);
    const actions = fetchTableData();
    setCustomActions(actions);
  };

  const actions = singleActions({ onEdit, onDelete });

  return (
    <div className="fiq-invite-users-table">
      <DataGrid
        gridOptions={{ onViewportChanged: sizeColumnsToFit }}
        onGridReady={onGridReady}
        columnDefs={columns(actions)}
        rowData={customActions}
      />
    </div>
  );
};

CustomActionsTable.propTypes = propTypes;

export default inject(stores => ({
  fetchTableData: stores.customActions.fetchTableData,
  removeCustomAction: stores.customActions.removeCustomAction,
}))(observer(withRouter(CustomActionsTable)));
