import React from 'react';
import { object, func } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Numeric from 'Dashboard/components/layouts/Numeric';

const text = {
  TITLE: 'Closed workflows',
  DESCRIPTION: 'Total number of closed workflows in selected time period',
};

const propTypes = {
  data: object,
  addNewFilter: func,
  history: object.isRequired,
  dashboardInterval: object.isRequired,
};

const defaultProps = {
  data: {},
  addNewFilter: null,
};

const filters = (startDate, endDate) => [
  {
    field: { label: 'Start datetime', value: 'start_datetime', type: 'datetime' },
    operator: { label: 'at or before', value: 'lte', related_models: null },
    value: endDate,
  },
  {
    field: { label: 'End datetime', value: 'end_datetime', type: 'datetime' },
    operator: { label: 'between', value: 'range', related_models: null },
    value: [startDate, endDate],
  },
];

const FinishedWorkflowsNumeric = ({ data, addNewFilter, history, dashboardInterval }) => {
  const onClick = () => {
    const { startDate, endDate } = dashboardInterval;
    filters(startDate, endDate).forEach(addNewFilter);
    history.push('/marketing/workflows');
  };

  const { value, percentage, isGrowing } = data;

  return (
    <Numeric
      title={text.TITLE}
      description={text.DESCRIPTION}
      value={value}
      percentage={percentage}
      isGrowing={isGrowing}
      onClick={onClick}
      isCount
    />
  );
};

FinishedWorkflowsNumeric.propTypes = propTypes;
FinishedWorkflowsNumeric.defaultProps = defaultProps;

export default {
  component: inject(stores => ({
    data: stores.dashboardStore.marketingNumericsData.finishedWorkflows,
    addNewFilter: stores.tables.workflows.filters.addNewFilter,
    dashboardInterval: stores.dashboardStore.dashboardInterval,
  }))(observer(withRouter(FinishedWorkflowsNumeric))),
};
