import React from 'react';
import { Pill } from 'tc-biq-design-system';
import { RouteCell } from 'App/components/gridCellRenderers';
import hideCols from 'App/services/utilities/hideCols';
import NoDataDash from 'App/components/NoDataDash';
import isEmpty from 'lodash/isEmpty';

export default {
  ...hideCols(['external_url', 'assigned_to', 'assigned_to_email', 'is_deleted']),
  id: {
    width: 50,
  },
  subject: {
    width: 200,
  },
  tags: {
    cellRendererFramework: ({ value }) => {
      if (isEmpty(value)) return <NoDataDash />;
      return value.map(tag => <Pill key={tag}>{tag}</Pill>);
    },
  },
  external_id: {
    cellRendererFramework: ({ data }) => (
      <a target="_blank" rel="noopener noreferrer" href={data.external_url}>
        {data.external_id}
      </a>
    ),
  },
  asigned_agent: {
    headerName: 'Assigned Agent',
    cellRendererFramework: ({ data }) => {
      if (data.assigned_to) {
        return RouteCell('/settings/users/')({ hrefValue: `${data.assigned_to}`, value: data.assigned_to });
      }
      return <div>{data.assigned_to_email || ''}</div>;
    },
  },
  submitted_by: {
    cellRendererFramework: ({ value }) => RouteCell('/contacts/')({ hrefValue: `${value}/activityStream`, value }),
  },
};
