import CopyToClipboardWrapper from 'App/components/CopyToClipboardWrapper';
import Field from 'App/components/FieldRenderer';
import { inject, observer } from 'mobx-react';
import { object, string } from 'prop-types';
import React, { Fragment, useState } from 'react';
import { generateIntegrationSecret } from 'Settings/Sections/Integrations/services/IntegrationsService';
import { Button } from 'tc-biq-design-system';

const text = {
  GENERATE: 'Generate secret',
  COPY: 'Copy to clipboard',
};

const AutoGeneratedSecretField = inject(stores => ({
  setFieldError: stores.forms.editIntegrationConfig.setFieldError,
  updateFieldValue: stores.forms.editIntegrationConfig.updateFieldValue,
}))(
  observer(
    ({ value, setFieldError, updateFieldValue, fetchIntegrationsData, isLast, ...fieldProps }) => {
      const [inProgress, setInProgress] = useState(false);
      const [fieldValue, setFieldValue] = useState(value || '');
      const generateSecret = async () => {
        setInProgress(true);
        try {
          const response = await generateIntegrationSecret(fieldProps.name);
          updateFieldValue(fieldProps.name, response.data?.value);
          setFieldValue(response.data?.value);
          fetchIntegrationsData();
        } catch (e) {
          setFieldError(fieldProps.name, e?.response?.data);
        } finally {
          setInProgress(false);
        }
      };

      return (
        <Fragment>
          <Field
            {...fieldProps}
            value={fieldValue}
            formId="editIntegrationConfig"
            afterFieldSpaceSize={4}
          />
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={generateSecret} loading={inProgress}>
              {text.GENERATE}
            </Button>
            <CopyToClipboardWrapper textToCopy={fieldValue}>
              <Button onClick={e => e.preventDefault()} size="small" icon="Duplicate">
                {text.COPY}
              </Button>
            </CopyToClipboardWrapper>
          </div>
          {!isLast && <hr />}
        </Fragment>
      );
    },
  ),
);

AutoGeneratedSecretField.wrappedComponent.propTypes = {
  fieldProps: object.isRequired,
  value: string.isRequired,
};

// eslint-disable-next-line
export { AutoGeneratedSecretField };
