import React, { useEffect } from 'react';
import Page from 'App/components/Page';
import { withRouter } from 'react-router-dom';
import { object, func, bool } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Col, Row, Space } from 'tc-biq-design-system';

import Field, { fieldsResolver } from 'App/components/FieldRenderer';
import Loader from 'App/components/Loader';

import './CustomActionsSinglePage.scss';
import CustomActionFooter from 'Automation/components/CustomActionFooter';
import CustomActionActions from 'Automation/components/CustomActionActions';

const propTypes = {
  match: object.isRequired,
  fetchRuleOptions: func.isRequired,
  fieldsDef: object.isRequired,
  loadingFields: bool.isRequired,
  loadingData: bool.isRequired,
  fetchCustomAction: func.isRequired,
  disableRuleTrigger: bool.isRequired,
};

const text = {
  TITLE: 'Custom actions',
  CREATE_TITLE: 'Create custom action',
  EDIT_TITLE: 'Edit custom action',
  TRIGGER_TITLE: 'Set custom action trigger',
};

const bread = id => [
  { label: 'Automation' },
  { label: text.TITLE, route: '/automation/rules' },
  { label: id },
];

const toObject = (fields) => {
  const temp = {};
  fields.forEach((field) => {
    temp[field.id] = field;
  });
  return temp;
};

const CustomActionsSinglePage = ({
  match,
  fetchActions,
  fetchRuleOptions,
  fieldsDef,
  loadingFields,
  loadingData,
  fetchCustomAction,
  resetCustomActions,
}) => {
  const { customActionId, cloneId } = match.params;
  const title = customActionId === 'create' || cloneId ? text.CREATE_TITLE : text.EDIT_TITLE;
  const fieldsProps = toObject(fieldsResolver(fieldsDef));
  const fetchData = async () => {
    await fetchRuleOptions();
    await fetchActions();
    if (customActionId !== 'create') {
      await fetchCustomAction(customActionId);
    }
  };

  useEffect(() => {
    fetchData();
  }, [customActionId]);

  useEffect(() => {
    resetCustomActions();
  }, []);

  return (
    <Page bread={bread(customActionId)} title={title} goBackIcon={null} style={{ padding: '30px', overflow: 'auto' }}>
      <Loader visible={loadingFields || loadingData} />
      {!loadingFields && !loadingData && (
        <div className="fiq-rules-single-page">
          <div className="fiq-rules-single-page__name">
            <Field
              name="name"
              {...fieldsProps.name}
              formId="customActionForm"
              label="Custom action name"
            />
          </div>
          <div className="fiq-rules-single-page__body">
            <Row gutter={0} style={{ minHeight: 'calc(100vh - 490px)' }}>
              <Col gutter={0} xs="68%">
                <div className="fiq-rules-single-page__trigger">
                  {/* <BlockTitle title={text.TRIGGER_TITLE} icon="Clock" /> */}
                  {/* <Space size={32} /> */}
                  <Field
                    id="description"
                    name="description"
                    type="textarea"
                    formId="customActionForm"
                    label="Description"
                    rows={7}
                  />
                  <Space size={32} />
                  {/* <RuleTriggerQueryBuilder /> */}
                </div>
              </Col>
              <Col gutter={0} xs="2%">
                <div className="fiq-rules-single-page__separator" />
              </Col>
              <Col gutter={0} xs="30%">
                <CustomActionActions />
              </Col>
            </Row>
          </div>
          <CustomActionFooter />
        </div>
      )}
    </Page>
  );
};

CustomActionsSinglePage.propTypes = propTypes;

export default inject(stores => ({
  fetchRuleOptions: stores.rules.fetchRuleOptions,
  fetchActions: stores.customActions.fetchActions,
  fetchCustomAction: stores.customActions.fetchCustomAction,
  fieldsDef: stores.rules.fieldsDef,
  resetCustomActions: stores.customActions.resetCustomActions,
  loadingFields: stores.rules.requestInProgress.ruleOptions,
  loadingData: stores.rules.requestInProgress.fetchRule,
  disableRuleTrigger: stores.rules.disableRuleTrigger,
}))(observer(withRouter(CustomActionsSinglePage)));
