import React from 'react';
import { Icon } from 'tc-biq-design-system';
import classNames from 'classnames';

import './PaymentTypeCell.scss';

const paymentType = {
  Deposit: { color: 'text-status01-500', icon: 'Deposit' },
  Withdraw: { color: 'text-status04-500', icon: 'Withdrawal' },
  Credit: { color: 'text-status01-500', icon: 'Credit' },
  Debit: { color: 'text-status04-500', icon: 'Debit' },
  'Internal credit': { color: 'text-status01-500', icon: 'Credit' },
  'Internal debit': { color: 'text-status04-500', icon: 'Debit' },
};

const directionStyle = {
  positive: ['Deposit', 'Credit', 'Internal credit'],
  negative: ['Withdraw', 'Debit', 'Internal debit'],
};

// eslint-disable-next-line
const PaymentTypeCell = ({ value }) => (
  <div className="fiq-payment-type-cell">
    <div
      className={classNames('fiq-payment-type-cell__icon-wrapper', {
        positive: directionStyle.positive.includes(value),
        negative: directionStyle.negative.includes(value),
      })}
    >
      {paymentType[value] && (
        <Icon name={paymentType[value].icon} colorName={paymentType[value].color} />
      )}
    </div>
    <span>{value}</span>
  </div>
);

export default PaymentTypeCell;
