import React from 'react';
import { observer, inject } from 'mobx-react';
import { Trans } from '@lingui/macro';
import { Dropdown, Avatar, Icon, Space, Button } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';

import './UserDropdown.scss';

const propTypes = {
  user: object.isRequired,
  history: object.isRequired,
};

const getInitials = userFullName => userFullName
  .split(' ')
  .map(n => n[0])
  .join('');

const customHeader = user => toggleHandler => (
  <div
    key="userdropdown-header"
    className="fiq-user-dropdown__custom-header"
    onClick={toggleHandler}
  >
    <div className="fiq-user-dropdown__avatar">
      <Avatar initials={getInitials(user.name)} src={user.avatar} size="regular" />
    </div>
    <Space size={4} />
    <p
      data-recording-sensitive
      className="tc-paragraph-regular fiq-user-dropdown__custom-header__full-name"
    >
      {user.name}
    </p>
    <Space size={4} />
    <Icon colorName="" size="small" name="CaretDown" />
  </div>
);

const customList = ({ logout, history, userId }) => [
  {
    item: onSelect => (
      <li onClick={onSelect} className="fiq-user-dropdown__custom-item">
        <Icon colorName="text-primary-500" size="regular" name="User" />
        <p className="tc-paragraph-regular">
          <Trans>My profile</Trans>
        </p>
      </li>
    ),
    onClick: () => history.push(`/settings/users/${userId}`),
  },
  {
    item: onSelect => BINQFUSE_ENABLED && (
      <li onClick={onSelect} className="fiq-user-dropdown__custom-item">
        <Icon colorName="text-primary-500" size="regular" name="ArrowRight" />
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="tc-paragraph-regular"
          href={BIQ_BASE_URL}
        >
          <Trans>Go to BrokerIQ</Trans>
        </a>
      </li>
    ),
  },
  {
    item: onSelect => (
      <li onClick={onSelect} className="fiq-user-dropdown__custom-item">
        <Icon colorName="text-primary-500" size="regular" name="Logout" />
        <p className="tc-paragraph-regular">
          <Trans>Logout</Trans>
        </p>
      </li>
    ),
    onClick: () => logout(),
  },
];

const UserDropdown = ({ user, history }) => {
  const { logout } = useAuth0();

  const logoutWithReturnTo = () => {
    logout({ returnTo: window.location.origin });
  };

  if (!user.id) {
    return (
      <Button color="transparent" icon="Logout" onClick={() => logoutWithReturnTo()}>
        Logout
      </Button>
    );
  }

  const listArguments = {
    logout: logoutWithReturnTo,
    history,
    userId: user.id,
  };

  return (
    <div className="fiq-user-dropdown">
      <Dropdown openLeft customList={customList(listArguments)} customHeader={customHeader(user)} />
    </div>
  );
};

UserDropdown.propTypes = propTypes;

export default inject(stores => ({
  user: stores.loginStore.user,
  keepLoggedIn: stores.loginStore.keepLoggedIn,
}))(observer(withRouter(UserDropdown)));
