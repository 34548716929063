import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { object, func } from 'prop-types';
import Stepper from 'App/components/Stepper';

// Components
import WorkflowHeader from 'Marketing/components/WorkflowHeader';
import WorkflowJourneysTable from 'Marketing/components/WorkflowJourneysTable';
import WorkflowBuilder from 'Marketing/components/WorkflowBuilder';
import WorkflowOverview from 'Marketing/components/WorkflowOverview';
import CommunicationKpi from 'Marketing/components/CommunicationKpi';

import PreviewWorkflowDetails, {
  openWorkflowDetails,
} from 'Marketing/components/overlays/PreviewWorkflowDetails/PreviewWorkflowDetails';
import {
  openPreviewWorkflow,
} from 'Marketing/components/overlays/PreviewWorkflow';
import { NoContentPage } from 'App/components/NoContentPage';


// Style
import './WorkflowOverviewPage.scss';

const propTypes = {
  match: object.isRequired,
  workflow: object.isRequired,
  fetchWorkflow: func.isRequired,
  fetchEventMetadata: func.isRequired,
  fetchSendToQueryBuilderMetadata: func.isRequired,
  resetWorkflow: func.isRequired,
  fetchJourneyStats: func.isRequired,
  journeyStats: object.isRequired,
};

export const stepKeys = {
  DETAILS: 'details',
  WORKFLOW: 'workflow',
  GOAL: 'goal',
  COMMUNICATION_KPI: 'communication_kpi',
};

const text = {
  WORKFLOW_OVERVIEW: 'Overview',
  JOURNEYS: 'Journeys',
  WORKFLOW: 'Workflow builder',
  COMMUNICATION_KPI: 'Communication',
};

const getSteps = (workflowId, steps, startDate) => [
  {
    key: stepKeys.DETAILS,
    title: text.WORKFLOW_OVERVIEW,
    component: () => <WorkflowOverview />,
  },
  {
    key: stepKeys.COMMUNICATION_KPI,
    title: text.COMMUNICATION_KPI,
    component: () => <CommunicationKpi workflowStartDate={startDate} id={workflowId} />,
  },
  {
    key: stepKeys.GOAL,
    title: text.JOURNEYS,
    component: () => <WorkflowJourneysTable id={workflowId} />,
  },
  {
    key: stepKeys.WORKFLOW,
    title: text.WORKFLOW,
    component: isActive => (
      <WorkflowBuilder
        isActive={isActive}
        steps={steps}
        isOverview
      />
    ),
  },
];


const WorkflowOverviewPage = ({
  workflow,
  match,
  fetchWorkflow,
  fetchSendToQueryBuilderMetadata,
  fetchEventMetadata,
  resetWorkflow,
  fetchJourneyStats,
  journeyStats,
}) => {
  const { workflowId } = match.params;
  const [activeStep, setActiveStep] = useState(stepKeys.DETAILS);

  const onStepChange = async (key) => {
    setActiveStep(key);
  };

  const openDetails = () => openWorkflowDetails(workflow);

  const fetchData = async () => {
    await fetchWorkflow(workflowId);
    await fetchJourneyStats(workflowId);
  };

  useEffect(() => {
    if (workflow.goal_event_type) fetchEventMetadata(workflow.goal_event_type.id);
    if (workflow.send_to_query) fetchSendToQueryBuilderMetadata();
  }, [workflow]);


  useEffect(() => {
    fetchData();
    return () => resetWorkflow();
  }, []);

  if (workflow.status === 'Draft') return <NoContentPage />;

  return (
    <div className="fiq-workflow-overview">
      <WorkflowHeader
        workflow={workflow}
        goBackIcon
        openDetails={openDetails}
        openPreviewWorkflow={openPreviewWorkflow}
      />
      <Stepper
        steps={getSteps(workflowId, journeyStats.steps, workflow.start_datetime)}
        onChange={onStepChange}
        activeStep={activeStep}
        hideIndex
      />
      <PreviewWorkflowDetails />
    </div>
  );
};

WorkflowOverviewPage.propTypes = propTypes;

export default inject(stores => ({
  fetchWorkflow: stores.workflows.fetchWorkflowData,
  fetchJourneyStats: stores.workflows.fetchJourneyStats,
  journeyStats: stores.workflows.journeyStats,
  workflow: stores.workflows.workflow,
  fetchSendToQueryBuilderMetadata: stores.workflows.fetchSendToQueryBuilderMetadata,
  fetchEventMetadata: stores.workflows.fetchEventMetadata,
  resetWorkflow: stores.workflows.resetWorkflow,
}))(observer(withRouter(WorkflowOverviewPage)));
