import { isBoolean } from 'lodash';

export const cleanEmptyValues = (values = {}) => {
  const cleaned = {};
  Object.keys(values).forEach((key) => {
    const value = values[key];

    if (value || isBoolean(value)) {
      cleaned[key] = value;
    }
  });

  return cleaned;
};
