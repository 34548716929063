import { bool, func, number, object } from 'prop-types';
import React from 'react';
import { Sidepanel } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import RenderFooter
  from '../components/SidepanelRenderFooter';
import Field from 'App/components/FieldRenderer';

/**
 *  BIQ Action Component
 */

const propTypes = {
  contactId: number.isRequired,
  sidepanelManager: object.isRequired,
  requestInProgress: bool.isRequired,
  addToExclusionList: func.isRequired,
  fetchContactData: func.isRequired,
  errors: object.isRequired,
  data: object.isRequired,
  validateForm: func.isRequired,
};

const ChangePassword = ({
  contactId,
  sidepanelManager,
  changePassword,
  requestInProgress,
  errors,
  validateForm,
  ...options
}) => {
  const submit = async () => {
    await changePassword();
    if (!errors.changePassword) {
      sidepanelManager.close();
    }
  };

  const cancel = () => sidepanelManager.close();

  return (
    <Sidepanel
      {...options}
      footerRender={() => (
        <RenderFooter
          confirm={submit}
          cancel={cancel}
          requestInProgress={requestInProgress}
        />
      )}
    >
      <Field
        id="password"
        name="password"
        type="password"
        label="Password"
        formId="changePasswordForm"
        className="tc-paragraph-regular"
      />
      <Field
        id="password2"
        name="password2"
        label="Confirm Password"
        type="password"
        formId="changePasswordForm"
        className="tc-paragraph-regular"
      />
    </Sidepanel>
  );
};

ChangePassword.propTypes = propTypes;

export default inject(stores => ({
  contactId: stores.contact.profile.contactData.id,
  errors: stores.contact.profile.errors,
  changePassword: stores.contact.profile.changePassword,
  requestInProgress: stores.contact.profile.requestInProgress.changePassword,
  validateForm: stores.forms.changePasswordForm.validateForm,
}))(observer(ChangePassword));
