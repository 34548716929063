import getErrorMessage from 'App/services/utilities/getErrorMessage';
import { notifier } from 'tc-biq-design-system';

/**
 * Returns a humanized error message from the error response
 */
const getHumanizedErrorMessage = (error: { humanized_message: string; query?: string[] }) => {
  const {
    humanized_message,
    query,
  } = error;

  let errorMessage = 'An error has occurred, please contact support.';

  if (humanized_message) {
    errorMessage = humanized_message;
  } else if (query && query.length > 0) {
    errorMessage = getErrorMessage(query[0]);
  }

  return errorMessage;
};

/**
 * Handles an error response from the server
 * @param error - An error object from the response
 * @param defaultErrorMessage - Default error message
 */
const handleErrorResponse = (error: any, defaultErrorMessage?: string) => {
  if (error?.response?.data) {
    notifier.error(getHumanizedErrorMessage(error.response.data));
  } else if (error?.error && typeof error?.error === 'string') {
    notifier.error(error.error);
  } else {
    notifier.error(defaultErrorMessage || 'An error has occurred, please contact support.');
  }
};

export {
  getHumanizedErrorMessage,
  handleErrorResponse,
};
