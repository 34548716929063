import React from 'react';
import { inject, observer } from 'mobx-react';
import { object, func, string } from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Field from 'App/components/FieldRenderer';
import AddEventAttribute from 'App/components/AddEventAttribute';

const text = {
  TOOLTIP: 'In order to add an attribute, pick an event from the querybuilder.',
};


// eslint-disable-next-line no-unused-vars
const MessageBody = ({
  field,
  updateFieldValue,
  fieldsMetadata,
  onChange,
  value,
}) => (
  <AddEventAttribute
    updateFieldValue={updateFieldValue}
    onChange={onChange}
    fieldKey="message_body"
    attributes={fieldsMetadata}
    disabled={isEmpty(fieldsMetadata)}
    tooltip={text.TOOLTIP}
  >
    <Field {...field} type="textarea" onChange={onChange} value={value} />
  </AddEventAttribute>
);

MessageBody.propTypes = {
  field: object.isRequired,
  updateFieldValue: func.isRequired,
  fieldsMetadata: object,
  onChange: func,
  value: string,
};

MessageBody.defaultProps = {
  fieldsMetadata: {},
  onChange: null,
  value: '',
};

export default inject(stores => ({
  updateFieldValue: stores.forms.customActionForm.updateFieldValue,
  fieldsMetadata: stores.rules.queryBuilder.fieldsMetadata,
}))(observer(MessageBody));
