import http from 'App/services/http';

const fetchApiKeyData = id => http.get(`/api-keys/${id}/`);
const addApiKey = payload => http.post('/api-keys/', payload);
const editApiKey = (id, payload) => http.patch(`/api-keys/${id}/`, payload);
const deleteApiKey = id => http.delete(`/api-keys/${id}/`);

export {
  fetchApiKeyData,
  addApiKey,
  editApiKey,
  deleteApiKey,
};
