import React from 'react';

import Page from 'App/components/Page';
import PaymentsTable, { tableConfig } from 'Transactions/components/PaymentsTable';

const bread = [
  { label: 'Payments' },
  { label: 'Internal transfers', route: '/transactions/internal-transfers' }
];
const exportDataOptions = { permission: 'finances_paymenttransaction', path: `/api${tableConfig.tableUrl}`, tableKey: tableConfig.tableKey };
const defaultFilters = { payment_type__in: 'Internal credit,Internal debit' }

const InternalTransfersPage = () => (
  <Page bread={bread} title="Internal transfers" style={{ margin: '0 20px' }} exportDataOptions={exportDataOptions}>
    <PaymentsTable defaultFilters={defaultFilters} />
  </Page>
);

export default InternalTransfersPage;
