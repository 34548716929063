/* eslint-disable react/prop-types */
import React from 'react';
import { Card, Icon, Space } from 'tc-biq-design-system';

const CARD_STYLE = {
  header: { display: 'flex', alignItems: 'center', height: '44px' },
  content: { padding: '0', backgroundColor: 'white' },
};

const headerTemplate = ({ icon, title }) => (
  <div className="workflow-details__header">
    <Icon name={icon} colorName="text-primary-500" />
    <Space size={12} />
    <span className="tc-heading-s">{title}</span>
  </div>
);

const SidepanelCard = ({ icon, title, children }) => (
  <>
    <Card
      headerTemplate={() => headerTemplate({ icon, title })}
      style={CARD_STYLE}
    >
      {children}
    </Card>
    <Space size={16} />
  </>
);

export default SidepanelCard;
