import { hasAccess } from 'App/services/permissionsService';
import AddNote from './AddNote';
import actionTypes from '../types';

const text = {
  LABEL: 'Add Note',
};

const ACTION_ICON = 'Notes';

export default {
  name: 'addNote',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: actionTypes.TOP,
  hasPermission: () => hasAccess('contacts_note', 'create'),
  handler: (comboxManager) => {
    const comboxOptions = {
      title: text.LABEL,
      id: 'add-note-combox',
    };

    comboxManager.add(AddNote, comboxOptions);
  },
};
