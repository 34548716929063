import React, { useState } from 'react';
import moment from 'moment';
import { object, string, oneOfType, number } from 'prop-types';
import { Space, Button, TextArea, Popconfirm, Avatar } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';

import If from 'App/components/If';
import { UserCell } from 'App/components/gridCellRenderers';
import withErrorBoundary from 'App/components/hoc/withErrorBoundary';
import { hasAccess } from 'App/services/permissionsService';
import extractDisplayName from 'App/services/utilities/extractDisplayName';

const text = {
  EDIT: 'Edit',
  REMOVE: 'Remove',
  SAVE_CHANGES: 'Save changes',
  DISCARD: 'Discard',
  POP_CONFIRM: 'Delete',
  POP_CANCEL: 'Cancel',
  POP_QUESTION: 'Are you sure you want to delete this note?',
};

const propTypes = {
  owner: object,
  contact: object.isRequired,
  content: string.isRequired,
  noteIndex: number.isRequired,
  id: oneOfType([number, string]).isRequired,
  created: string.isRequired,
};

const defaultProps = {
  owner: null,
};

// eslint-disable-next-line
const NoteContent = inject(stores => ({
  updateNote: stores.contact.notes.updateNote,
  deleteNote: stores.contact.notes.deleteNote,
  error: stores.contact.notes.errors.updateNote,
  userId: stores.loginStore.user.id,
  requestInProgress: stores.contact.notes.requestInProgress.updateNote,
}))(
  observer(
    // eslint-disable-next-line
    ({
      content,
      noteIndex,
      updateNote,
      error,
      ownerId,
      userId,
      requestInProgress,
      deleteNote,
      id,
      contactId,
    }) => {
      const [editMode, setEditMode] = useState(false);
      const [noteText, updateNoteText] = useState(content);
      const onChange = ({ target }) => updateNoteText(target.value);
      const toggleEditMode = () => {
        setEditMode(!editMode);
        updateNoteText(content);
      };
      const editNote = async () => {
        await updateNote(noteText, noteIndex, contactId);
        if (!error) toggleEditMode();
      };

      const removeNote = () => deleteNote(contactId, id);

      return (
        <div className="fiq-notes__note__body tc-paragraph-regular">
          <If condition={editMode}>
            <TextArea
              value={noteText}
              onChange={onChange}
              className="tc-paragraph-regular"
              rows={2}
              helpText={error}
              hasError={!!error}
            />
          </If>
          <If condition={!editMode}>{content}</If>
          <div className="fiq-notes__note__body__edit-remove">
            <If condition={editMode}>
              <Button size="small" onClick={toggleEditMode} color="ghost">
                {text.DISCARD}
              </Button>
              <Button
                disabled={requestInProgress}
                loading={requestInProgress}
                onClick={editNote}
                size="small"
              >
                {text.SAVE_CHANGES}
              </Button>
            </If>
            <If condition={!editMode && ownerId === userId}>
              <If condition={hasAccess('contacts_note', 'update')}>
                <span onClick={toggleEditMode} className="tc-paragraph-strong">
                  {text.EDIT}
                </span>
              </If>
              <Space size={8} />
              <If condition={hasAccess('contacts_note', 'delete')}>
                <Popconfirm
                  type="destructive"
                  placement="bottom"
                  icon="Delete"
                  label={text.POP_QUESTION}
                  onConfirm={removeNote}
                  buttonLabels={{ cancel: text.POP_CANCEL, confirm: text.POP_CONFIRM }}
                >
                  <span className="tc-paragraph-strong">{text.REMOVE}</span>
                </Popconfirm>
              </If>
            </If>
          </div>
        </div>
      );
    },
  ),
);

const NoOwner = () => (
  <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
    <div style={{ width: '32px' }}>
      <Avatar />
    </div>
    <Space size={8} />
    <span>Not available</span>
  </div>
);

const Note = ({ owner, contactId, content, created, noteIndex, id }) => {
  const date = moment(created).format('MMM Do YYYY');
  const time = moment(created).format('HH:mm:ss');
  const ownerId = owner ? owner.id : '';
  return (
    <div className="fiq-notes__note">
      <div className="fiq-notes__note__header">
        <div style={{ whiteSpace: 'nowrap' }}>
          {owner && (
            <UserCell
              id={owner.id}
              fullName={extractDisplayName(owner)}
              avatarSource={null}
              route="/users/"
            />
          )}
          {!owner && <NoOwner />}
        </div>
        <div className="fiq-notes__note__header__date">
          <div className="tc-paragraph-regular">{date}</div>
          <div className="tc-paragraph-regular text-neutral-500">{time}</div>
        </div>
      </div>

      { contactId && (
        <NoteContent
          content={content}
          noteIndex={noteIndex}
          ownerId={ownerId}
          id={id}
          contactId={contactId}
        />
      ) }
    </div>
  );
};

Note.propTypes = propTypes;
Note.defaultProps = defaultProps;

export default withErrorBoundary(Note);
