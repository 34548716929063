/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { object, bool, func } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Accordion, Icon, Space, Table, Radio, Checkbox } from 'tc-biq-design-system';
import classNames from 'classnames';

import './EndpointAccordion.scss';

import PERMISSIONS_FIELD_TYPES from 'App/enums/permissionsFieldTypes';
import If from 'App/components/If';

const propTypes = {
  endpoint: object.isRequired,
  isOpen: bool,
  updateFieldPermission: func.isRequired,
  updateEndpointPermission: func.isRequired,
};

const defaultProps = {
  isOpen: false,
};

const text = {
  FIELDS: 'Fields',
  EXTRA_OPERATIONS: 'Extra operations',
  READ_ONLY: 'Read',
  WRITE: 'Read and Write',
  HIDE: 'Hide',
  ACTIVE: 'Active',
};

const fieldPermEnum = {
  read_fields: ['read', 'export'],
  write_fields: ['create', 'update'],
  hidden_fields: ['read', 'export'],
};

const isDisabled = (fieldKey, endpointPermissions, readOnlyFields, fieldId) => {
  if (readOnlyFields && readOnlyFields.includes(fieldId)) return true;
  const fieldConfigKeys = Object.keys(fieldPermEnum);
  return !Object.keys(endpointPermissions).filter(
    key => fieldConfigKeys.includes(fieldKey)
      && endpointPermissions[key]
      && fieldPermEnum[fieldKey].includes(key),
  ).length;
};

const formatFieldName = (fieldName) => {
  const name = fieldName.split('_').join(' ');
  return `${name[0].toUpperCase()}${name.substring(1)}`;
};

const EndpointPermissions = inject(stores => ({
  updatePermission: stores.roles.updateEndpointPermission,
}))(
  observer(({ name, permissions, updatePermission }) => Object.keys(permissions).map(key => (
    <div className="fiq-role-accordion__role-permission" key={`${name}-${key}`}>
      <Checkbox
        onChange={e => updatePermission(name, key, e.target.checked)}
        checked={permissions[key]}
      >
        <span className="tc-paragraph-strong">{key.toUpperCase()}</span>
      </Checkbox>
    </div>
  ))),
);

const headerTemplate = (endpoint, toggle, visible, updateEndpointPermission, hasFields) => (
  <div className={classNames('fiq-role-accordion__header', { open: visible })} onClick={toggle}>
    <div className="fiq-role-accordion__header-title">
      {hasFields && (
        <span className="fiq-role-accordion__caret-icon">
          {visible ? (
            <Icon size="small" name="CaretDown" />
          ) : (
            <Icon size="small" name="CaretRight" />
          )}
        </span>
      )}
      <Space size={12} />
      <span className="tc-paragraph-strong">{endpoint.name}</span>
    </div>
    <EndpointPermissions
      name={endpoint.name}
      permissions={endpoint.endpointPermissions}
      updateEndpointPermission={updateEndpointPermission}
    />
  </div>
);

const renderRadio = (fieldKey, updatePermission, endpointPermissions, readOnlyFields) => data => (
  <Radio
    value={fieldKey}
    onChange={() => updatePermission(data.name, data.field, fieldKey, !data[fieldKey])}
    checked={data[fieldKey]}
    disabled={isDisabled(fieldKey, endpointPermissions, readOnlyFields, data.field)}
  />
);

const renderCheckbox = updateExtraOperationPermission => data => (
  <Checkbox
    onChange={e => updateExtraOperationPermission(data.name, data.operation, e.target.checked)}
    checked={data.isActive}
  />
);

const roleTableCols = (updatePermission, endpointPermissions, readOnlyFields) => [
  { title: text.FIELDS, key: 'field', render: data => formatFieldName(data.field) },
  {
    title: text.READ_ONLY,
    key: PERMISSIONS_FIELD_TYPES.readFields,
    render: renderRadio(PERMISSIONS_FIELD_TYPES.readFields, updatePermission, endpointPermissions),
  },
  {
    title: text.WRITE,
    key: PERMISSIONS_FIELD_TYPES.writeFields,
    render: renderRadio(
      PERMISSIONS_FIELD_TYPES.writeFields,
      updatePermission,
      endpointPermissions,
      readOnlyFields,
    ),
  },
  {
    title: text.HIDE,
    key: PERMISSIONS_FIELD_TYPES.hiddenFields,
    render: renderRadio(
      PERMISSIONS_FIELD_TYPES.hiddenFields,
      updatePermission,
      endpointPermissions,
    ),
  },
];

const extraOperationsTableCols = updateExtraOperationPermission => [
  { title: text.EXTRA_OPERATIONS, key: 'operation', render: data => formatFieldName(data.operation) },
  {
    title: text.ACTIVE,
    key: PERMISSIONS_FIELD_TYPES.extraOperations,
    render: renderCheckbox(updateExtraOperationPermission),
  },
];

const EndpointAccordion = ({
  endpoint,
  isOpen,
  updateFieldPermission,
  updateEndpointPermission,
  updateExtraOperationPermission,
}) => {
  const [visible, toggleVisible] = useState(isOpen);
  useEffect(() => {
    toggleVisible(isOpen);
  }, [isOpen]);
  const toggleAccordion = () => toggleVisible(!visible);
  const { fieldPermissions, name, endpointPermissions, readOnlyFields, extraOperations } = endpoint;
  const hasFields = fieldPermissions && !!fieldPermissions.length;
  const hasExtraOperations = extraOperations && !!extraOperations.length;

  return (
    <Accordion
      title=""
      headerTemplate={headerTemplate(
        endpoint,
        toggleAccordion,
        visible,
        updateEndpointPermission,
        hasFields,
      )}
      visible={hasFields || hasExtraOperations ? visible : false}
    >
      {/* Fields */}
      <div className="fiq-role-accordion__body">
        <Table
          key={name}
          cols={roleTableCols(updateFieldPermission, endpointPermissions, readOnlyFields)}
          data={fieldPermissions}
        />
      </div>

      {/* Extra operations */}
      <If condition={hasExtraOperations}>
        <div className="fiq-role-accordion__body pt-0">
          <Table
            key={name}
            cols={extraOperationsTableCols(updateExtraOperationPermission)}
            data={extraOperations}
          />
        </div>
      </If>
    </Accordion>
  );
};

EndpointAccordion.propTypes = propTypes;
EndpointAccordion.defaultProps = defaultProps;

export default EndpointAccordion;
