import React from 'react';
import { bool, object } from 'prop-types';
import { Sidepanel } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { closeOverlay, openOverlay } from 'App/services/overlayService';
import { RouteCell, StatusCell } from 'App/components/gridCellRenderers';

import './WorkflowsSidepanel.scss';

const SIDEPANEL_ID = 'WORKFLOWS_SIDEPANEL';

const text = {
  SIDEPANEL_TITLE: 'Workflows',
};

export const openWorkflowsSidepanel = (workflows) => {
  openOverlay(SIDEPANEL_ID, { workflows });
};

export const closeWorkflowsSidepanel = () => {
  closeOverlay(SIDEPANEL_ID);
};

const WorkflowNameCell = ({ data, value }) => RouteCell('/marketing/workflows-overview/')({ hrefValue: data.id, value });

// eslint-disable-next-line react/prop-types
const WorkflowsTable = ({ workflows }) => (
  <div className="info-sidepanel__table">
    {workflows.map(item => (
      <div key={item.id} className="info-sidepanel__table__item">
        <WorkflowNameCell data={item} value={item.name} />
        <StatusCell value={item.status} />
      </div>
    ))}
  </div>
);


const WorkflowsSidepanel = ({
  visible,
  parameters,
}) => {
  if (!visible) return null;
  return (
    <Sidepanel
      visible={visible}
      hideFooter
      title={text.SIDEPANEL_TITLE}
      onCloseIconClick={closeWorkflowsSidepanel}
      icon="Marketing"
    >
      <WorkflowsTable workflows={parameters.workflows} />
    </Sidepanel>
  );
};

WorkflowsSidepanel.propTypes = {
  visible: bool,
  parameters: object,
};

WorkflowsSidepanel.defaultProps = {
  visible: false,
  parameters: {},
};

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
}))(observer(WorkflowsSidepanel));
