import React, { useEffect } from 'react';
import { Dropdown } from 'tc-biq-design-system';
import { object } from 'prop-types';

import Page from 'App/components/Page';
import { ContactsTable, tableConfig } from 'Contacts/components';
import { openLeadImportModal } from 'Contacts/components/overlays/LeadImport';
import { openBulkLeadImportModal } from 'Contacts/components/overlays/BulkLeadImport';
import { hasAccess } from 'App/services/permissionsService';

const bread = [
  { label: 'Contacts', route: '/contacts' },
  { label: 'Leads', route: '/contacts/leads' }
];

const exportDataOptions = { permission: 'finances_tradeposition', path: `/api${tableConfig.tableUrl}`, tableKey: tableConfig.tableKey };

const headerActions = () => (
  <Dropdown
    title="Add leads"
    list={[
      { label: 'Single lead', onClick: openLeadImportModal },
      { label: 'Bulk import', onClick: openBulkLeadImportModal },
    ]}
  />
);

const defaultFilters = { status__exact: 'Lead' };

const LeadsPage = ({ match }) => {
  useEffect(() => {
    const { action } = match.params;
    if (action === 'leadImportBulk') openBulkLeadImportModal();
    else if (action === 'leadImport') openLeadImportModal();
  }, []);
  return (
    <Page
      bread={bread}
      title="Leads"
      style={{ margin: '0 20px' }}
      exportDataOptions={exportDataOptions}
      headerActions={hasAccess('contacts_contact', 'create') ? headerActions : null}
    >
      <ContactsTable defaultFilters={defaultFilters} />
    </Page>
  );
};

LeadsPage.propTypes = { match: object.isRequired };

export default LeadsPage;
