import { action, observable, runInAction, makeObservable } from 'mobx';
import moment from 'moment';
import { fetchMetrics, fetchMetricsData } from 'Dashboard/services/dashboardService';
import run from 'App/services/utilities/run';
import {
  calculateFinanceNumericsData,
  formatMetrics,
  setGranularity,
  buildQueryForPreviousInterval,
} from './utils';

const initialNumericData = {
  value: null,
  percentage: null,
  isGrowing: null,
  currency: null,
};

export default class DashboardStore {
  dashboardInterval = {
    startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };

  metrics = {};

  financeNumericsData = {
    incomingAverage: initialNumericData,
    incomingCount: initialNumericData,
    incomingTotal: initialNumericData,
    netIncome: initialNumericData,
    redepositsTotal: initialNumericData,
    redepositsCount: initialNumericData,
    FTDTotal: initialNumericData,
    FTDCount: initialNumericData,
    FTDAverage: initialNumericData,
    outgoingCount: initialNumericData,
    outgoingAverage: initialNumericData,
    outgoingTotal: initialNumericData,
  };

  marketingNumericsData = {
    finishedWorkflows: initialNumericData,
  };

  contactsStatusData = {};

  interactionsData = {};

  contactsData = {};

  contactsNumericData = initialNumericData;

  fetchMetricsDataInProgress = false;

  metricsErrors = {};

  constructor() {
    makeObservable(this, {
      dashboardInterval: observable,
      metrics: observable,
      financeNumericsData: observable,
      marketingNumericsData: observable,
      contactsStatusData: observable,
      interactionsData: observable,
      contactsData: observable,
      contactsNumericData: observable,
      fetchMetricsDataInProgress: observable,
      metricsErrors: observable,
      fetchMetrics: action.bound,
      fetchFinanceNumericData: action.bound,
      setInteractionsData: action.bound,
      setContactsData: action.bound,
      setMarketingNumericsData: action.bound,
      setDashboardInterval: action.bound,
    });
  }

  async fetchMetrics() {
    const [err, data] = await run(fetchMetrics());
    if (err) {
      runInAction(() => {
        this.metricsErrors = err;
      });
    } else {
      runInAction(() => {
        this.metrics = formatMetrics(data);
      });
    }
  }

  async fetchFinanceNumericData() {
    const { startDate, endDate } = this.dashboardInterval;
    const incomingQuery = {
      metric: 'incoming_funds',
      query: {
        start_of_interval: startDate,
        end_of_interval: endDate,
        granularity: 'all',
        dimensions: ['is_first_time_deposit'],
      },
    };
    const outgoingQuery = {
      metric: 'outgoing_funds',
      query: {
        start_of_interval: startDate,
        end_of_interval: endDate,
        granularity: 'all',
      },
    };
    runInAction(() => {
      this.fetchMetricsDataInProgress = true;
    });

    const response = await Promise.all([
      fetchMetricsData(incomingQuery),
      fetchMetricsData(buildQueryForPreviousInterval(incomingQuery)),
      fetchMetricsData(outgoingQuery),
      fetchMetricsData(buildQueryForPreviousInterval(outgoingQuery)),
    ]).catch((err) => {
      if (err.response.status === 503) {
        return err.response.data.detail;
      }
      return err;
    });

    const [incomingData, incomingOldData, outgoingData, outgoingOldData] = response.map(
      item => item.data,
    );
    runInAction(() => {
      this.financeNumericsData = calculateFinanceNumericsData(
        incomingData,
        incomingOldData,
        outgoingData,
        outgoingOldData,
      );
      this.fetchMetricsDataInProgress = false;
    });
  }

  setInteractionsData(data) {
    this.interactionsData = { ...this.interactionsData, ...data };
  }

  setContactsData(data) {
    this.contactsData = { ...this.contactsData, ...data };
  }

  setMarketingNumericsData(key, data) {
    runInAction(() => {
      this.marketingNumericsData = {
        ...this.marketingNumericsData,
        [key]: data,
      };
    });
  }

  setDashboardInterval(date) {
    this.dashboardInterval = date;
    const { startDate, endDate } = date;
    this.granularity = setGranularity(startDate, endDate);
  }
}
