import isCurrencyCode from 'App/services/utilities/validators/isCurrencyCode';

export function currencyFormater(value, currency) {
  const text = {
    NA: 'N/A',
  };
  const amount = Number.parseFloat(value);
  if (isCurrencyCode(currency)) {
    return amount.toLocaleString('en-EN', { style: 'currency', currency });
  }
  return `${currency || text.NA} ${amount.toFixed(2)}`;
}
