import React, { useMemo, useEffect } from 'react';
import { SidebarMenu } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import { array, object } from 'prop-types';
import { inject, observer } from 'mobx-react';

import * as CommandbarService from 'App/services/commandbarService';
import tradecore_logo from '../../../../../assets/images/logos/tradecore-white-logo.svg';

const propTypes = {
  items: array.isRequired,
  location: object.isRequired,
  history: object.isRequired,
};


const LogoFull = () => (
  <div>
    <img src={tradecore_logo} alt="Logo" width="120" />
  </div>
);

const MainSidebar = ({ items, location, history }) => {
  const { pathname } = location;

  const formatedItems = useMemo(() => items.map(item => ({
    ...item,
    icon: item.icon ? item.icon() : null,
    iconActive: item.iconActive ? item.iconActive() : null,
  })), [items]);
  const onRouteChange = route => history.push(route);

  useEffect(() => {
    CommandbarService.addMenuCommands(formatedItems);
  }, [formatedItems]);

  return (
    <SidebarMenu
      Logo={LogoFull}
      items={formatedItems}
      activeRoute={pathname}
      onChange={onRouteChange}
    />
  );
};

MainSidebar.propTypes = propTypes;

export default inject(stores => ({
  items: stores.navigator.visibleItems,
}))(observer(withRouter(MainSidebar)));
