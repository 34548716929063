import React, { useEffect, useCallback } from 'react';
import { func, array, bool } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Space, Icon } from 'tc-biq-design-system';

import Loader from 'App/components/Loader';
import BlockTitle from 'App/components/BlockTitle';
import ActionCard from './ActionCard';

import './CustomActionActions.scss';

const propTypes = {
  fetchActions: func.isRequired,
  actions: array.isRequired,
  loading: bool.isRequired,
  addAction: func.isRequired,
};

const text = {
  ADD_ACTIONS: 'Add another action',
  ACTIONS_TITLE: 'Action to perform',
};

const CustomActionActions = ({ fetchActions, actions, loading, addAction }) => {
  const fetchData = useCallback(async () => {
    await fetchActions();
  });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="fiq-rule-actions">
      <BlockTitle title={text.ACTIONS_TITLE} icon="Action" count={actions.length} />
      <Space size={32} />

      <Loader visible={loading} />
      {actions.map((action, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ActionCard key={index} actionIndex={index} action={action} />
      ))}
      <Space size={24} />
      {actions.length < 10 && (
        <div onClick={addAction} className="add-action">
          <Icon name="Plus" colorName="text-primary-500" />
          <Space size={8} />
          <span className="text-primary-500 tc-paragraph-strong">{text.ADD_ACTIONS}</span>
        </div>
      )}
    </div>
  );
};

CustomActionActions.propTypes = propTypes;

export default inject(stores => ({
  actions: stores.customActions.actions,
  fetchActions: stores.customActions.fetchActions,
  loading: stores.customActions.requestInProgress.actions,
  addAction: stores.customActions.addAction,
}))(observer(CustomActionActions));
