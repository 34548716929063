import React, { useState } from 'react';
import { Modal, notifier } from 'tc-biq-design-system';
import { object, string, number, func, bool } from 'prop-types';
import { inject, observer } from 'mobx-react';

import SidepanelFooter from 'App/components/SidepanelFooter';
import { approveContact, rejectContact } from 'Contacts/services/contactsService';
import { handleErrorResponse } from 'App/services/utilities/error.utils';

const propTypes = {
  contactId: number.isRequired,
  sidepanelManager: object.isRequired,
  firstName: string,
  lastName: string,
  fetchContactData: func.isRequired,
  actionType: string.isRequired,
};

const defaultProps = {
  firstName: '',
  lastName: '',
};

const text = {
  PROMPT: (firstName, lastName, action) => `Are you sure you want to ${action} ${firstName} ${lastName}?`,
  APPROVE: 'approve',
  REJECT: 'reject',
  APPROVE_SUCCESS: 'Approved contact successfully',
  REJECT_SUCCESS: 'Rejected contact successfully',
  BUTTON_LABELS_APPROVE: {
    confirm: 'Approve contact',
    cancel: 'Cancel',
  },
  BUTTON_LABELS_REJECT: {
    confirm: 'Reject contact',
    cancel: 'Cancel',
  },
};

const RenderFooter = ({ confirm, cancel, requestInProgress, isApprove }) => (
  <SidepanelFooter
    submitInProgress={requestInProgress}
    execute={confirm}
    close={cancel}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={isApprove ? text.BUTTON_LABELS_APPROVE : text.BUTTON_LABELS_REJECT}
  />
);

RenderFooter.propTypes = {
  confirm: func.isRequired,
  cancel: func.isRequired,
  requestInProgress: bool.isRequired,
  isApprove: bool.isRequired,
};

const ApproveTo = ({
  sidepanelManager,
  contactId,
  firstName,
  lastName,
  actionType,
  fetchContactData,
  ...options
}) => {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const isApprove = actionType === 'approve';
  const actionText = isApprove ? text.APPROVE : text.REJECT;

  const submit = () => {
    setRequestInProgress(true);
    const promise = isApprove ? approveContact : rejectContact;
    promise(contactId).then(
      () => {
        setRequestInProgress(false);
        sidepanelManager.close();
        notifier.success(isApprove ? text.APPROVE_SUCCESS : text.REJECT_SUCCESS);
        fetchContactData(contactId);
      },
      (err) => {
        setRequestInProgress(false);
        sidepanelManager.close();
        handleErrorResponse(err);
      },
    );
  };

  const cancel = () => sidepanelManager.close();

  return (
    <Modal
      {...options}
      footerRender={() => (
        <RenderFooter
          confirm={submit}
          cancel={cancel}
          requestInProgress={requestInProgress}
          isApprove={isApprove}
        />
      )}
    >
      {text.PROMPT(firstName, lastName, actionText)}
    </Modal>
  );
};

ApproveTo.propTypes = propTypes;
ApproveTo.defaultProps = defaultProps;

export default inject(stores => ({
  contactId: stores.contact.profile.contactData.id,
  fetchContactData: stores.contact.profile.fetchContactData,
  firstName: stores.contact.profile.contactData.first_name,
  lastName: stores.contact.profile.contactData.last_name,
}))(observer(ApproveTo));
