import React from 'react';

import GridComponentFactory from 'App/components/grid';
import modifiers from './modifiers';
import { object } from 'prop-types';

export const tableConfig = {
  tableUrl: '/finances/payment-transactions/',
  tableKey: 'payments',
};

const propTypes = {
  defaultFilters: object
}

const defaultProps = {
  defaultFilters: {}
}

const { GridComponent } = GridComponentFactory(tableConfig);

const PaymentsTable = ({ defaultFilters }) => (
  <GridComponent
    enableSegmentFiltering
    modifiers={modifiers}
    defaultFilters={defaultFilters}
  />
);

PaymentsTable.propTypes = propTypes;
PaymentsTable.defaultProps = defaultProps;

export default PaymentsTable;
