import React from 'react';
import { Space, Avatar, Flag } from 'tc-biq-design-system';

import {
  RouteCell,
  PillCell,
  UserCell,
  SmartlookCell,
  ContactCell,
} from 'App/components/gridCellRenderers';
import NoDataDash from 'App/components/NoDataDash';
import hideCols from 'App/services/utilities/hideCols';
import getInitials from 'App/services/utilities/getInitials';
import extractDisplayName from 'App/services/utilities/extractDisplayName';
import { openPreviewTrackingLink } from 'Settings/Sections/TrackingLinks/components/overlays/PreviewTrackingLink';
import { fetchTrackingLink } from 'Marketing/services/TrackingLinksService';
import { getByName } from 'App/helpers/countries';
import operatorEnum from 'App/enums/operatorEnum';

// eslint-disable-next-line
const RenderIdAvatar = ({ value, data }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div>
      <Avatar src={data.avatar} initials={getInitials(data)} />
    </div>
    <Space size={8} />
    {RouteCell('/contacts/')({ hrefValue: `${value}/activityStream`, value })}
  </div>
);

// eslint-disable-next-line react/prop-types
const RenderUser = ({ value }) => (value ? (
  <UserCell id={value.id} avatar={value.avatar} fullName={extractDisplayName(value)} />
) : (
  <NoDataDash />
));

// eslint-disable-next-line react/prop-types
const RenderTrackingLink = ({ value }) => {
  if (!value) return '-';
  const onClick = async () => {
    const { data } = await fetchTrackingLink(value.id);
    openPreviewTrackingLink(data);
  };
  return (
    <div className="like-a-link" onClick={onClick}>
      {value.name}
    </div>
  );
};

export default {
  ...hideCols(['avatar']),
  id: {
    headerName: '',
    pinned: 'left',
    minWidth: 120,
    resizable: false,
    cellRendererFramework: RenderIdAvatar,
    quickFilter: {
      operator: operatorEnum.EXACT,
    },
  },
  first_name: {
    cellRendererFramework: SmartlookCell,
    quickFilter: {
      operator: operatorEnum.CONTAINS,
    },
  },
  last_name: {
    cellRendererFramework: SmartlookCell,
    quickFilter: {
      operator: operatorEnum.CONTAINS,
    },
  },
  compliance_manager: { cellRendererFramework: RenderUser },
  sales_manager: { cellRendererFramework: RenderUser },
  retention_manager: { cellRendererFramework: RenderUser },
  tags: { cellRendererFramework: PillCell({ displayName: 'label' }) },
  email: {
    minWidth: 240,
    cellRendererFramework: SmartlookCell,
    quickFilter: {
      operator: operatorEnum.CONTAINS,
    },
  },
  phone: {
    cellRendererFramework: SmartlookCell,
    quickFilter: {
      operator: operatorEnum.CONTAINS,
    },
  },
  segments: { cellRendererFramework: PillCell({ displayName: 'name' }) },
  workflows: { headerName: 'Workflows', cellRendererFramework: PillCell({ displayName: 'name' }) },
  address: { cellRendererFramework: SmartlookCell },
  city: { cellRendererFramework: SmartlookCell },
  zip: { cellRendererFramework: SmartlookCell },
  landing_page: { cellRendererFramework: SmartlookCell },
  created: {
    minWidth: 280,
    quickFilter: {
      operator: operatorEnum.RANGE,
    },
  },
  updated: {
    minWidth: 280,
    quickFilter: {
      operator: operatorEnum.RANGE,
    },
  },
  whitelabel_name: {
    quickFilter: {
      operator: operatorEnum.CONTAINS,
    },
  },
  tracking_link: { cellRendererFramework: RenderTrackingLink },
  country: {
    // eslint-disable-next-line react/prop-types
    cellRendererFramework: ({ value }) => {
      if (!value) return <NoDataDash />;
      const countryData = getByName(value);
      if (!countryData) return <NoDataDash />;
      return <span title={value}><Flag country={countryData.iso2} /></span>;
    },
    minWidth: 150,
    quickFilter: {
      operator: operatorEnum.EXACT,
    },
  },
  referred_by: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => value ? (
      <ContactCell
        id={value.id}
        avatar={value.avatar}
        fullName={`${value.first_name} ${value.last_name}`}
      />
    ) : (
      <NoDataDash />
    ),
  },
};
