import { action, runInAction, observable, makeObservable } from 'mobx';
import { notifier } from 'tc-biq-design-system';

import {
  fetchWebhookOptions,
  fetchWebhook,
  createWebhook,
  editWebhook,
} from 'Settings/Sections/Webhooks/services/webhooksService';
import forms from 'App/rootStore/formsStore';
import formatPayload from 'App/services/utilities/formatPayload';
import { handleErrorResponse } from 'App/services/utilities/error.utils';

const text = {
  WEBHOOK_FAILED: 'Failed to fetch webhook data',
  CREATE_FAILED: 'Failed to create webhook',
  CREATE_SUCCESS: 'Successfully created webhook',
  EDIT_SUCCESS: 'Successfully edited webhook',
  EDIT_FAILED: 'Failed to edit webhook',
};

export default class WebhooksStore {
  requestInProgress = {
    createWebhook: false,
    editWebhook: false,
    deleteWebhook: false,
    fieldsDef: false,
    fetchWebhook: false,
  };

  errors = {
    fieldsDef: null,
    trigger_events: null,
  };

  fieldsDef = {};

  webhook = {};

  webhookTriggers = [];

  constructor() {
    makeObservable(this, {
      requestInProgress: observable,
      errors: observable,
      fieldsDef: observable,
      webhook: observable,
      webhookTriggers: observable,
      fetchFieldsDef: action.bound,
      fetchWebhookData: action.bound,
      createWebhook: action.bound,
      editWebhook: action.bound,
      updateWebhookTriggers: action.bound,
      resetWebhookForm: action.bound,
    });
  }

  async fetchFieldsDef() {
    this.requestInProgress.fieldsDef = true;
    try {
      const response = await fetchWebhookOptions();
      runInAction(() => {
        this.fieldsDef = response.data.actions.POST;
      });
    } catch (err) {
      runInAction(() => {
        this.errors.fieldsDef = err.response;
      });
      handleErrorResponse(err);
    } finally {
      runInAction(() => {
        this.requestInProgress.fieldsDef = false;
      });
    }
  }

  async fetchWebhookData(id) {
    this.requestInProgress.fetchWebhook = true;
    try {
      const response = await fetchWebhook(id);
      runInAction(() => {
        this.webhook = response.data;
      });
    } catch (err) {
      handleErrorResponse(err, text.WEBHOOK_FAILED);
    } finally {
      forms.webhookForm.setFieldsData(this.webhook);
      runInAction(() => {
        this.requestInProgress.fetchWebhook = false;
      });
    }
  }

  async createWebhook(history) {
    this.requestInProgress.createWebhook = true;
    try {
      const { name, url } = forms.webhookForm.data;
      const payload = formatPayload({
        name,
        url,
        trigger_events: this.webhookTriggers,
      });
      const { data } = await createWebhook(payload);
      notifier.success(text.CREATE_SUCCESS);
      this.resetWebhookForm();
      history.push(`/settings/webhooks/${data.id}`);
    } catch (err) {
      if (err.response && err.response.data) {
        forms.webhookForm.setFieldsErrors(err.response.data);
      }
      handleErrorResponse(err, text.CREATE_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.createWebhook = false;
      });
    }
  }

  async editWebhook(id, history) {
    this.requestInProgress.editWebhook = true;
    try {
      const { name, url } = forms.webhookForm.data;
      const payload = formatPayload({
        name,
        url,
        trigger_events: this.webhookTriggers,
      });
      await editWebhook(id, payload);
      notifier.success(text.EDIT_SUCCESS);
      this.resetWebhookForm();
      history.push('/settings/webhooks');
    } catch (err) {
      if (err.response && err.response.data) {
        forms.webhookForm.setFieldsErrors(err.response.data);
      }
      handleErrorResponse(err, text.EDIT_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.editWebhook = false;
      });
    }
  }

  updateWebhookTriggers(webhookTriggers) {
    forms.webhookForm.resetFieldError('trigger_events');
    this.webhookTriggers = webhookTriggers;
  }

  resetWebhookForm() {
    this.webhook = {};
    this.webhookTriggers = {};
    forms.webhookForm.resetFieldsData();
  }
}
