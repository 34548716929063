import React from 'react';
import { any, bool } from 'prop-types';
import classnames from 'classnames';

import './SectionWrapper.scss';

const SectionWrapper = ({ children }) => <div className="fiq-section-wrapper">{children}</div>;

SectionWrapper.propTypes = { children: any.isRequired };

const FieldWrapper = ({ children, hideBorder }) => (
  <div className={classnames('fiq-workflow-form__field-wrapper', { 'fiq-workflow-form__field-wrapper--hide-border': hideBorder })}>
    {children}
  </div>
);

FieldWrapper.propTypes = { children: any.isRequired, hideBorder: bool };
FieldWrapper.defaultProps = { hideBorder: false };
SectionWrapper.FieldWrapper = FieldWrapper;

export default SectionWrapper;
