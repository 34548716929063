import AddToExclusionList from './AddToExclusionList';
import Archive from './Archive';
import Unarchive from './Unarchive';
import LoginAsContact from './LoginAsContact';
import ChangePassword from './ChangePassword';
import NewTradingAccount from './NewTradingAccount';
import EditTradingAccount from './EditTradingAccount';

/**
 * List with all BIQ actions
 */
const BIQ_ACTIONS = [
  AddToExclusionList,
  Archive,
  Unarchive,
  LoginAsContact,
  ChangePassword,
  NewTradingAccount,
  EditTradingAccount,
];

/**
 * Returns all BIQ actions if BINQFUSE_ENABLED is enabled
 */
export default () => (BINQFUSE_ENABLED ? BIQ_ACTIONS : []);
