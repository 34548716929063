import React from 'react';
import { NavCard } from 'tc-biq-design-system';
import { number, oneOfType, string } from 'prop-types';
import WorkflowActivityStream from './WorkflowActivityStream';
import WorkflowRecipientsTable from './WorkflowRecipientsTable';


const propTypes = {
  workflowId: oneOfType([number, string]).isRequired,
};

const tabTypes = {
  ACTIVITY_STREAM: 'activity_stream',
  RECIPIENTS: 'recipients',
};

const tabs = [
  { title: 'Activity Stream', id: tabTypes.ACTIVITY_STREAM },
  { title: 'Recipients', id: tabTypes.RECIPIENTS },
];

const WorkflowTabs = ({ workflowId }) => (
  <div style={{ padding: '0 20px 20px 20px' }}>
    <NavCard tabs={tabs}>
      {(active) => {
        if (active === tabTypes.ACTIVITY_STREAM) return <WorkflowActivityStream id={workflowId} />;
        if (
          active === tabTypes.RECIPIENTS
        ) return <WorkflowRecipientsTable workflowId={workflowId} />;
        return null;
      }}
    </NavCard>
  </div>
);

WorkflowTabs.propTypes = propTypes;

export default WorkflowTabs;
