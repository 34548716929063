import React, { useEffect } from 'react';
import { object, bool, func } from 'prop-types';
import { Modal, Space } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { isEmpty } from 'lodash';

import { closeOverlay, openOverlay } from 'App/services/overlayService';
import SidepanelFooter from 'App/components/SidepanelFooter';
import Loader from 'App/components/Loader';
import { hasAccess } from 'App/services/permissionsService';

import './PreviewWorkflow.scss';

const SIDEPANEL_ID = 'PREVIEW_WORKFLOW';

export const closePreviewWorkflow = () => closeOverlay(SIDEPANEL_ID);
export const openPreviewWorkflow = data => openOverlay(SIDEPANEL_ID, data);

const propTypes = {
  parameters: object,
  visible: bool,
  requestInProgress: bool.isRequired,
  fetchWorkflow: func.isRequired,
  workflow: object,
  resetWorkflowData: func.isRequired,
  history: object.isRequired,
};

const defaultProps = {
  workflow: null,
  parameters: null,
  visible: false,
};

const text = {
  SUBJECT: 'Subject:',
  SEND_FROM: 'Send from:',
  REPLY_TO: 'Reply to:',
  BUTTON_LABELS: {
    confirm: 'Edit message',
  },
};

const getIcon = (icon) => {
  const icons = {
    Email: 'Mail',
    SMS: 'SingleChat',
    WhatsApp: 'WhatsApp',
  };

  if (icons[icon]) return icons[icon];
  return 'Mail';
};

const customFooter = (history, id, edit, permission) => () => (permission ? (
  <SidepanelFooter
    execute={edit}
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
    style={{ justifyContent: 'flex-end', width: '100%' }}
  />
) : null);

const PreviewWorkflow = ({
  visible,
  parameters,
  requestInProgress,
  fetchWorkflow,
  workflow,
  resetWorkflowData,
  history,
}) => {
  const close = () => {
    const shouldResetWorkflow = !history.location.pathname.includes('/workflows-details');
    if (shouldResetWorkflow) resetWorkflowData();
    closePreviewWorkflow();
  };
  const title = parameters?.name ? parameters.name : '';
  const editMessage = () => history.push(`/marketing/workflows/${parameters.id}`);
  let canEdit = false;

  useEffect(() => {
    if (parameters?.id) {
      fetchWorkflow(parameters.id);
      canEdit = hasAccess('marketing_workflow', 'update') && parameters.status === 'Draft';
    }
  }, [parameters]);

  if (!parameters || (!workflow.message && !workflow.template)) return null;

  return (
    <Modal
      closable
      title={title}
      icon={getIcon(workflow.channel)}
      size="large"
      visible={visible}
      onCloseIconClick={close}
      footerRender={customFooter(history, parameters.id, editMessage, canEdit)}
    >
      <Loader visible={requestInProgress || isEmpty(workflow)} />
      {!isEmpty(workflow) && !requestInProgress && (
        <div className="fiq-preview-workflow">
          {workflow.message?.from_email && (
          <div className="fiq-preview-workflow__title">
            <div className="text-neutral-500">{text.SEND_FROM}</div>
            <Space size={8} />
            <div>{workflow.message.from_email}</div>
          </div>
          )}

          {workflow.message?.reply_to_email && (
          <div className="fiq-preview-workflow__title">
            <div className="text-neutral-500">{text.REPLY_TO}</div>
            <Space size={8} />
            <div>{workflow.message.reply_to_email}</div>
          </div>
          )}
          {workflow.message?.subject && (
            <div className="fiq-preview-workflow__title">
              <div className="text-neutral-500">{text.SUBJECT}</div>
              <Space size={8} />
              <div>{workflow.message.subject}</div>
            </div>
          )}
          <div className="fiq-preview-workflow__body">
            {ReactHtmlParser(workflow.template?.content?.[0]?.content)}
          </div>
        </div>
      )}
    </Modal>
  );
};

PreviewWorkflow.propTypes = propTypes;
PreviewWorkflow.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
  workflow: stores.workflows.workflow,
  fetchWorkflow: stores.workflows.fetchWorkflowData,
  requestInProgress: stores.workflows.requestInProgress.fetchWorkflow,
  resetWorkflowData: stores.workflows.resetWorkflow,
}))(observer(withRouter(PreviewWorkflow)));
