import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { object, bool, string, number, oneOfType, any } from 'prop-types';
import { Modal, Code, Space } from 'tc-biq-design-system';
import moment, { ISO_8601 } from 'moment';
import ReactHtmlParser from 'react-html-parser';

import removeGlobalStyles from 'App/services/utilities/removeGlobalStyles';
import { closeOverlay, openOverlay } from 'App/services/overlayService';
import Loader from 'App/components/Loader';
import NonFieldErrors from 'App/components/NonFieldErrors';
import { fetchWebhookLog } from 'Settings/Sections/Webhooks/services/webhooksService';
import { StatusCell } from 'App/components/gridCellRenderers';

import './PreviewLog.scss';

const SIDEPANEL_ID = 'PREVIEW_LOG';

export const openPreviewLog = (id, status) => openOverlay(SIDEPANEL_ID, { id, status });
export const closePreviewLog = () => closeOverlay(SIDEPANEL_ID);

const propTypes = {
  visible: bool,
  parameters: object,
};

const text = {
  TITLE: 'Webhook transaction',
  CLOSE: 'Close',
  EVENT: 'Event',
  PAYLOAD: 'Payload',
  REQUEST: 'Request',
  RESPONSE: 'Response',
  DELIVERY: 'Delivery',
  ATTEMPTS: 'Attempts',
  STATUS: 'Status',
  RESPONSE_STATUS: 'Response status',
};

const defaultProps = {
  visible: false,
  parameters: null,
};

const formatLogResponse = (response) => {
  try {
    const parsedResponse = JSON.parse(response);
    return <Code data={parsedResponse} />;
  } catch {
    return <div>{ReactHtmlParser(response, { transform: removeGlobalStyles })}</div>;
  }
};

const ModalBody = ({ logId }) => {
  const [logData, setLogData] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [errors, setErrors] = useState(null);
  const fetchLog = async () => {
    setLoadingData(true);
    try {
      const response = await fetchWebhookLog(logId);
      setLogData(response.data);
    } catch (err) {
      setErrors(err.data);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchLog();
  }, []);

  return (
    <div className="payload">
      <Loader visible={loadingData} />
      {!loadingData && !errors && logData.event && (
        <div className="fiq-preview-log">
          <div className="fiq-preview-log__info">
            <InfoDetail title={text.EVENT} content={logData.event.name} />
            <InfoDetail
              title={text.DELIVERY}
              content={moment(logData.delivery, ISO_8601).format('YYYY-MM-DD, \\at HH:mm:ss')}
            />
            <InfoDetail title={text.ATTEMPTS} content={logData.attempt} />
            <InfoDetail title={text.RESPONSE_STATUS} content={logData.response_status_code} />
          </div>
          <div className="fiq-preview-log__content">
            <div className="fiq-preview-log__request">
              <div className="tc-heading-s">{text.REQUEST}</div>
              <Space size={12} />
              <div className="fiq-preview-log__wrapper">
                <Code data={logData.request} />
              </div>
            </div>
            <div className="fiq-preview-log__separator" />

            <div className="fiq-preview-log__request">
              <div className="tc-heading-s">{text.RESPONSE}</div>
              <Space size={12} />
              <div className="fiq-preview-log__wrapper">{formatLogResponse(logData.response)}</div>
            </div>
          </div>
        </div>
      )}
      {errors && <NonFieldErrors errors={errors[0]} />}
    </div>
  );
};
ModalBody.propTypes = { logId: number.isRequired };

const InfoDetail = ({ title, content, children }) => (
  <div className="fiq-preview-log__detail">
    <div className="text-neutral-500">{title}</div>
    <Space size={4} />
    {content ? <div>{content}</div> : children}
  </div>
);
InfoDetail.propTypes = {
  title: string.isRequired,
  content: oneOfType([string, number]),
  children: any,
};
InfoDetail.defaultProps = { children: null, content: string };

const Title = ({ status }) => (
  <div className="fiq-preview-log__title">
    <div className="tc-heading-m truncate-text">{text.TITLE}</div>
    <Space size={24} />
    <StatusCell value={status} />
  </div>
);
Title.propTypes = { status: string.isRequired };

const PreviewLog = ({ visible, parameters }) => {
  if (!parameters || !parameters.id) return null;
  const { id, status } = parameters;
  return (
    <Modal
      icon="Code"
      closable
      hideFooter
      size="large"
      visible={visible}
      title={<Title status={status} />}
      onCloseIconClick={closePreviewLog}
    >
      <ModalBody logId={id} />
    </Modal>
  );
};

PreviewLog.propTypes = propTypes;
PreviewLog.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
}))(observer(PreviewLog));
