import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { object, func } from 'prop-types';
import { ComboxProvider } from 'tc-biq-design-system';
import { isEmpty } from 'lodash';

// Components
import WorkflowHeader from 'Marketing/components/WorkflowHeader';
import WorkflowChart from 'Marketing/components/WorkflowChart';
import WorkflowTabs from 'Marketing/components/WorkflowTabs';
import PreviewWorkflowDetails, {
  openWorkflowDetails,
} from 'Marketing/components/overlays/PreviewWorkflowDetails/PreviewWorkflowDetails';
import PreviewMessage, { openPreviewMessage } from 'Marketing/components/overlays/PreviewMessage';
import PreviewWorkflow, {
  openPreviewWorkflow,
} from 'Marketing/components/overlays/PreviewWorkflow';

// Services
import { fetchKPIs } from 'Marketing/services/WorkflowService';

const propTypes = {
  match: object.isRequired,
  workflow: object.isRequired,
  fetchWorkflow: func.isRequired,
  fetchEventMetadata: func.isRequired,
  fetchSendToQueryBuilderMetadata: func.isRequired,
  resetWorkflow: func.isRequired,
};

const text = {
  SENT: 'Sent',
  FAILED: 'Failed',
  OPENED: 'Opened',
  CLICKED: 'Clicked',
  CONVERTED: 'Converted',
  REPORTED_AS_SPAM: 'Reported as spam',
  UNSUBSCRIPTIONS: 'Unsubscriptions',
};

const emptyKpiData = [
  { text: text.SENT, percent: '-', value: '-' },
  { text: text.FAILED, percent: '-', value: '-' },
  { text: text.OPENED, percent: '-', value: '-' },
  { text: text.CLICKED, percent: '-', value: '-' },
  { text: text.CONVERTED, percent: '-', value: '' },
  { text: text.REPORTED_AS_SPAM, percent: '-', value: '-' },
  { text: text.UNSUBSCRIPTIONS, percent: '-', value: '-' },
];

const toPercentage = (all, part) => (part ? `${Math.round((part / all) * 100)}%` : '-');

const formatKpiData = (data) => {
  const { clicks, failed, opens, reported_as_spam, sent, unsubscribed } = data;

  const failedPercent = toPercentage(sent, failed);
  const openedPercent = toPercentage(sent, opens);
  const spamPercent = toPercentage(sent, reported_as_spam);
  const clickedPercent = toPercentage(sent, clicks);
  const unsubscriptionsPercent = toPercentage(sent, unsubscribed);

  const convertedValue = data.conversions
    .map(item => item.count)
    .reduce((prev, curr) => prev + curr, 0);
  const convertedPercent = toPercentage(sent, convertedValue);

  return [
    { text: text.SENT, percent: sent, value: sent },
    { text: text.FAILED, percent: failedPercent, value: failed },
    { text: text.OPENED, percent: openedPercent, value: opens },
    { text: text.CLICKED, percent: clickedPercent, value: clicks },
    { text: text.CONVERTED, percent: convertedPercent, value: convertedValue },
    { text: text.REPORTED_AS_SPAM, percent: spamPercent, value: reported_as_spam },
    { text: text.UNSUBSCRIPTIONS, percent: unsubscriptionsPercent, value: unsubscribed },
  ];
};

const WorkflowDetailsPage = ({
  workflow,
  match,
  fetchWorkflow,
  fetchSendToQueryBuilderMetadata,
  fetchEventMetadata,
  resetWorkflow,
}) => {
  const [kpiData, setKpiData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const { workflowId } = match.params;

  const openDetails = () => openWorkflowDetails(workflow);

  const fetchData = async () => {
    await fetchWorkflow(workflowId);
  };

  const fetchKpiData = async () => {
    const { data } = await fetchKPIs(workflowId);
    setKpiData(formatKpiData(data));
    setChartData(data.conversions);
  };

  useEffect(() => {
    if (workflow.goal_event_type) fetchEventMetadata(workflow.goal_event_type.id);
    if (workflow.send_to_query) fetchSendToQueryBuilderMetadata();
  }, [workflow]);

  useEffect(() => {
    if (!isEmpty(workflow) && workflow.status !== 'Draft') {
      fetchKpiData();
    } else {
      setKpiData(emptyKpiData);
      setChartData([]);
    }
  }, [workflow.status]);

  useEffect(() => {
    fetchData();
    return () => resetWorkflow();
  }, []);

  return (
    <ComboxProvider>
      <WorkflowHeader
        workflow={workflow}
        goBackIcon
        openDetails={openDetails}
        openPreviewMessage={openPreviewMessage}
        openPreviewWorkflow={openPreviewWorkflow}
        kpiData={kpiData}
      />
      <WorkflowChart data={chartData} />
      <WorkflowTabs workflowId={workflowId} />
      <PreviewWorkflowDetails />
      <PreviewMessage />
      <PreviewWorkflow />
    </ComboxProvider>
  );
};

WorkflowDetailsPage.propTypes = propTypes;

export default inject(stores => ({
  fetchWorkflow: stores.workflows.fetchWorkflowData,
  workflow: stores.workflows.workflow,
  fetchSendToQueryBuilderMetadata: stores.workflows.fetchSendToQueryBuilderMetadata,
  fetchEventMetadata: stores.workflows.fetchEventMetadata,
  resetWorkflow: stores.workflows.resetWorkflow,
}))(observer(withRouter(WorkflowDetailsPage)));
