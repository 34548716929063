import http from 'App/services/http';

const fetchWorkflowData = id => http.get(`/marketing/workflows/${id}/`);

const createWorkflow = payload => http.post('/marketing/workflows/', payload);

const updateWorkflow = (id, payload) => http.put(`/marketing/workflows/${id}/`, payload);

const cloneWorkflow = id => http.post(`/marketing/workflows/${id}/clone/`);

const fetchWorkflowsOptions = () => http.options('/marketing/workflows/');

const startWorkflow = id => http.post(`/marketing/workflows/${id}/start/`);

const endWorkflow = id => http.post(`/marketing/workflows/${id}/end/`);

const deleteWorkflow = id => http.delete(`/marketing/workflows/${id}/`);

const sendTestEmail = payload => http.post('/marketing/workflows/send-test-communication/', payload);

const fetchKPIs = id => http.get(`/marketing/workflows/${id}/stats/`);

const fetchWorkflowRecipients = id => http.get(`/marketing/workflows/${id}/memberships/`);

const fetchWorkflowsMetric = query => http.post('/marketing/workflows/metric/', query);

const addWorkflowBlock = (workflowId, payload) => http.post(`/marketing/workflows/${workflowId}/graph/`, payload);

const editWorkflowBlock = (workflowId, blockID, payload) => http.put(`/marketing/workflows/${workflowId}/graph/${blockID}/`, payload);

const removeWorkflowBlock = (workflowId, blockID) => http.delete(`/marketing/workflows/${workflowId}/graph/${blockID}/`);

const moveWorkflowBlock = (workflowId, blockID, payload) => http.post(`/marketing/workflows/${workflowId}/graph/${blockID}/move/`, payload);

const getWorkflow = workflowId => http.get(`/marketing/workflows/${workflowId}/graph/`);

const fetchWorkflowPropsMetadata = () => http.get('/marketing/workflows/graph/props-metadata/');

const fetchJourneyStats = workflowId => http.get(`/marketing/workflows/${workflowId}/journey-stats/`);

const fetchJourney = id => http.get(`/marketing/journeys/${id}/`);

const fetchWorkflowsForManualEnrollment = () => http.get('/marketing/workflows/?&allow_manual_enrollment__exact=true');

const enrollContactToWorkflow = payload => http.post('/marketing/journeys/', payload);
const bulkEnrollContactsToWorkflow = payload => http.post('/marketing/journeys/bulk/', payload);

export {
  fetchWorkflowData,
  createWorkflow,
  updateWorkflow,
  cloneWorkflow,
  sendTestEmail,
  fetchWorkflowsOptions,
  startWorkflow,
  endWorkflow,
  deleteWorkflow,
  fetchKPIs,
  fetchWorkflowRecipients,
  fetchWorkflowsMetric,
  addWorkflowBlock,
  fetchWorkflowPropsMetadata,
  editWorkflowBlock,
  removeWorkflowBlock,
  moveWorkflowBlock,
  getWorkflow,
  fetchJourneyStats,
  fetchJourney,
  fetchWorkflowsForManualEnrollment,
  enrollContactToWorkflow,
  bulkEnrollContactsToWorkflow,
};
