import React, { Fragment, useEffect, useState } from 'react';
import { number, object, bool, func, array } from 'prop-types';
import { Select, Sidepanel } from 'tc-biq-design-system';
import SidepanelFooter from 'App/components/SidepanelFooter';
import { inject, observer } from 'mobx-react';

const propTypes = {
  contactId: number.isRequired,
  sidepanelManager: object.isRequired,
  requestInProgress: bool.isRequired,
  errors: object.isRequired,
  fetchContactData: func.isRequired,
  workflowsForManualEnrollment: array.isRequired,
  fetchWorkflowsForManualEnrollment: func.isRequired,
  enrollContactToWorkflow: func.isRequired,
};

const text = {
  PROMPT: 'Are you sure you want to enroll this contact to the selected workflow?',
  PLACEHOLDER: 'Workflow',
};

const RenderFooter = ({ confirm, cancel, requestInProgress }) => (
  <SidepanelFooter
    submitInProgress={requestInProgress}
    execute={confirm}
    close={cancel}
    cancelColor="ghost"
    confirmColor="primary"
  />
);

const ManualEnrollToWorkflow = ({
  contactId,
  sidepanelManager,
  requestInProgress,
  errors,
  workflowsForManualEnrollment,
  fetchContactData,
  fetchWorkflowsForManualEnrollment,
  enrollContactToWorkflow,
  ...options
}) => {
  const [workflow, setWorkflow] = useState(null);
  const submit = async () => {
    if (!workflow) return;

    await enrollContactToWorkflow(contactId, workflow.value);

    if (!errors) {
      fetchContactData(contactId);
      sidepanelManager.close();
    }
  };

  const cancel = () => {
    setWorkflow(null);
    sidepanelManager.close();
  };

  useEffect(async () => {
    fetchWorkflowsForManualEnrollment();
  }, []);

  return (
    <Sidepanel
      {...options}
      footerRender={() => (
        <RenderFooter
          confirm={submit}
          cancel={cancel}
          requestInProgress={requestInProgress}
        />
      )}
    >
      <Fragment>
        <Select
          type="search"
          labelKey="display_name"
          valueKey="value"
          label={text.PLACEHOLDER}
          options={workflowsForManualEnrollment}
          placeholder={text.PLACEHOLDER}
          value={workflow}
          onChange={value => setWorkflow(value)}
        />
      </Fragment>
    </Sidepanel>
  );
};

ManualEnrollToWorkflow.propTypes = propTypes;

export default inject(stores => ({
  contactId: stores.contact.profile.contactData.id,
  errors: stores.workflows.errors.enrollContactToWorkflow,
  requestInProgress: stores.workflows.requestInProgress.enrollContactToWorkflow,
  workflowsForManualEnrollment: stores.workflows.workflowsForManualEnrollment,
  fetchContactData: stores.contact.profile.fetchContactData,
  fetchWorkflowsForManualEnrollment: stores.workflows.fetchWorkflowsForManualEnrollment,
  enrollContactToWorkflow: stores.workflows.enrollContactToWorkflow,
}))(observer(ManualEnrollToWorkflow));
