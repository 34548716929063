/* eslint-disable max-len */
import React from 'react';
import { ActivityDateGroup, ActivityEvent, Icon, MessageBox, notify, Pill } from 'tc-biq-design-system';
import { string, array, bool, func, object as objectType } from 'prop-types';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import { get } from 'lodash';

import iconConfig from 'App/enums/iconConfigEnum';
import imgConfig from 'App/enums/imgConfigEnum';
import { openComments } from 'App/components/CommonOverlays/Comments';
import { hasAccess } from 'App/services/permissionsService';
import { formatDate, formatTitle } from 'App/services/utilities/dateGroupUtils';
import { fetchEmailInbox } from 'Settings/Sections/Users/services/UsersService';

import SendEmail from 'Contacts/components/ContactActions/actions/SendEmail/SendEmail';
import removeGlobalStyles from 'App/services/utilities/removeGlobalStyles';
import { withRouter } from 'react-router-dom';

import './DateGroup.scss';

const propTypes = {
  groupTitle: string.isRequired,
  activities: array.isRequired,
  isFirst: bool.isRequired,
  checkHighlight: func.isRequired,
  initThread: func.isRequired,
  hideThread: func.isRequired,
  threads: objectType.isRequired,
  comboxManager: objectType.isRequired,
  loadMore: func.isRequired,
};

const text = {
  MESSAGE_BOX_ACTION_LABELS: {
    expand: 'Expand',
    collapse: 'Collapse',
  },
  SHOW_THREAD: 'Show thread',
  LOAD_MORE: 'Load more',
  REPLY_TO: 'Reply to',
  LABEL: 'Reply to email',
  MANUALLY_LOGGED: 'Manually logged',
  NOTIFICATION_TITLE: 'Unable to reply',
  NOTIFICATION_CONTENT: 'None of You email inboxes are in this thread!',
};

const messageBoxContent = {
  communications_calllog: true,
};

const getIconConfig = (object) => {
  if (object && iconConfig[object.object_type]) return iconConfig[object.object_type];
  return iconConfig.defaultIcon;
};

const getImgConfig = (object) => {
  if (object && object.metadata && object.metadata.provider && imgConfig[object.object_type]) {
    return imgConfig[object.object_type](object.metadata.provider);
  }
  return null;
};

function getUrlParams(search) {
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  return hashes.reduce((params, hash) => {
    const [key, val] = hash.split('=');
    return Object.assign(params, { [key]: decodeURIComponent(val) });
  }, {});
}

function isManuallyLogged(metadata) {
  if (metadata && metadata.is_manually_logged) {
    return () => <div className="fiq-activity-stream__manually-logged"><Pill type="status02">{text.MANUALLY_LOGGED}</Pill></div>;
  }
  return () => null;
}

const getMessageBox = (id, object, index, thread, threadCount, content) => (
  replyTo,
  initThread,
  loadMore,
) => {
  const metadata = get(object, 'metadata');
  const eventObjectType = get(object, 'object_type');
  if (eventObjectType && metadata && messageBoxContent[eventObjectType]) {
    if (!metadata.description) return null;
    return (
      <MessageBox
        data-recording-sensitive
        key={`${id}-message-box-${index}`}
        text={metadata.description}
      />
    );
  }
  if (object) {
    const showThread = !!(thread && thread.showThread);
    const threadArray = (thread && thread.activities) || [];
    const showInitThread = !!(index === 0 && !showThread && threadCount > 1);
    const next = thread && thread.next;
    const showLoadMore = !!(threadArray.length - 1 === index && next);
    const offset = next ? +getUrlParams(next).offset : 0;
    const showReplyTo = object.object_type === 'communications_emaillog';

    const checkInboxes = async () => {
      const response = await fetchEmailInbox();
      const availableUserEmails = response.data.results.map(inbox => inbox.email);
      if (availableUserEmails.some(email => content.includes(email))) {
        replyTo();
      } else {
        notify.pending({
          title: text.NOTIFICATION_TITLE,
          content: text.NOTIFICATION_CONTENT,
        });
      }
    };

    return (
      <div>
        {object.metadata?.content && (
        <MessageBox
          key={`${id}-message-box-${index}`}
          text={ReactHtmlParser(object.metadata.content, { transform: removeGlobalStyles })}
          actionLabels={text.MESSAGE_BOX_ACTION_LABELS}
        />
        )}
        <div
          className="fiq-activity-stream__message-box-footer"
          style={{
            justifyContent: !showInitThread && !showLoadMore ? 'flex-end' : 'space-between',
          }}
        >
          {showInitThread && (
            <div
              onClick={() => {
                initThread(id);
              }}
              className="fiq-activity-stream__load"
            >
              {`${text.SHOW_THREAD} (${threadCount})`}
            </div>
          )}
          {showLoadMore && (
            <div
              onClick={() => {
                loadMore(thread.id, null, next);
              }}
              className="fiq-activity-stream__load"
            >
              {`${text.LOAD_MORE} (${threadCount - offset})`}
            </div>
          )}
          {showReplyTo && (
            <div onClick={checkInboxes} className="fiq-activity-stream__reply-to">
              <Icon name="Reply" />
              <div>{text.REPLY_TO}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
  return <div key={`${id}-message-box`} />;
};

const DateGroup = withRouter(({
  history,
  hideThread,
  threads,
  initThread,
  groupTitle,
  activities,
  isFirst,
  checkHighlight,
  comboxManager,
  loadMore,
}) => {
  const readCommentsPermission = hasAccess('events_activitycomment', 'read');
  return (
    <div data-recording-sensitive className="fiq-activity-stream__date-group">
      <ActivityDateGroup text={groupTitle} isFirstNode={isFirst} />
      {!!activities.length
        && activities.map((activity, index) => {
          const metadata = get(activity, 'object.metadata');
          const thread = threads[activity.id];
          const threadArray = (thread && thread.activities) || [];
          const showThread = thread && thread.showThread;
          const threadCount = activity.thread_count;
          const threadActivities = threadArray.length ? threadArray : [activity, ...threadArray];
          const previous = thread && thread.previous;
          const showLoadMore = showThread && previous;
          const attachments = metadata && metadata.attachments;
          const formatedAttachments = (attachments || []).map(({ name, file }) => ({
            name: name.split('/').pop(),
            file,
          }));

          return (
            <div
              key={activity.id}
              className={classNames({ 'fiq-activity-stream__thread': showThread })}
            >
              {(showThread ? threadActivities : [activity]).map((a, i) => (
                <ActivityEvent
                  key={`${a.id}-leader`}
                  isFirstNode={isFirst && !index && !i}
                  date={formatDate(groupTitle, a.timestamp)}
                  title={formatTitle(a)}
                  hideTimeline={i > 0}
                  comments={a.comment_count}
                  iconConfig={getIconConfig(a.object)}
                  imgConfig={getImgConfig(a.object)}
                  highlight={checkHighlight(a.id)}
                  threadsCount={threadCount}
                  attachments={formatedAttachments}
                  manuallyLogged={isManuallyLogged(metadata)}
                  comboxManager={comboxManager}
                  tooltipTitle={a.event_type.name}
                  onIconClick={() => history.push(`/settings/events/${a.event_type.id}`)}
                  loadPreviousComponent={
                    showLoadMore && !i && `Load previous (${threadCount - threadArray.length})`
                  }
                  onLoadPrevious={() => {
                    loadMore(thread.id, previous, null);
                  }}
                  showThreadHeader={showThread && i === 0}
                  onCloseThread={() => {
                    if (thread) {
                      hideThread(thread.id);
                    }
                  }}
                  addComment={
                    readCommentsPermission
                      ? () => openComments({
                        activity: a,
                        groupTitle,
                      })
                      : null
                  }
                >
                  {getMessageBox(a.id, a.object, i, thread, threadCount, a.content)(
                    () => {
                      comboxManager.add(SendEmail, {
                        title: text.LABEL,
                        id: 'send-email-combox',
                        objectId: a.object.id,
                      });
                    },
                    initThread,
                    loadMore,
                  )}
                </ActivityEvent>
              ))}
            </div>
          );
        })}
    </div>
  );
});

DateGroup.propTypes = propTypes;

export default DateGroup;
