import actionTypes from 'Contacts/components/ContactActions/actions/types';
import { CONTACT_STATUS } from 'App/enums/contactStatus';
import { hasAccess } from 'App/services/permissionsService';
import Archive from './Archive';

const text = {
  LABEL: 'Archive',
};

const ACTION_ICON = 'Delete';

// TODO: Should be added permission 'user.archive.*'
export default {
  name: 'archive',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: actionTypes.DROPDOWN,
  hasPermission: status => ([
    CONTACT_STATUS.FULL,
    CONTACT_STATUS.PENDING,
    CONTACT_STATUS.LIMITED,
    CONTACT_STATUS.REVIEW,
    CONTACT_STATUS.ALERT,
    CONTACT_STATUS.EXCLUDED,
    CONTACT_STATUS.BLACKLIST,
    CONTACT_STATUS.UNSUITABLE,
    CONTACT_STATUS.UNSUITABLE_REFER,
    CONTACT_STATUS.UNSUITABLE_DEMO,
    CONTACT_STATUS.UNSUITABLE_REFER_DEMO,
    CONTACT_STATUS.REJECTED,
    CONTACT_STATUS.INCOMPLETE,
    CONTACT_STATUS.KYC_CHECK_IN_PROGRESS,
    CONTACT_STATUS.REVIEW,
    CONTACT_STATUS.PENDING_EMAIL_VERIFICATION,
    CONTACT_STATUS.PENDING_SMS_VERIFICATION,
    CONTACT_STATUS.LEAD,
    CONTACT_STATUS.DEMO,
  ].includes(status) && hasAccess('contacts_contact', 'update')),
  handler: (sidepanelManager) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      title: text.LABEL,
      type: 'info',
      sidepanelManager,
      visible: true,
    };

    sidepanelManager.add(Archive, sidepanelOptions);
  },
};
