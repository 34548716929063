import React from 'react';
import { StatusCell, ContactCell } from 'App/components/gridCellRenderers';
import hideCols from 'App/services/utilities/hideCols';
import NoDataDash from 'App/components/NoDataDash';

const text = {
  COMPLETED_STATUS: 'Completed',
  CANCELED_STATUS: 'Cancelled',
  STARTED: 'Started',
};

// eslint-disable-next-line react/prop-types
const renderNextStep = ({ data }) => {
  if (data.status === text.COMPLETED_STATUS) return <div>{text.COMPLETED_STATUS}</div>;
  if (data.status === text.CANCELED_STATUS) return <div>/</div>;
  return <div>{`${data.next_step?.name} - ${data.next_step?.type}`}</div>;
};

export default {
  ...hideCols(['id', 'workflow']),
  contact: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => value ? (
      <ContactCell
        id={value.id}
        avatar={value.avatar}
        fullName={`${value.first_name} ${value.last_name}`}
      />
    ) : (
      <NoDataDash />
    ),
  },
  status: {
    cellRendererFramework: StatusCell,
  },
  next_step: {
    cellRendererFramework: renderNextStep,
  },
  created: {
    headerName: text.STARTED,
  },
};
