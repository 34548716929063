import React from 'react';

import Page from 'App/components/Page';
import WorkflowForm from 'Marketing/components/WorkflowForm';

const text = {
  TITLE: 'Create Workflow',
};

const bread = [{ label: text.TITLE, route: '/marketing/workflows' }];

const WorkflowCreatePage = () => (
  <Page bread={bread} title={text.TITLE}>
    <WorkflowForm />
  </Page>
);

export default WorkflowCreatePage;
