import React, { useState, useEffect } from 'react';
import { find } from 'lodash';
import { Button, Select, Space } from 'tc-biq-design-system';
import { number, func, array, string } from 'prop-types';

import ConditionRule from 'App/components/QueryBuilderFactory/ConditionRuleFactory/ConditionRule';
import { fetchEventDefinitionData } from 'Settings/Sections/Events/services/EventsService';
import {
  formatEventLabel,
  formatEventNestedKeys,
  formatFields,
} from 'App/components/QueryBuilderFactory/queryBuilderStoreUtils';
import Loader from 'App/components/Loader/Loader';
import { handleErrorResponse } from 'App/services/utilities/error.utils';

const propTypes = {
  eventId: number.isRequired,
  updateEvent: func.isRequired,
  parentIdentifier: array.isRequired,
  condition: string,
  rules: array,
  initialId: number,
};

const defaultProps = {
  condition: 'ALL',
  rules: [],
  initialId: null,
};

const text = {
  REMOVE_ALL: 'Remove all',
  EVENT_DATA: 'Event data filter',
  MATCH: {
    prefix: 'Match',
    sufix: 'of the following conditions',
  },
  ADD_ATTRIBUTE: 'Add event data filter',
  FIELDS_ERROR: 'Failed to fetch data',
};

const matchOptions = [{ value: 'AND', label: 'all' }, { value: 'OR', label: 'one of' }];

const EventDataFilter = ({
  eventId,
  condition,
  rules,
  updateEvent,
  parentIdentifier,
  initialId,
}) => {
  const [eventAttributes, setEventAttributes] = useState([]);
  const [match, setMatchValue] = useState(find(matchOptions, { value: condition }));
  const [fieldsDef, setFieldsDef] = useState(null);
  const [loadingFieldDefs, setLoadingFieldDefs] = useState(false);

  const selectMatchCondition = (selectedMatch) => {
    setMatchValue(selectedMatch);
    updateEvent({ condition: selectedMatch.value }, parentIdentifier);
  };

  const updateAttribute = (data, identifier) => {
    const [attrIndex] = identifier;
    const attrCopy = [...eventAttributes];
    attrCopy[attrIndex] = { is_attribute: false, ...data };
    setEventAttributes(attrCopy);
    updateEvent({ rules: attrCopy }, parentIdentifier);
  };

  const addAttribute = () => {
    setEventAttributes([...eventAttributes, { is_attribute: false }]);
  };

  const removeAttribute = ([attrIndex]) => {
    const attrCopy = eventAttributes
      .map((attr, index) => (index === attrIndex ? null : attr))
      .filter(attr => attr);
    updateEvent({ rules: attrCopy }, parentIdentifier);
    setEventAttributes([]);
    setEventAttributes(attrCopy.filter(attr => attr));
  };

  const setEmptyAttributes = () => {
    setEventAttributes([]);
    updateEvent({ rules: [] }, parentIdentifier);
  };

  const fetchFieldsDef = async () => {
    setLoadingFieldDefs(true);
    try {
      const response = await fetchEventDefinitionData(eventId);
      const { payload, old_payload = {} } = response.data.message_properties;
      const fields = formatFields(
        formatEventNestedKeys({ old_payload, payload }),
        formatEventLabel,
      );
      setFieldsDef(fields);
    } catch (err) {
      handleErrorResponse(err, text.FIELDS_ERROR);
    } finally {
      setLoadingFieldDefs(false);
    }
  };

  useEffect(() => {
    if (eventId) {
      fetchFieldsDef();
    }
  }, [eventId]);

  useEffect(() => {
    if (initialId && initialId === eventId) {
      setEventAttributes(rules);
    } else {
      setEmptyAttributes();
      selectMatchCondition(matchOptions[0]);
    }
  }, [fieldsDef]);

  return (
    <div className="fiq-condition-event__event-filter">
      <div className="fiq-condition-event__event-header">
        <div className="text-neutral-500">{text.EVENT_DATA}</div>
        <Button color="transparent" onClick={setEmptyAttributes} icon="Delete" size="small">
          {text.REMOVE_ALL}
        </Button>
      </div>

      <div className="fiq-condition-event__event-body">
        <div className="fiq-condition-event__match">
          <div>{text.MATCH.prefix}</div>
          <Space size={8} />
          <div className="select">
            <Select
              clearable={false}
              options={matchOptions}
              value={match}
              onChange={selectMatchCondition}
            />
          </div>
          <Space size={8} />
          <div>{text.MATCH.sufix}</div>
        </div>
        <Loader visible={loadingFieldDefs} />
        {!loadingFieldDefs
          && eventAttributes
          && eventAttributes.map((eventRule, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className="fiq-condition-event__rule">
              {fieldsDef && (
                <ConditionRule
                  fields={fieldsDef}
                  removeCondition={removeAttribute}
                  updateConditionRule={updateAttribute}
                  parentIdentifier={[index]}
                  rule={eventRule}
                />
              )}
            </div>
          ))}
        <div className="fiq-condition-event__remove-attr">
          <Button
            color="transparent"
            size="small"
            icon="Plus"
            iconPosition="left"
            onClick={addAttribute}
          >
            {text.ADD_ATTRIBUTE}
          </Button>
        </div>
      </div>
    </div>
  );
};

EventDataFilter.propTypes = propTypes;
EventDataFilter.defaultProps = defaultProps;

export default EventDataFilter;
