import { action, observable, runInAction, makeObservable } from 'mobx';
import { notifier } from 'tc-biq-design-system';
import { convertToRaw } from 'draft-js';

import formatPayload from 'App/services/utilities/formatPayload';
import stores from 'App/rootStore';
import {
  fetchContactData,
  fetchContactInfoLayout,
  fetchContactOptions,
  editContactBasicInfo,
  sendEmail as sendEmailApi,
  sendSms,
  assignManager,
  sendWhatsApp,
  fetchEmailLog,
  logCall,
  fetchContactKpiData,
  fetchLeadImportOptions,
  bulkImportLead,
  addToExclusionList,
  archive,
  unarchive,
  fetchLoginAsContactToken,
  changePassword,
} from 'Contacts/services/contactsService';
import convertRawValueToHtml from 'App/services/utilities/draftJsUtils';
import * as omit from 'lodash/omit';
import { handleErrorResponse } from 'App/services/utilities/error.utils';

const text = {
  EDIT_DETAILS_SUCCESS: 'Edited details successfully',
  EDIT_DETAILS_FAILED: 'Failed to edit details',
  SEND_EMAIL_SUCCESS: 'Email sent successfully',
  SEND_EMAIL_FAILED: 'Failed to send an email!',
  SEND_SMS_SUCCESS: 'SMS sent successfully',
  SEND_SMS_FAILED: 'Failed to send SMS!',
  SEND_WHATS_APP_SUCCESS: 'WhatsApp message sent successfully',
  SEND_WHATS_APP_FAILED: 'Failed to send WhatsApp message!',
  ASSIGN_MANAGER_SUCCESS: 'Successfully assigned manager!',
  UNASSING_MANAGER_SUCCESS: 'Successfully unassigned manager!',
  LOAD_EMAIL_FAILED: 'Failed to load email data!',
  LOG_CALL_SUCCESS: 'Successfully logged call',
  LOG_CALL_FAILED: 'Failed to log call',
  BULK_IMPORT_LEAD_SUCCESS:
    'Your file is being processed - once completed you will be notified via e-mail',
  GENERAL_ERROR: 'Something went wrong',
  ADD_TO_EXCLUSION_LIST_SUCCESS: 'Contact successfully added to exclusion list.',
  ADD_TO_EXCLUSION_LIST_FAILED: 'Failed to add contact to exclusion list!',
  ARCHIVE_SUCCESS: 'Contact successfully archived!',
  ARCHIVE_FAILED: 'Failed to archive contact!',
  UNARCHIVE_SUCCESS: 'Contact successfully unarchived!',
  UNARCHIVE_FAILED: 'Failed to unarchive contact!',
  FETCH_LOGIN_AS_CONTACT_TOKEN_FAILED: 'Failed to fetch login as contact token!',
  CHANGE_PASSWORD_SUCCESS: 'Password successfully changed!',
  CHANGE_PASSWORD_FAILED: 'Failed to change password!',
};

export default class ContactStore {
  contactData = {};

  contactOptions = {};

  contactInfoLayout = {};

  contactKpiData = {};

  requestInProgress = {
    contactData: false,
    editBasicInfo: false,
    sendEmail: false,
    sendSms: false,
    sendWhatsApp: false,
    assignManager: false,
    getEmailLog: false,
    logCall: false,
    getFields: false,
    addToExclusionList: false,
    archive: false,
    unarchive: false,
    loginAsContactToken: false,
    changePassword: false,
  };

  errors = {
    contactData: null,
    editBasicInfo: null,
    sendEmail: null,
    sendSms: null,
    sendWhatsApp: null,
    assignManager: null,
    logCall: null,
    addToExclusionList: null,
    archive: null,
    unarchive: null,
    loginAsContactToken: null,
    changePassword: null,
  };

  constructor() {
    makeObservable(this, {
      contactData: observable,
      contactOptions: observable,
      contactInfoLayout: observable,
      contactKpiData: observable,
      requestInProgress: observable,
      errors: observable,
      fetchContactData: action.bound,
      editBasicInfo: action.bound,
      sendEmail: action.bound,
      sendSms: action.bound,
      sendWhatsApp: action.bound,
      logCall: action.bound,
      assignManagerToContact: action.bound,
      getEmailLog: action.bound,
      fetchLeadImportFields: action.bound,
      bulkImportLeads: action.bound,
      resetContactData: action.bound,
      setContactData: action.bound,
      addToExclusionList: action.bound,
      archive: action.bound,
      unarchive: action.bound,
      fetchLoginAsContactToken: action.bound,
      changePassword: action.bound,
    });
  }

  async fetchContactData(id) {
    this.requestInProgress.contactData = true;
    try {
      const [
        contactData,
        contactInfoLayout,
        contactOptions,
        contactKpiData,
      ] = await Promise.allSettled([
        fetchContactData(id),
        fetchContactInfoLayout(),
        fetchContactOptions(),
        fetchContactKpiData(id),
      ]).then(res => res.map(item => item?.value));

      const formattedLanguage = (contactOptions.data.actions.POST?.language?.choices || []).find(
        item => item?.value === contactData.data.language,
      ) || null;

      runInAction(() => {
        this.contactData = {
          ...contactData.data,
          language: formattedLanguage,
          landing_page: contactData.data.landing_page
          || contactData.data?.tracking_link?.landing_page,
        };
        this.contactInfoLayout = contactInfoLayout.data;
        this.contactOptions = contactOptions.data.actions.GET;
        this.contactKpiData = contactKpiData?.data;
        this.requestInProgress.contactData = false;
      });
    } catch (err) {
      runInAction(() => {
        this.errors.contactData = err;
        this.requestInProgress.contactData = false;
      });
      handleErrorResponse(err);
    }
  }

  async editBasicInfo() {
    this.requestInProgress.editBasicInfo = true;
    const { data, setFieldsErrors } = stores.forms.editBasicInfoForm;
    try {
      const { id } = this.contactData;
      const payload = formatPayload(data);
      const response = await editContactBasicInfo(id, formatBasicInfoPayload(payload));
      runInAction(() => {
        this.requestInProgress.editBasicInfo = false;
        this.contactData = response.data;
        this.errors.editBasicInfo = null;
        notifier.success(text.EDIT_DETAILS_SUCCESS);
      });
    } catch (err) {
      if (err?.response?.data) setFieldsErrors(err.response.data);
      runInAction(() => {
        this.requestInProgress.editBasicInfo = false;
        this.errors.editBasicInfo = err?.response?.data;
      });
      handleErrorResponse(err, text.EDIT_DETAILS_FAILED);
    }
  }

  async sendEmail(files, isReplyTo, contactId) {
    // TODO: Update this method when API is ready
    const { id } = this.contactData;
    const { data, setFieldsErrors } = stores.forms.sendEmailForm;
    this.requestInProgress.sendEmail = true;
    const rawBodyState = convertToRaw(data.body.getCurrentContent());
    const omitedData = omit(data, isReplyTo ? ['subject'] : []);

    try {
      await sendEmailApi(
        contactId || id,
        {
          ...omitedData,
          body: convertRawValueToHtml(rawBodyState),
          email_inbox: data.email_inbox.value,
          cc: (data.cc || []).map(({ email, display_name }) => email || display_name),
          bcc: (data.bcc || []).map(({ email, display_name }) => email || display_name),
        },
        files,
      );
      notifier.success(text.SEND_EMAIL_SUCCESS);
      runInAction(() => {
        this.requestInProgress.sendEmail = false;
        this.errors.sendEmail = null;
      });
    } catch (err) {
      if (err?.response?.data) {
        setFieldsErrors(err.response.data);
      }
      handleErrorResponse(err, text.SEND_EMAIL_FAILED);

      runInAction(() => {
        if (err.response && err.response.data) {
          this.errors.sendEmail = err.response.data;
        }
        this.requestInProgress.sendEmail = false;
      });
    }
  }

  async sendSms() {
    const { id } = this.contactData;
    const { data, setFieldsErrors } = stores.forms.sendSmsForm;
    this.requestInProgress.sendSms = true;
    try {
      await sendSms(id, data);
      notifier.success(text.SEND_SMS_SUCCESS);
      runInAction(() => {
        this.requestInProgress.sendSms = false;
        this.errors.sendSms = null;
      });
    } catch (err) {
      if (err?.response?.data) {
        setFieldsErrors(err.response.data);
      }
      handleErrorResponse(err, text.SEND_SMS_FAILED);
      runInAction(() => {
        this.errors.sendSms = err.response.data;
        this.requestInProgress.sendSms = false;
      });
    }
  }

  /**
   * BIQ Action - Add to exclusion list
   */
  async addToExclusionList() {
    const { external_id } = this.contactData;
    this.requestInProgress.addToExclusionList = true;
    try {
      await addToExclusionList(external_id);
      notifier.success(text.ADD_TO_EXCLUSION_LIST_SUCCESS);
      runInAction(() => {
        this.requestInProgress.addToExclusionList = false;
        this.errors.addToExclusionList = null;
      });
    } catch (err) {
      handleErrorResponse(err, text.ADD_TO_EXCLUSION_LIST_FAILED);
      runInAction(() => {
        this.errors.addToExclusionList = err.response.data;
        this.requestInProgress.addToExclusionList = false;
      });
    }
  }

  /**
   * BIQ Action - Archive
   */
  async archive() {
    const { external_id } = this.contactData;
    this.requestInProgress.archive = true;
    try {
      await archive(external_id);
      notifier.success(text.ARCHIVE_SUCCESS);
      runInAction(() => {
        this.requestInProgress.archive = false;
        this.errors.archive = null;
      });
    } catch (err) {
      handleErrorResponse(err, text.ARCHIVE_FAILED);
      runInAction(() => {
        this.errors.archive = err.response.data;
        this.requestInProgress.archive = false;
      });
    }
  }

  /**
   * BIQ Action - Unarchive
   */
  async unarchive() {
    const { external_id } = this.contactData;
    this.requestInProgress.unarchive = true;
    try {
      await unarchive(external_id);
      notifier.success(text.UNARCHIVE_SUCCESS);
      runInAction(() => {
        this.requestInProgress.unarchive = false;
        this.errors.unarchive = null;
      });
    } catch (err) {
      handleErrorResponse(err, text.UNARCHIVE_FAILED);
      runInAction(() => {
        this.errors.unarchive = err.response.data;
        this.requestInProgress.unarchive = false;
      });
    }
  }

  /**
   * BIQ Action - Fetch login as contact token
   */
  async fetchLoginAsContactToken() {
    const { external_id } = this.contactData;
    this.requestInProgress.loginAsContactToken = true;

    let res;

    try {
      runInAction(() => {
        this.errors.loginAsContactToken = null;
        this.requestInProgress.loginAsContactToken = false;
      });
      const response = await fetchLoginAsContactToken(external_id);
      res = response.data.token;
    } catch (err) {
      handleErrorResponse(err, text.FETCH_LOGIN_AS_CONTACT_TOKEN_FAILED);
      runInAction(() => {
        this.errors.loginAsContactToken = err.response.data;
        this.requestInProgress.loginAsContactToken = false;
      });
    }

    return res;
  }

  /**
   * BIQ Action - Change password
   */
  async changePassword() {
    this.requestInProgress.changePassword = true;
    const { data, setFieldsErrors } = stores.forms.changePasswordForm;
    try {
      const { external_id } = this.contactData;
      const payload = formatPayload(data);
      const response = await changePassword(external_id, formatBasicInfoPayload(payload));
      runInAction(() => {
        this.requestInProgress.changePassword = false;
        // this.contactData = response.data;
        this.errors.changePassword = null;
        notifier.success(text.CHANGE_PASSWORD_SUCCESS);
      });
    } catch (err) {
      if (err?.response?.data) setFieldsErrors(err.response.data);
      runInAction(() => {
        this.requestInProgress.changePassword = false;
        this.errors.changePassword = err?.response?.data;
      });
      handleErrorResponse(err, text.CHANGE_PASSWORD_FAILED);
    }
  }

  async sendWhatsApp() {
    const { id } = this.contactData;
    const { data, setFieldsErrors } = stores.forms.sendWhatsAppForm;
    this.requestInProgress.sendWhatsApp = true;
    try {
      await sendWhatsApp(id, data);
      notifier.success(text.SEND_WHATS_APP_SUCCESS);
      runInAction(() => {
        this.requestInProgress.sendWhatsApp = false;
        this.errors.sendWhatsApp = null;
      });
    } catch (err) {
      if (err?.response?.data) {
        setFieldsErrors(err.response.data);
      }
      handleErrorResponse(err, text.SEND_WHATS_APP_FAILED);
      runInAction(() => {
        this.errors.sendWhatsApp = err.response.data;
        this.requestInProgress.sendWhatsApp = false;
      });
    }
  }

  async logCall() {
    runInAction(() => {
      this.requestInProgress.logCall = true;
      this.errors.logCall = null;
    });
    const { data, setFieldsErrors } = stores.forms.logCall;
    try {
      await logCall(formatPayload(data));
      notifier.success(text.LOG_CALL_SUCCESS);
    } catch (err) {
      runInAction(() => {
        this.errors.logCall = err;
      });
      if (err?.response?.data) {
        setFieldsErrors(err.response.data);
      }
      handleErrorResponse(err, text.LOG_CALL_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.logCall = false;
      });
    }
  }

  async assignManagerToContact(managerId, managerKey) {
    const { id: contactId } = this.contactData;
    runInAction(() => {
      this.requestInProgress.assignManager = true;
    });
    try {
      const { data } = await assignManager(contactId, managerId, managerKey);
      runInAction(() => {
        this.contactData = data;
      });
      if (managerId) {
        notifier.success(text.ASSIGN_MANAGER_SUCCESS);
      } else {
        notifier.success(text.UNASSING_MANAGER_SUCCESS);
      }
    } catch (err) {
      this.errors.assignManager = err.response.data;
      handleErrorResponse(err);
    } finally {
      runInAction(() => {
        this.requestInProgress.assignManager = false;
      });
    }
  }

  async getEmailLog(id) {
    runInAction(() => {
      this.requestInProgress.getEmailLog = true;
    });
    let res;

    try {
      res = await fetchEmailLog(id);
    } catch (err) {
      handleErrorResponse(err, text.LOAD_EMAIL_FAILED);
      return {};
    } finally {
      runInAction(() => {
        this.requestInProgress.getEmailLog = false;
      });
    }

    return res.data;
  }

  async fetchLeadImportFields() {
    runInAction(() => {
      this.requestInProgress.getFields = true;
    });
    try {
      const response = await fetchLeadImportOptions();
      const fieldsOptions = response.data.actions.POST;
      const excludeKeys = [
        'date_of_birth',
        'email',
        'compliance_manager',
        'id',
        'retention_manager',
        'sales_manager',
        'aff_id',
        'cxd',
      ];
      runInAction(() => {
        this.userFields = fieldsOptions;
        this.optionalFields = Object.keys(fieldsOptions).filter(key => !excludeKeys.includes(key));
      });
    } catch (err) {
      handleErrorResponse(err, text.GENERAL_ERROR);
    } finally {
      runInAction(() => {
        this.requestInProgress.getFields = false;
      });
    }
  }

  async bulkImportLeads() {
    this.requestInProgress.upload = true;
    const { data, setFieldsErrors } = stores.forms.uploadDocForm;
    try {
      await bulkImportLead(data);
      runInAction(() => {
        this.requestInProgress.upload = false;
      });
      notifier.success(text.BULK_IMPORT_LEAD_SUCCESS);
      return true;
    } catch (err) {
      runInAction(() => {
        this.requestInProgress.upload = false;
      });
      if (err?.response?.data) setFieldsErrors(err.response.data);
      handleErrorResponse(err);
      return false;
    }
  }

  resetContactData() {
    this.contactData = {};
  }

  setContactData(contactData) {
    this.contactData = contactData;
  }
}

function formatBasicInfoPayload(payload) {
  const validatedPayload = {};
  Object.keys(payload)
    .forEach((key) => {
      if ((key === 'lead_score' || key === 'lifetime_value') && payload[key] === '') {
        validatedPayload[key] = null;
      } else {
        validatedPayload[key] = payload[key];
      }
    });
  return validatedPayload;
}
