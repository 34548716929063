import React, { useState } from 'react';
import { Icon, Space } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';

import { toJS } from 'mobx';
import './UpdateClientActionBlock.scss';
import AttributeCard from '../components';

const UpdateClientActionBlock = () => {
  const [attributesLocal, setAttributesLocal] = useState([
    { id: 1, attributeId: '', attributeValue: null },
  ]);

  const addAttributeLocal = () => {
    setAttributesLocal([...attributesLocal, { id: attributesLocal.length + 1, attributeId: '', attributeValue: null }]);
  };

  const updateAttributeIdLocal = (id, data) => {
    const attributeLocal = [...attributesLocal].find(attr => attr.id === id);
    attributeLocal.attributeId = data;

    const idx = attributesLocal.findIndex(attr => attr.id === id);
    attributesLocal[idx] = attributeLocal;
    setAttributesLocal([...attributesLocal]);
  };

  const updateAttributeValueLocal = (id, value) => {
    const attribute = [...attributesLocal].find(attr => attr.id === id);
    attribute.attributeValue = value;

    const idx = attributesLocal.findIndex(attr => attr.id === id);
    attributesLocal[idx] = attribute;
    setAttributesLocal(attributesLocal);
  };

  const removeAttributeLocal = (id) => {
    const attributeLocalFiltered = [...attributesLocal].filter(attr => attr.id !== id);
    setAttributesLocal(attributeLocalFiltered);
  };

  return (
    <div className="update-client-action-wrapper">
      <div className="update-client-action-wrapper__actions">
        {attributesLocal.map((attr, index) => (
          <AttributeCard
            key={index}
            attribute={attr}
            attributeIndex={index}
            updateAttributeId={updateAttributeIdLocal}
            updateAttributeValue={updateAttributeValueLocal}
            removeAttribute={removeAttributeLocal}
          />
        ))
        }
      </div>

      <div onClick={addAttributeLocal} className="update-client-action-wrapper__add-field">
        <Icon name="Plus" colorName="text-primary-500" />
        <Space size={8} />
        <span className="text-primary-500 tc-paragraph-strong">Add field</span>
      </div>
    </div>
  );
};

export default inject(stores => ({
  formData: stores.forms.editBlock.data,
  attributes: stores.workflows.attributes,
  attributeChoices: toJS(stores.workflows.attributeChoices),
  addAttribute: stores.workflows.addAttribute,
  updateAttributeId: stores.workflows.updateAttributeId,
  updateAttributeValue: stores.workflows.updateAttributeValue,
  removeAttribute: stores.workflows.removeAttribute,
}))(observer(UpdateClientActionBlock));
