import React, { useState, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { object, func } from 'prop-types';
import { observer, inject } from 'mobx-react';
import { isEmpty } from 'lodash';

import Page from 'App/components/Page';
import Stepper from 'App/components/Stepper';
import { states } from 'Marketing/utils/workflow';

import WorkflowBuilder from '../../components/WorkflowBuilder';
import WorkflowForm from '../../components/WorkflowForm';

export const stepKeys = {
  DETAILS: 'details',
  WORKFLOW: 'workflow',
  GOAL: 'goal',
};

const text = {
  TITLE: 'Workflows',
  SINGLE_TITLE: 'Workflow',
};

const bread = name => [{ label: text.TITLE, route: '/marketing/workflows' }, { label: name }];

const isDetailsDone = workflow => !!workflow?.send_to_type && !!workflow?.start_datetime;

const isWorkflowDone = (workflow, graph) => graph?.blocks && graph.blocks.length > 1;

const getSteps = () => [
  {
    key: stepKeys.DETAILS,
    title: 'Workflow  details',
    component: () => <WorkflowForm state={states.DETAILS} />,
    isDone: isDetailsDone,
    isDisabled: () => false,
  },
  {
    key: stepKeys.WORKFLOW,
    title: 'Workflow builder',
    component: isActive => <WorkflowBuilder isActive={isActive} />,
    isDone: isWorkflowDone,
    isDisabled: workflow => !isDetailsDone(workflow),
  },
  {
    key: stepKeys.GOAL,
    title: 'Set goal',
    component: () => <WorkflowForm state={states.GOAL} />,
    isDisabled: (workflow, graph) => !isWorkflowDone(workflow, graph),
    isDone: workflow => workflow?.goal_attribution_window && workflow?.goal_event_type,
  },
];

const propTypes = {
  match: object.isRequired,
  history: object.isRequired,
  workflow: object.isRequired,
  graph: object.isRequired,
  fetchWorkflow: func.isRequired,
};

const WorkflowsSinglePage = ({ match, history, workflow, graph, fetchWorkflow }) => {
  const { workflowId, step } = match.params;
  const [steps, setSteps] = useState([]);
  const [stepResults, setStepResults] = useState({ done: [], disabled: [] });
  const [activeStep, setActiveStep] = useState(stepKeys.DETAILS);

  const onStepChange = async (key) => {
    history.push(`/marketing/workflows/${workflowId}/setup/${key}`);
  };

  const validateWorkflow = () => {
    const result = steps.reduce(
      (acc, { isDone, isDisabled, key }) => ({
        disabled: isDisabled(workflow, graph) ? [...acc.disabled, key] : acc.disabled,
        done: isDone(workflow, graph) ? [...acc.done, key] : acc.done,
      }),
      { done: [], disabled: [] },
    );
    setStepResults(result);
  };

  useEffect(() => {
    fetchWorkflow(workflowId);
  }, [workflowId]);

  useEffect(() => {
    setSteps(getSteps());
  }, [workflow]);

  useEffect(() => {
    if (step) setActiveStep(step);
  }, [step]);

  useEffect(() => {
    if (workflow?.id) validateWorkflow();
  }, [workflow, steps, graph]);

  if (!isEmpty(workflow) && workflow.status !== 'Draft') {
    return <Redirect to="/no-permission" />;
  }

  return (
    <Page bread={bread(workflow?.name)} title={`${text.SINGLE_TITLE} ${workflow?.name}`}>
      <Stepper
        steps={steps}
        onChange={onStepChange}
        activeStep={activeStep}
        disabledSteps={stepResults.disabled}
        doneSteps={stepResults.done}
      />
    </Page>
  );
};

WorkflowsSinglePage.propTypes = propTypes;

export default inject(stores => ({
  updateWorkflow: stores.workflows.updateWorkflow,
  workflow: stores.workflows.workflow,
  graph: stores.workflows.graph,
  fetchWorkflow: stores.workflows.fetchWorkflowData,
}))(observer(withRouter(WorkflowsSinglePage)));
