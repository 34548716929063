import actionTypes from 'Contacts/components/ContactActions/actions/types';
import ChangePassword from './ChangePassword';
import { CONTACT_STATUS } from 'App/enums/contactStatus';
import { hasAccess } from 'App/services/permissionsService';

const text = {
  LABEL: `Change password`,
  TITLE: contact => `Change password for ${contact.first_name} ${contact.last_name}`,
};

const ACTION_ICON = 'User';

export default {
  name: 'changePassword',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: actionTypes.DROPDOWN,
  hasPermission: status => ([
    CONTACT_STATUS.FULL,
    CONTACT_STATUS.PENDING,
    CONTACT_STATUS.LIMITED,
    CONTACT_STATUS.REVIEW,
    CONTACT_STATUS.DEMO,
  ].includes(status) && hasAccess('contacts_contact', 'update')),
  handler: (sidepanelManager, contact) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      title: text.TITLE(contact),
      sidepanelManager,
      visible: true,
    };

    sidepanelManager.add(ChangePassword, sidepanelOptions);
  },
};
