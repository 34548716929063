import React, { useState, useEffect } from 'react';
import { Button, Sidepanel, InfoBox, Space } from 'tc-biq-design-system';
import { isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import { func, array, object, bool, string } from 'prop-types';
import { closeOverlay, openOverlay } from 'App/services/overlayService';
import Field from 'App/components/FieldRenderer';
import EditActionBlock from 'Marketing/components/Workflow/overlays/EditActionBlock';
import classnames from 'classnames';

import {
  queryFlattener,
} from 'App/components/QueryBuilderFactory/queryBuilderStoreUtils';
import QueryBuilderFactory from 'App/components/QueryBuilderFactory';
import formatQueryRulesArrayValues from 'App/services/utilities/formatQueryRulesArrayValues';
import formatPayload from 'App/services/utilities/formatPayload';
import { BLOCK_TYPE, blockTypeIconMap, blockDescriptionMap } from '../../libs/consts';

import './EditBlock.scss';

const propTypes = {
  actions: array,
  onBlockEdited: func,
  hasErrors: bool,
  visible: bool,
  submitInProgress: bool,
  actionChoices: array,
  allFields: object,
  parameters: object,
  handleAddBlock: func,
  actionFieldError: string,
  resetQueries: func.isRequired,
  setQueries: func.isRequired,
  setFieldsMetadata: func.isRequired,
  queries: object.isRequired,
  formData: object.isRequired,
  setFieldsErrors: func.isRequired,
  resetFieldsData: func.isRequired,
  updateFieldValue: func.isRequired,
  onClose: func,
};

const OR_CONDITION = 'OR';

const SIDEPANEL_ID = 'EDIT_BLOCK';

const defaultProps = {
  actions: [],
  onBlockEdited: () => null,
  hasErrors: false,
  visible: false,
  submitInProgress: false,
  actionChoices: [],
  allFields: {},
  parameters: {},
  handleAddBlock: null,
  actionFieldError: '',
  onClose: () => null,
};

const text = {
  DISCARD: 'Discard',
  SAVE: 'Save',
  SIDEPANEL_EDIT_TITLE: 'Edit block data',
  SIDEPANEL_PREVIEW_TITLE: 'Preview block data',
  SIDEPANEL_CREATE_TITLE: 'Create new block',
  PLACEHOLDER: 'Select action...',
};

const { QueryBuilder } = QueryBuilderFactory({
  storeKey: 'workflows',
  storeFieldKey: 'workflowQueryBuilder',
});

const getDefaultQuery = type => ({
  condition: type === BLOCK_TYPE.WAIT_UNTIL ? 'OR' : 'AND',
  rules: [
    {
      field: 'segments.id',
      operator: 'exact',
      type: 'multi-related field',
      value: '',
    },
  ],
});

export const openEditBlockSidepanel = (block, insertInfo, onAddNode, isOverview) => {
  openOverlay(SIDEPANEL_ID, {
    block,
    insertInfo,
    onAddNode,
    isOverview,
  });
};

export const closeEditBlockSidepanel = () => {
  closeOverlay(SIDEPANEL_ID);
};

// eslint-disable-next-line react/prop-types
const customFooter = ({
  hasErrors,
  execute,
  submitInProgress,
  close,
  isOverview,
}) => (
  <div className="fiq-edit-node__footer">
    <Button color="ghost" onClick={close}>
      {text.DISCARD}
    </Button>
    <Button disabled={hasErrors || isOverview} loading={submitInProgress} onClick={execute}>
      {text.SAVE}
    </Button>
  </div>
);

const getTitle = (isOverview, isNewBlockMode) => {
  if (isOverview) return text.SIDEPANEL_PREVIEW_TITLE;
  return isNewBlockMode ? text.SIDEPANEL_CREATE_TITLE : text.SIDEPANEL_EDIT_TITLE;
};

const getBlockSize = type => ({
  [BLOCK_TYPE.WAIT_UNTIL]: 'large',
  [BLOCK_TYPE.BRANCH]: 'large',
  [BLOCK_TYPE.EMAIL_BRANCH]: 'large',
})[type] || 'medium';

const emailTemplateOptions = [
  { value: 'template1', display_name: 'Template 1' },
  { value: 'template2', display_name: 'Template 2' },
  { value: 'template3', display_name: 'Template 3' },
  { value: 'template4', display_name: 'Template 4' },
];

const EditBlock = ({
  hasErrors,
  onBlockEdited,
  formData,
  setFieldsErrors,
  resetFieldsData,
  updateFieldValue,
  queries,
  setFieldsMetadata,
  resetQueries,
  setQueries,
  visible,
  submitInProgress,
  actionChoices,
  allFields,
  actions,
  parameters,
  handleAddBlock,
  actionFieldError,
  onClose,
}) => {
  const [queryField, setQueryField] = useState(null);
  const [hasFieldsPopulated, setFieldsPopulated] = useState(false);
  const [fields, setFields] = useState([]);

  const {
    block,
    insertInfo,
    isOverview,
  } = parameters;

  const isNewBlockMode = !!insertInfo;
  const isActionBlock = block?.type === BLOCK_TYPE.ACTION;
  const isWaitUntilBlock = block?.type === BLOCK_TYPE.WAIT_UNTIL;
  const sidepanelTitle = getTitle(isOverview, isNewBlockMode);

  useEffect(() => {
    if (!isActionBlock) setFields(allFields[block?.type] || []);
    return () => resetData();
  }, [block]);

  useEffect(() => {
    if (!fields?.length) return;
    updateFieldValue('name', block.name);
    fields.forEach(({ name }) => {
      updateFieldValue(name, block.properties?.[name]);
    });

    setFieldsPopulated(true);
  }, [fields]);

  useEffect(() => {
    const field = fields.find(({ type }) => type === 'query');
    if (field) {
      setFieldsMetadata(queryFlattener(field.properties));
      setQueryField(field);
    }
  }, [fields]);

  useEffect(() => {
    if (queryField) {
      const query = block?.properties?.[queryField.name];
      if (query) {
        setQueries(query);
      } else if (isWaitUntilBlock) {
        setQueries(getDefaultQuery(block.type));
      }
    }
  }, [queryField]);

  const resetData = () => {
    setQueryField(null);
    setFields([]);
    setFieldsPopulated(false);
    resetQueries();
    resetFieldsData();
  };

  const onSave = () => {
    const formattedQuery = formatQueryRulesArrayValues(queries);
    const formattedFormData = formatPayload(formData);
    const formattedData = queryField
      ? {
        ...formattedFormData,
        [queryField.name]: formattedQuery,
      }
      : formattedFormData;

    if (isNewBlockMode) {
      handleAddBlock({
        ...formattedData,
        type: block.type,
      }, setFieldsErrors);
      return;
    }
    onBlockEdited(formattedData, setFieldsErrors);
  };

  const onDiscard = () => {
    onBlockEdited(null, setFieldsErrors);
    closeEditBlockSidepanel();
    onClose();
  };

  if (block?.type === BLOCK_TYPE.ACTION) {
    return (
      <EditActionBlock
        actions={actions}
        block={block}
        onDiscard={onDiscard}
        onSave={onSave}
        submitInProgress={submitInProgress}
        actionChoices={actionChoices}
        formData={formData}
        hasErrors={hasErrors}
        visible={visible}
        resetFieldsData={resetFieldsData}
        updateFieldValue={updateFieldValue}
        actionFieldError={actionFieldError}
        title={sidepanelTitle}
        isOverview={isOverview}
      />
    );
  }

  return (
    <Sidepanel
      visible={visible}
      title={sidepanelTitle}
      size={getBlockSize(block?.type)}
      className="fiq-edit-block-sidepanel"
      onCloseIconClick={onDiscard}
      avatar={() => <img src={blockTypeIconMap[block?.type]} alt="email-block" />}
      footerRender={() => customFooter({
        execute: onSave,
        close: onDiscard,
        submitInProgress,
        hasErrors,
        isOverview,
      })
      }
    >
      <div className={classnames('fiq-edit-node')}>
        {block && <InfoBox header={block.type}>{blockDescriptionMap[block.type]}</InfoBox>}
        <Space size={32} />
        <div className="fiq-edit-node__content">
          <Field
            label="Name"
            id="name"
            name="name"
            required
            disabled={isOverview}
            formId="editBlock"
          />

          {/* Email template control */}
          { block && block.name === 'Email Condition Block' && (
            <Field
              disabled={false}
              label="Email template"
              type="choice"
              key="template"
              formId="editBlock"
              valueKey="value"
              labelKey="display_name"
              options={emailTemplateOptions}
            />
          ) }

          {hasFieldsPopulated
            && fields.map(({
              id,
              type,
              label,
              read_only,
              ...props
            }) => {
              if (type === 'query') {
                return queryField ? (
                  <div key={id}>
                    <span>{label}</span>
                    <QueryBuilder
                      defaultConditionValue={isWaitUntilBlock ? OR_CONDITION : null}
                      liteConditionHeader
                    />
                  </div>
                ) : null;
              }
              return (
                <Field
                  {...props}
                  disabled={read_only || isOverview}
                  label={label}
                  type={type}
                  key={id}
                  formId="editBlock"
                />
              );
            })}
        </div>
      </div>
    </Sidepanel>
  );
};

EditBlock.propTypes = propTypes;
EditBlock.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
  queries: stores.workflows.workflowQueryBuilder.queries,
  setFieldsMetadata: stores.workflows.workflowQueryBuilder.setFieldsMetadata,
  resetQueries: stores.workflows.workflowQueryBuilder.resetQueries,
  setQueries: stores.workflows.workflowQueryBuilder.setQueries,
  setFieldsErrors: stores.forms.editBlock.setFieldsErrors,
  resetFieldsData: stores.forms.editBlock.resetFieldsData,
  updateFieldValue: stores.forms.editBlock.updateFieldValue,
  formData: stores.forms.editBlock.data,
  hasErrors: !isEmpty(stores.forms.editBlock.fieldErrors),
  actionFieldError: stores.forms.editBlock.fieldErrors.action_id,
}))(observer(EditBlock));
