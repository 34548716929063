import React from 'react';
import { inject, observer } from 'mobx-react';
import { array, bool, object, func } from 'prop-types';

import If from 'App/components/If';
import Loader from 'App/components/Loader';
import EndpointAccordion from './EndpointAccordion';

const propTypes = {
  filteredData: array.isRequired,
  requestInProgress: bool.isRequired,
  accordions: object.isRequired,
  updateFieldPermission: func.isRequired,
  updateEndpointPermission: func.isRequired,
  updateExtraOperationPermission: func.isRequired,
};

const PermissionsSection = ({
  filteredData,
  requestInProgress,
  accordions,
  updateFieldPermission,
  updateEndpointPermission,
  updateExtraOperationPermission,
}) => (
  <div className="fiq-roles-single__permissions">
    <Loader visible={requestInProgress} />
    <If condition={!requestInProgress}>
      {filteredData.map(endpoint => (
        <EndpointAccordion
          endpoint={endpoint}
          key={endpoint.name}
          isOpen={accordions.visible}
          updateFieldPermission={updateFieldPermission}
          updateEndpointPermission={updateEndpointPermission}
          updateExtraOperationPermission={updateExtraOperationPermission}
        />
      ))}
    </If>
  </div>
);

PermissionsSection.propTypes = propTypes;

export default inject(stores => ({
  filteredData: stores.roles.filteredData,
  requestInProgress: stores.roles.requestInProgress.fetchData,
  accordions: stores.roles.fields.accordions,
  updateFieldPermission: stores.roles.updateFieldPermission,
  updateEndpointPermission: stores.roles.updateEndpointPermission,
  updateExtraOperationPermission: stores.roles.updateExtraOperationPermission,
}))(observer(PermissionsSection));
