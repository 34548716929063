import { observable, action, runInAction, computed, makeObservable } from 'mobx';
import { notifier } from 'tc-biq-design-system';
import { isEmpty, omit, pick } from 'lodash';
import moment from 'moment';
import run from 'App/services/utilities/run';

import channelEnum from 'App/enums/channelEnum';
import {
  fetchWorkflowData,
  createWorkflow,
  updateWorkflow,
  fetchWorkflowsOptions,
  sendTestEmail,
  startWorkflow,
  fetchJourneyStats,
  fetchWorkflowsForManualEnrollment,
  enrollContactToWorkflow,
  bulkEnrollContactsToWorkflow,
} from 'Marketing/services/WorkflowService';
import stores from 'App/rootStore';
import { fetchEventDefinitionData } from 'Settings/Sections/Events/services/EventsService';
import {
  formatEventLabel,
  formatEventNestedKeys, queryFlattener,
} from 'App/components/QueryBuilderFactory/queryBuilderStoreUtils';
import formatPayload from 'App/services/utilities/formatPayload';
import formatQueryRulesArrayValues from 'App/services/utilities/formatQueryRulesArrayValues';
import fetchContactQueryBuilderMetadata
  from 'App/services/utilities/fetchContactQueryBuilderMetadata';
import { handleErrorResponse } from 'App/services/utilities/error.utils';

const text = {
  FETCH_WORKFLOW_FAILED: 'Failed to fetch workflow',
  UPDATE_SUCCESS: 'Successfully updated workflow',
  UPDATE_FAILED: 'Failed to update workflow',
  CREATE_SUCCESS: 'Successfully created workflow',
  CREATE_DRAFT_SUCCESS: 'Workflow created as draft',
  CREATE_FAILED: 'Failed to create workflow',
  OPTIONS_FAILED: 'Failed to get fields data',
  TEST_MESSAGE_SUCCESS: 'Test message sent. Please check your inbox',
  TEST_MESSAGE_FAILED: 'Failed to send test email',
  START_SUCCESS: 'Successfully started workflow',
  START_FAILED: 'Failed to start workflow',
  EMAIL_CONTENT_REQUIRED: 'It\'s required to design the template, in order to create workflow',
  ENROLL_CONTACT_SUCCESS: 'Successfully enrolled contact to the workflow',
  ENROLL_CONTACT_FAILED: 'Failed to enroll contact to the workflow!',
  BULK_ENROLL_CONTACT_SUCCESS: 'Successfully enrolled contacts to the workflow',
  BULK_ENROLL_CONTACT_FAILED: 'Failed to enroll contacts to the workflow!',
};

export const SEND_TO_TRIGGER_TYPES = ['Contacts who enter', 'Contacts who exit'];

export default class WorkflowsStore {
  constructor(sendToQueryBuilderStore, goalQueryBuilderStore, workflowQueryBuilderStore) {
    makeObservable(this, {
      workflow: observable,
      journeyStats: observable,
      fieldsDef: observable,
      graph: observable,
      requestInProgress: observable,
      errors: observable,
      isRecurringEnabled: observable,
      workflowsForManualEnrollment: observable,
      isEndingWorkflow: observable,
      fetchWorkflowData: action.bound,
      updateWorkflow: action.bound,
      createWorkflow: action.bound,
      startWorkflow: action.bound,
      fetchWorkflowsOptions: action.bound,
      fetchSendToQueryBuilderMetadata: action.bound,
      fetchEventMetadata: action.bound,
      fetchJourneyStats: action.bound,
      fetchWorkflowsForManualEnrollment: action.bound,
      enrollContactToWorkflow: action.bound,
      bulkEnrollContactToWorkflow: action.bound,
      sendTestEmail: action.bound,
      toggleRecurringWorkflow: action.bound,
      toggleEndingWorkflow: action.bound,
      setWorkflowBuilder: action.bound,
      resetForm: action.bound,
      resetWorkflow: action.bound,
      updateCreateInProgress: computed,
      hasErrorsForStartWorkflow: computed,
    });

    this.sendToQueryBuilder = sendToQueryBuilderStore;
    this.goalQueryBuilder = goalQueryBuilderStore;
    this.workflowQueryBuilder = workflowQueryBuilderStore;
  }

  workflow = {};

  journeyStats = {};

  fieldsDef = {};

  graph = {};

  workflowsForManualEnrollment = [];

  requestInProgress = {
    fetchWorkflow: false,
    updateWorkflow: false,
    createWorkflow: false,
    deleteWorkflow: false,
    startWorkflow: false,
    eventMetadata: false,
    contactPayloadMetadata: false,
    fetchOptions: false,
    sendTestEmail: false,
    fetchJourneyStats: false,
    enrollContactToWorkflow: false,
    bulkEnrollContactToWorkflow: false,
  };

  errors = {};

  isRecurringEnabled = false;

  isEndingWorkflow = false;

  async fetchWorkflowData(id) {
    this.requestInProgress.fetchWorkflow = true;
    const { setFieldsData } = stores.forms.workflowForm;
    try {
      const response = await fetchWorkflowData(id);
      runInAction(() => {
        this.workflow = response.data;
        this.graph = response.data.graph;
      });
      setFieldsData(formatWorkflowFormData(this.workflow));
      if (this.workflow.recurring_type) {
        this.toggleRecurringWorkflow(true);
      }
      if (
        this.workflow.end_datetime
        && hasEnding(this.workflow.send_to_type, this.isRecurringEnabled)
      ) {
        this.toggleEndingWorkflow(true);
      }
    } catch (err) {
      handleErrorResponse(err, text.FETCH_WORKFLOW_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.fetchWorkflow = false;
      });
    }
  }

  async updateWorkflow(id, showSuccessMessage) {
    this.requestInProgress.updateWorkflow = true;
    const payload = formatWorkflowPayload(
      this.sendToQueryBuilder.queries,
      this.goalQueryBuilder.queries,
      this.isRecurringEnabled,
      this.isEndingWorkflow,
    );
    try {
      const { data: workflow } = await updateWorkflow(id, payload);
      if (showSuccessMessage) {
        notifier.success(text.UPDATE_SUCCESS);
      }
      runInAction(() => {
        this.workflow = {
          ...this.workflow,
          ...workflow,
        };
      });
      return true;
    } catch (err) {
      if (err?.response?.data) {
        setErrors(err.response.data);
      }
      handleErrorResponse(err);
      return false;
    } finally {
      runInAction(() => {
        this.requestInProgress.updateWorkflow = false;
      });
    }
  }

  async createWorkflow({ history }) {
    this.requestInProgress.createWorkflow = true;
    const payload = formatWorkflowPayload(
      this.sendToQueryBuilder.queries,
      this.goalQueryBuilder.queries,
      this.isRecurringEnabled,
      this.isEndingWorkflow,
    );
    try {
      const response = await createWorkflow(payload);
      notifier.success(text.CREATE_SUCCESS);
      history.push(`/marketing/workflows/${response.data.id}/setup`);
    } catch (err) {
      if (err?.response?.data) {
        setErrors(err.response.data);
      }
      handleErrorResponse(err, text.CREATE_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.createWorkflow = false;
      });
    }
  }

  async startWorkflow({
    history,
    workflowId,
  }) {
    this.requestInProgress.startWorkflow = true;
    try {
      await startWorkflow(workflowId);
      notifier.success(text.START_SUCCESS);
      history.push('/marketing/workflows');
    } catch (err) {
      if (err?.response?.data) {
        setErrors(err.response.data);
      }
      handleErrorResponse(err, text.START_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.startWorkflow = false;
      });
    }
  }

  async fetchWorkflowsOptions() {
    this.requestInProgress.fetchOptions = true;
    try {
      const response = await fetchWorkflowsOptions();
      runInAction(() => {
        this.fieldsDef = response.data.actions.POST;
      });
    } catch (err) {
      handleErrorResponse(err, text.OPTIONS_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.fetchOptions = false;
      });
    }
  }

  async fetchSendToQueryBuilderMetadata() {
    this.requestInProgress.contactPayloadMetadata = true;
    try {
      const {
        fields,
        fieldsMetadata,
      } = await fetchContactQueryBuilderMetadata();
      runInAction(() => {
        this.sendToQueryBuilder.setFields(queryFlattener(fields));
        this.sendToQueryBuilder.setFieldsMetadata(queryFlattener(fieldsMetadata));
      });
    } catch (err) {
      runInAction(() => {
        this.errors.contactPayloadMetadata = err.data;
      });
      handleErrorResponse(err);
    } finally {
      const { send_to_query } = this.workflow;
      if (send_to_query) {
        this.sendToQueryBuilder.setQueries(send_to_query);
      }
      runInAction(() => {
        this.requestInProgress.contactPayloadMetadata = false;
      });
    }
  }

  async fetchEventMetadata(triggerEventId) {
    this.requestInProgress.eventMetadata = true;
    try {
      const response = await fetchEventDefinitionData(triggerEventId);
      const {
        payload,
        old_payload,
      } = response.data.template_properties;
      runInAction(() => {
        this.goalQueryBuilder.setFields({});
        this.goalQueryBuilder.setFieldsMetadata(
          formatEventNestedKeys({
            payload,
            old_payload,
          }),
          formatEventLabel,
        );
      });
    } catch (err) {
      runInAction(() => {
        this.errors.eventMetadata = err.data;
      });
      handleErrorResponse(err);
    } finally {
      const {
        goal_event_type,
        goal_query,
      } = this.workflow;
      if (goal_event_type && goal_event_type.id === triggerEventId && goal_query) {
        this.goalQueryBuilder.setQueries(goal_query);
      } else if (goal_event_type && goal_event_type.id !== triggerEventId) {
        this.goalQueryBuilder.resetQueries();
      }
      runInAction(() => {
        this.requestInProgress.eventMetadata = false;
      });
    }
  }

  async fetchJourneyStats(id) {
    this.requestInProgress.fetchJourneyStats = true;
    const [err, data] = await run(fetchJourneyStats(id));

    if (err) {
      runInAction(() => {
        this.errors.fetchJourneyStats = err;
        this.requestInProgress.fetchJourneyStats = false;
      });
      handleErrorResponse(err);
      return;
    }

    runInAction(() => {
      this.journeyStats = data;
      this.requestInProgress.fetchJourneyStats = false;
    });
  }

  async fetchWorkflowsForManualEnrollment() {
    const [err, data] = await run(fetchWorkflowsForManualEnrollment());

    if (err) {
      handleErrorResponse(err);
      return;
    }

    runInAction(() => {
      const { results } = data;
      this.workflowsForManualEnrollment = results.map(workflow => ({ display_name: workflow.name, value: workflow.id }));
    });
  }

  async enrollContactToWorkflow(contactId, workflowId) {
    this.requestInProgress.enrollContactToWorkflow = true;

    try {
      await enrollContactToWorkflow({ contact: contactId, workflow: workflowId });
      notifier.success(text.ENROLL_CONTACT_SUCCESS);
    } catch (err) {
      runInAction(() => {
        this.errors.enrollContactToWorkflow = err;
        this.requestInProgress.enrollContactToWorkflow = false;
      });
      handleErrorResponse(err);
    }
  }

  async bulkEnrollContactToWorkflow(contactIds, workflowId) {
    this.requestInProgress.bulkEnrollContactToWorkflow = true;

    try {
      await bulkEnrollContactsToWorkflow({ contacts: contactIds, workflow: workflowId });
      notifier.success(text.BULK_ENROLL_CONTACT_SUCCESS);
    } catch (err) {
      runInAction(() => {
        this.errors.bulkEnrollContactToWorkflow = err;
        this.requestInProgress.bulkEnrollContactToWorkflow = false;
      });
      handleErrorResponse(err);
    }
  }

  async sendTestEmail(content) {
    this.requestInProgress.sendTestEmail = true;
    const { channel } = stores.forms.workflowForm.data;
    const { resetFieldsData } = stores.forms.sendTestMailForm;
    const mail = stores.forms.sendTestMailForm.data.reply_to_email;
    const { message_body } = stores.forms.channelMessengerForm.data;
    const { data } = stores.forms.channelEmailForm;
    const formatedData = {
      body: content,
      from_email: data.from_email,
      reply_to_email: mail,
      subject: data.subject,
    };

    const payload = {
      channel: channel.value,
      ...(channel.value === channelEnum.EMAIL && { email_message: { ...formatedData } }),
      ...(channel.value !== channelEnum.EMAIL && { messanger_message: { message_body } }),
    };
    try {
      await sendTestEmail(payload);
      notifier.info(text.TEST_MESSAGE_SUCCESS);
    } catch (err) {
      handleErrorResponse(err, text.TEST_MESSAGE_FAILED);
    } finally {
      runInAction(() => {
        this.requestInProgress.sendToEmail = false;
      });
      resetFieldsData();
    }
  }

  toggleRecurringWorkflow(value) {
    this.isRecurringEnabled = value;
    if (!value && this.isEndingWorkflow) {
      this.toggleEndingWorkflow(false);
    }
  }

  toggleEndingWorkflow(value) {
    this.isEndingWorkflow = value;
  }

  setWorkflowBuilder(graph) {
    this.graph = graph;
  }

  resetForm() {
    stores.forms.channelEmailForm.resetFieldsData();
    stores.forms.workflowForm.resetFieldsData();
    stores.forms.channelMessengerForm.resetFieldsData();
    this.resetWorkflow();
    this.goalQueryBuilder.resetQueries();
    this.sendToQueryBuilder.resetQueries();
    this.fieldsDef = {};
    this.isRecurringEnabled = false;
    this.isEndingWorkflow = false;
  }

  resetWorkflow() {
    this.workflow = {};
    this.graph = {};
  }

  get updateCreateInProgress() {
    return this.requestInProgress.createWorkflow || this.requestInProgress.updateWorkflow;
  }

  // eslint-disable-next-line class-methods-use-this
  get hasErrorsForStartWorkflow() {
    return (
      !isEmpty(stores.forms.workflowForm.fieldErrors)
      || !isEmpty(stores.forms.channelMessengerForm.fieldErrors)
      || (this.isRecurringEnabled && isEmpty(stores.forms.workflowForm.data.recurring_type))
      || (this.isEndingWorkflow && isEmpty(stores.forms.workflowForm.data.end_datetime))
    );
  }
}

function formatEndDatetime(value) {
  return value ? moment.utc(value, moment.ISO_8601)
    .format('YYYY-MM-DD HH:mm') : value;
}

function formatStartDatetime(value) {
  return moment(value, 'YYYY-MM-DD', true)
    .isValid()
    ? moment.utc(value)
      .utc()
    : moment(value, moment.ISO_8601)
      .utc();
}

function formatWorkflowPayload(sendToQuery, goalQuery, isRecurring, isEnding) {
  const { data } = stores.forms.workflowForm;
  const {
    start_datetime,
    end_datetime,
    recurring_time,
    ...payload
  } = isRecurring
    ? data
    : Object.keys(data)
      .reduce((acc, k) => {
        acc[k] = k.includes('recurring') ? null : data[k];
        return acc;
      }, {});

  return {
    ...formatPayload(payload),
    goal_query: !isEmpty(goalQuery.rules) ? formatQueryRulesArrayValues(goalQuery) : null,
    recurring_time: recurring_time ? moment(recurring_time, 'HH:mm')
      .utc()
      .format('HH:mm') : null,
    send_to_query: !isEmpty(sendToQuery.rules) ? formatQueryRulesArrayValues(sendToQuery) : null,
    end_datetime: isEnding ? formatEndDatetime(end_datetime) : null,
    start_datetime: start_datetime ? formatStartDatetime(start_datetime) : null,
  };
}

function setErrors(errorData) {
  const {
    setFieldsErrors,
    resetFieldError,
  } = stores.forms.workflowForm;
  const setTemplateErrors = stores.forms.channelEmailForm.setFieldsErrors;
  const fieldErrors = omit(errorData, [
    'non_field_errors',
    'email_from',
    'reply_to',
    'subject',
    'template',
    'goal_query',
    'send_to_query',
  ]);
  const templateErrors = pick(errorData, 'template');
  setFieldsErrors(fieldErrors);
  setTemplateErrors(templateErrors);
  if (errorData.email_message) {
    resetFieldError('email_message');
    stores.forms.channelEmailForm.setFieldsErrors(errorData.email_message);
  }
  if (errorData.messanger_message) {
    resetFieldError('messanger_message');
    stores.forms.channelMessengerForm.setFieldsErrors(errorData.messanger_message);
  }
}

// eslint-disable-next-line
const hasEnding = (sendToType, isRecurringWorkflow) => SEND_TO_TRIGGER_TYPES.includes(sendToType) || isRecurringWorkflow;

function formatWorkflowFormData(data) {
  const {
    send_to_type,
    send_to_segment,
    goal_event_type,
    recurring_time,
  } = data;
  return {
    ...data,
    recurring_time: moment.utc(recurring_time, 'HH:mm')
      .local()
      .format('HH:mm'),
    send_to_type: send_to_type ? {
      value: send_to_type,
      display_name: send_to_type,
    } : null,
    send_to_segment: send_to_segment ? {
      value: send_to_segment?.id,
      display_name: send_to_segment?.name,
    } : null,
    goal_event_type: {
      ...goal_event_type,
      value: goal_event_type?.id,
      display_name: goal_event_type?.name,
    },
  };
}

export { hasEnding };
