import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { object, func, bool, array } from 'prop-types';

import { fetchActions } from 'Automation/services/rulesService';
import { formatKey } from 'App/helpers/format';
import {
  addWorkflowBlock,
  fetchWorkflowPropsMetadata,
  editWorkflowBlock,
  removeWorkflowBlock,
  moveWorkflowBlock,
  getWorkflow,
} from 'Marketing/services/WorkflowService';
import run from 'App/services/utilities/run';
import { fieldsResolver } from 'App/components/FieldRenderer';
import { handleErrorResponse } from 'App/services/utilities/error.utils';
import Workflow from '../Workflow';
import { addMockActions, addMockFields } from './mockData';

const propTypes = {
  graph: object.isRequired,
  match: object.isRequired,
  setWorkflowBuilder: func.isRequired,
  isActive: bool.isRequired,
  isOverview: bool,
  steps: array,
};

const defaultProps = {
  isOverview: false,
  steps: null,
};

const ActionType = 'Action';

const BlockMapping = {
  Delays: [
    {
      type: 'Time Delay',
      name: 'Time Delay',
    },
    {
      type: 'Wait Until',
      name: 'Wait Until...',
    },
  ],
  'Flow Control': [
    {
      type: 'Branch',
      name: 'True/False Branch',
    },
    // Mocked branch
    {
      type: 'Email Condition Block',
      name: 'Email Condition Block',
      actionID: 'email_branch',
    },
  ],
  Action: [
    {
      type: 'Action',
      name: 'Send Webhook',
      actionID: 'send_webhook',
    },
    {
      type: 'Action',
      name: 'Send Email',
      actionID: 'send_email_to_contact',
    },
    {
      type: 'Action',
      name: 'Send Whatsapp',
      actionID: 'send_whatsapp_message_to_contact',
    },
    {
      type: 'Action',
      name: 'Send SMS',
      actionID: 'send_sms_to_contact',
    },
    {
      type: 'Action',
      name: 'Send to Workflow',
      actionID: 'send_to_workflow',
    },
    {
      type: 'Action',
      name: 'Update Client',
      actionID: 'update_client',
    },
    {
      type: 'Action',
      name: 'Approve Client',
      actionID: 'approve_client',
    },
    {
      type: 'Action',
      name: 'Reject Client',
      actionID: 'reject_client',
    },
    {
      type: 'Action',
      name: 'Send Notification to Client',
      actionID: 'send_notification_to_client',
    },
    {
      type: 'Action',
      name: 'Send Notification to User',
      actionID: 'send_notification_to_user',
    },
    {
      type: 'Action',
      name: 'Send Notification to Manager',
      actionID: 'send_notification_to_manager',
    },
    {
      type: 'Action',
      name: 'Apply Bonus',
      actionID: 'apply_bonus',
    },
    {
      type: 'Action',
      name: 'Reset User Password',
      actionID: 'reset_user_password',
    },
    {
      type: 'Action',
      name: 'Reset all Trading account passwords',
      actionID: 'reset_all_trading_account_passwords',
    },
    {
      type: 'Action',
      name: 'Block/Unblock login',
      actionID: 'block_unblock_login',
    },
    {
      type: 'Action',
      name: 'Mask all user attributes',
      actionID: 'mask_personal_data',
    },
    {
      type: 'Action',
      name: 'Read only mode for all Trading accounts',
      actionID: 'set_read_only_for_all_accounts',
    },
    {
      type: 'Action',
      name: 'Create Trading Account',
      actionID: 'create_trading_account',
    },
    {
      type: 'Action',
      name: 'Edit Trading Account',
      actionID: 'edit_trading_account',
    },
    {
      type: 'Action',
      name: 'Action',
    },
  ],
  Exit: [
    {
      type: 'Exit',
      name: 'Exit',
    },
  ],
};

const WorkflowBuilder = ({
  graph,
  match,
  setWorkflowBuilder,
  isActive,
  isOverview,
  steps,
}) => {
  const { workflowId } = match.params;
  const [workflowLoading, setWorkflowLoading] = useState(false);
  const [propFields, setPropFields] = useState(null);
  const [actions, setActions] = useState([]);

  const onAddNode = async (payload, onBeforeAdd = () => null) => {
    setWorkflowLoading(true);
    const [err, data] = await run(
      addWorkflowBlock(workflowId, {
        data: {
          ...payload.block,
        },
        ...payload.insertInfo,
      }),
    );
    setWorkflowLoading(false);
    if (!err) {
      onBeforeAdd(data);
      setWorkflowBuilder(data);
    }

    return [err, data];
  };

  const onEditNode = async (payload) => {
    const [err] = await run(editWorkflowBlock(workflowId, payload.id, payload));

    if (!err) {
      const { data } = await getWorkflow(workflowId);
      setWorkflowBuilder(data);
    }

    return err;
  };

  const onRemoveNode = async (payload) => {
    setWorkflowLoading(true);
    const [err, resp] = await run(removeWorkflowBlock(workflowId, payload.id));
    setWorkflowLoading(false);
    if (!err) {
      setWorkflowBuilder(resp);
    } else if (err.response.data) {
      handleErrorResponse(err);
    }
  };

  const onMoveNode = async ({ blockId, payload }, onBeforeAdd = () => {
    // empty
  }) => {
    setWorkflowLoading(true);
    const [err, data] = await run(
      moveWorkflowBlock(workflowId, blockId, {
        ...payload,
      }),
    );
    setWorkflowLoading(false);
    if (!err) {
      onBeforeAdd(data);
      setWorkflowBuilder(data);
    } else if (err.response.data) {
      handleErrorResponse(err);
    }

    return [err, data];
  };

  useEffect(() => {
    (async () => {
      const { data } = await fetchActions();
      const actionsArray = Object.keys(data).map(key => ({
        actionID: key,
        type: ActionType,
        name: formatKey(key),
        fields: data[key],
      }));

      const newActions = addMockActions(actionsArray);
      console.log('new actions =>', newActions);

      setActions(newActions);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const propsMetadata = await fetchWorkflowPropsMetadata();
      const fields = Object.keys(propsMetadata.data).reduce((acc, type) => {
        acc[type] = fieldsResolver(propsMetadata.data[type]);
        return acc;
      }, {});

      // Add mocked fields
      const newFields = addMockFields(fields);

      setPropFields(newFields);
    })();
  }, []);

  return (
    <Workflow
      loading={workflowLoading}
      blocks={BlockMapping}
      workflowBuilder={graph}
      onAddNode={onAddNode}
      onEditNode={onEditNode}
      onRemoveNode={onRemoveNode}
      onMoveNode={onMoveNode}
      propFields={propFields}
      actions={actions}
      isActive={isActive}
      steps={steps}
      isOverview={isOverview}
    />
  );
};

WorkflowBuilder.propTypes = propTypes;
WorkflowBuilder.defaultProps = defaultProps;

export default inject(stores => ({
  graph: stores.workflows.graph,
  setWorkflowBuilder: stores.workflows.setWorkflowBuilder,
}))(observer(withRouter(WorkflowBuilder)));
