import React from 'react';
import { object, string, oneOfType, func, array, number, bool } from 'prop-types';
import moment from 'moment';

import fieldTypes from 'App/enums/fieldTypes';
import {
  Select,
  TextInput,
  NumberInput,
  NumberRange,
  DateInput,
  DateRange,
  BooleanInput,
  NumberMulti,
  TextInputMulti,
} from './FilterInputs';
import { isRange, isDayAgo, isIn, isNonValue, isExact, isAnniversary } from './filterStoreUtils';

const propTypes = {
  fields: object,
  value: oneOfType([object, string, array, number, bool]),
  selectedField: object,
  operator: object,
  relatedModels: oneOfType([object, array]),
  onChange: func.isRequired,
  onEnter: func,
  style: object,
  fieldLabel: string,
  withPortal: bool,
};

const defaultProps = {
  fields: {},
  value: '',
  selectedField: null,
  operator: null,
  relatedModels: null,
  style: null,
  fieldLabel: '',
  withPortal: false,
  onEnter: () => null,
};

const text = {
  PLACEHOLDER: 'Press Tab or Enter to add new value',
};

const getFieldOptions = (fields, selectedField) => fields[selectedField.value].choices;

const getSelectType = (operator) => {
  if (operator && operator.value && isIn(operator.value)) {
    return 'multi';
  }
  return 'single';
};

const formatDisplayDate = (dateString) => {
  if (Array.isArray(dateString) && dateString.length === 2) return dateString;
  if (dateString && moment.isMoment(moment(dateString))) {
    return moment(dateString).format('YYYY-MM-DD');
  }
  return '';
};

const formatListValues = (operator, value, formatFunction) => {
  if ((isIn(operator) || isRange(operator)) && !Array.isArray(value)) {
    const values = !(value === '') ? value?.split(',') : [];
    if (formatFunction) {
      return values.map(element => formatFunction(element));
    }
    return values;
  }
  return value;
};

const FilterInput = ({
  selectedField,
  fields,
  operator,
  onChange,
  onEnter,
  value,
  relatedModels,
  style,
  fieldLabel,
  withPortal,
}) => {
  if (isNonValue(operator.value)) return null;
  const selectOptions = getFieldOptions(fields, selectedField);
  switch (selectedField.type) {
    case fieldTypes.INTEGER:
    case fieldTypes.DECIMAL:
    case fieldTypes.FLOAT:
      if (isRange(operator.value)) {
        return (
          <NumberRange
            value={formatListValues(operator.value, value, Number)}
            onChange={onChange}
            onEnter={onEnter}
          />
        );
      }
      if (isIn(operator.value)) {
        return (
          <NumberMulti
            placeholder={text.PLACEHOLDER}
            value={formatListValues(operator.value, value, Number)}
            onChange={onChange}
          />
        );
      }
      return <NumberInput value={value} onChange={onChange} onEnter={onEnter} />;
    case fieldTypes.CHOICE:
    case fieldTypes.GENERIC_RELATION:
    case fieldTypes.RELATED_FIELD:
    case fieldTypes.MULTI_RELATED_FIELD:
      return (
        <Select
          value={formatListValues(
            operator.value,
            value,
            selectedField.type === fieldTypes.RELATED_FIELD ? parseInt : null,
          )}
          onChange={onChange}
          options={selectOptions}
          relatedModels={relatedModels}
          style={style}
          type={getSelectType(operator)}
          fieldLabel={fieldLabel}
          menuPortalTarget={withPortal ? document.body : null}
        />
      );
    case fieldTypes.BOOLEAN:
      return <BooleanInput value={value} onChange={onChange} />;
    case fieldTypes.DATE:
    case fieldTypes.DATETIME:
      if (isRange(operator.value)) {
        return (
          <DateRange
            onChangeDateFormat="YYYY-MM-DD HH:mm"
            value={formatListValues(operator.value, value, formatDisplayDate)}
            onChange={onChange}
            withPortal={withPortal}
          />
        );
      }
      if (isDayAgo(operator.value)) {
        return <NumberInput value={value} onChange={onChange} onEnter={onEnter} />;
      }
      if (isAnniversary(operator.value)) {
        return null;
      }
      return (
        <DateInput
          value={formatDisplayDate(value)}
          withPortal={withPortal}
          onChange={onChange}
        />
      );
    case fieldTypes.LIST_FIELD:
      // NOTE: We set multi input for exact operations, since we're dealing
      // with list field.
      if (isExact(operator.value)) {
        return (
          <TextInputMulti
            placeholder={text.PLACEHOLDER}
            value={formatListValues('in', value, null)}
            onChange={onChange}
          />
        );
      }
      return <TextInput value={value} onChange={onChange} onEnter={onEnter} />;
    default:
      if (isIn(operator.value)) {
        return (
          <TextInputMulti
            placeholder={text.PLACEHOLDER}
            value={formatListValues(operator.value, value, null)}
            onChange={onChange}
          />
        );
      }
      return <TextInput value={value} onChange={onChange} onEnter={onEnter} />;
  }
};

FilterInput.propTypes = propTypes;
FilterInput.defaultProps = defaultProps;

export default FilterInput;
