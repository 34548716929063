import React from 'react';
import Page from 'App/components/Page';
import { ContactsTable, tableConfig } from 'Contacts/components';

const bread = [
  { label: 'Contacts', route: '/contacts' },
  { label: 'Clients', route: '/contacts/clients' },
];

const exportDataOptions = { permission: 'finances_tradeposition', path: `/api${tableConfig.tableUrl}`, tableKey: tableConfig.tableKey };
const defaultFilters = { 'status__exact!': 'Lead', 'type__exact!': 'Marketing Agent' };

const ClientsPage = () => (
  <Page
    bread={bread}
    title="Clients"
    style={{ margin: '0 20px' }}
    exportDataOptions={exportDataOptions}
    headerActions={null}
  >
    <ContactsTable defaultFilters={defaultFilters} />
  </Page>
);

export default ClientsPage;
