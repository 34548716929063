import React, { PureComponent } from 'react';
import { Button, DatePickerRange } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { func, object, array, bool } from 'prop-types';

import Page from 'App/components/Page';
import { closePreviewTask } from 'App/components/CommonOverlays/PreviewTask';
import Filters, { FiltersServiceFactory } from 'App/components/Filters';
import { AddEditTask, ADD_EDIT_SIDEPANEL_ID } from 'Tasks/components/sidepanels';
import { openOverlay, closeOverlay } from 'App/services/overlayService';
import TasksAccordion from 'Tasks/components/TasksAccordion';
import Loader from 'App/components/Loader';

import '../TasksPage.scss';

const propTypes = {
  date: object.isRequired,
  setDateQuery: func.isRequired,
  fetchTasks: func.isRequired,
  activeFilters: array.isRequired,
  resetFilters: func.isRequired,
  fetchTasksOptions: func.isRequired,
  overDue: object.isRequired,
  resetAccordions: func.isRequired,
  filtersStore: object.isRequired,
  loadingOptions: bool.isRequired,
  addNewFilter: func.isRequired,
  match: object.isRequired,
};

const text = {
  today: 'Due today tasks',
  TITLE: 'Due today tasks',
};

const bread = [
  { label: 'Tasks', route: '/tasks' },
  { label: 'Due today', route: '/tasks/due-today' },
];

const accordionId = {
  today: 'today',
};

const filtersService = FiltersServiceFactory('/tasks/');

class DueTodayTasksPage extends PureComponent {
  componentDidMount() {
    const { date, setDateQuery } = this.props;
    setDateQuery(date);
    this.fetchTasksOptionsData();
    const { match } = this.props;
    const { action } = match.params;
    if (action) {
      openOverlay(ADD_EDIT_SIDEPANEL_ID, {
        type: 'add',
        onSuccess: this.fetchAllTasks,
      });
    }
  }

  componentWillUnmount() {
    this.resetFilters();
    closePreviewTask();
    closeOverlay(ADD_EDIT_SIDEPANEL_ID);
  }

  headerActions = () => (
    <Button
      onClick={() => openOverlay(ADD_EDIT_SIDEPANEL_ID, {
        type: 'add',
        onSuccess: this.fetchAllTasks,
      })
      }
    >
      Create task
    </Button>
  );

  applyDateFilter = (date) => {
    const { setDateQuery } = this.props;
    setDateQuery(date);
    this.fetchAllTasks();
  };

  resetFilters = () => {
    const { resetFilters } = this.props;
    resetFilters();
  };

  fetchTodayTasks = async (resetData) => {
    const { fetchTasks } = this.props;
    await fetchTasks(accordionId.today, resetData);
  };

  fetchAllTasks = async (resetData) => {
    const { resetAccordions } = this.props;
    resetAccordions();
    await this.fetchTodayTasks(resetData);
  };

  fetchTasksOptionsData = async () => {
    const { fetchTasksOptions } = this.props;
    await fetchTasksOptions();
  };

 filterAction = action => async (value) => {
   action(value);
   this.fetchAllTasks();
 };

 render() {
   const {
     activeFilters,
     date,
     today,
     filtersStore,
     loadingOptions,
   } = this.props;
   return (
     <Page
       headerActions={this.headerActions}
       bread={bread}
       title={text.TITLE}
       style={{ overflow: 'none' }}
     >
       <div className="fiq-tasks">
         <div className="fiq-tasks__filters">
           <Filters
             activeFilters={activeFilters}
             filtersService={filtersService}
             fetchData={this.fetchAllTasks}
             filtersStore={filtersStore}
             applyFilter={this.filterAction(filtersStore.addNewFilter)}
             viewName={filtersStore.viewName}
             savedFilters={filtersStore.savedFilters}
           />
           <div className="fiq-tasks__filters__daterange">
             <DatePickerRange
               onChange={this.applyDateFilter}
               startDate={date.startDate}
               endDate={date.endDate}
               predefinedRange
               startDateModifiers={{
                 popperModifiers: {
                   offset: {
                     enabled: true,
                     offset: '-100px, 0px',
                   },
                 },
               }}
               endDateModifiers={{
                 popperModifiers: {
                   offset: {
                     enabled: true,
                     offset: '-220px, 0px',
                   },
                 },
               }}
             />
           </div>
         </div>
         <Loader visible={loadingOptions} />
         {!loadingOptions && (
         <div className="fiq-tasks__content">
           <TasksAccordion
             {...today}
             fetchTasks={this.fetchTodayTasks}
             fetchAllTasks={this.fetchAllTasks}
             isOpen
             title={text.today}
             accordionId="today"
           />
         </div>
         )}
       </div>
       <AddEditTask />
     </Page>
   );
 }
}

DueTodayTasksPage.propTypes = propTypes;
DueTodayTasksPage.displayName = 'TasksPage';

export default inject(stores => ({
  setDateQuery: stores.tasks.setDateQuery,
  date: stores.tasks.date,
  fetchTasks: stores.tasks.fetchTasks,
  fetchTasksOptions: stores.tasks.fetchOptionsData,
  activeFilters: stores.tasks.filters.activeFilters,
  resetFilters: stores.tasks.resetFilters,
  filtersStore: stores.tasks.filters,
  today: stores.tasks.accordions.today,
  resetAccordions: stores.tasks.resetAccordions,
  loadingOptions: stores.tasks.requestInProgress.options,
  addNewFilter: stores.tasks.filters.addNewFilter,
}))(observer(DueTodayTasksPage));
