import React, { PureComponent } from 'react';
import { number, oneOfType, string } from 'prop-types';

import GridComponentFactory from 'App/components/grid';
import withErrorBoundary from 'App/components/hoc/withErrorBoundary';
import ContactActions from 'Contacts/components/ContactActions';
import { Dropdown, withSidepanelManager } from 'tc-biq-design-system';
import actionTypes from 'Contacts/components/ContactActions/actions/types';
import modifiers from './modifiers';


const propTypes = {
  id: oneOfType([number, string]).isRequired,
};

const tableConfig = contactId => ({
  tableUrl: `/contacts/${contactId}/wallets/`,
  tableKey: 'contactWallets',
});

const sizeOptions = [10, 20, 50, 100, 200];
const customColumns = [
  {
    key: 'providerType',
    headerName: 'Provider Type',
  },
  {
    key: 'mode',
    headerName: 'Mode',
  },
  {
    key: 'group',
    headerName: 'Group',
  },
];

const mapActionAttributes = actions => actions.map(({ label, icon, handler }) => ({
  label, icon, onClick: handler,
}));

class ContactWalletsTable extends PureComponent {
  render() {
    const { id, contact } = this.props;
    const { GridComponent} = GridComponentFactory(tableConfig(id));

    const Actions = props => (
      <ContactActions type={actionTypes.TRADING_ACCOUNTS} contact={contact} {...props}>
        {actions => !_.isEmpty(actions) && <Dropdown openLeft title="Actions" list={mapActionAttributes(actions)} />}
      </ContactActions>
    );

    const ActionsComponent = withSidepanelManager(Actions);
    return (
      <div>
        <GridComponent
          modifiers={modifiers}
          customColumns={customColumns}
          sizeOptions={sizeOptions}
          customActions={ActionsComponent}
          defaultPageSize={10}
          height="calc(100vh - 450px)"
        />
      </div>
    );
  }
}

ContactWalletsTable.propTypes = propTypes;

export default withErrorBoundary(ContactWalletsTable);
