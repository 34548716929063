import { cloneDeep } from 'lodash';

const lookups = {
  exact: 'is equal to',
  'exact!': 'is not equal to',
  isnull: 'does not exist',
  'isnull!': 'exists',
  gt: 'greater than',
  gte: 'greater than or equal to',
  lt: 'less than',
  lte: 'less than or equal to',
  range: 'between',
  'range!': 'all except between',
  in: 'is one of',
  'in!': 'is not one of',
};

const emailConditionProperties = {
  // unsubscribe: {
  //   type: 'choice',
  //   choices: [{ value: 'Yes', display_name: 'Yes' }, { value: 'No', display_name: 'No' }],
  //   lookups,
  // },
  spam: {
    type: 'choice',
    choices: [{ value: 'Yes', display_name: 'Yes' }, { value: 'No', display_name: 'No' }],
    lookups,
  },
  open: {
    type: 'choice',
    choices: [{ value: 'Yes', display_name: 'Yes' }, { value: 'No', display_name: 'No' }],
    lookups,
  },
  click: {
    type: 'choice',
    choices: [{ value: 'Yes', display_name: 'Yes' }, { value: 'No', display_name: 'No' }],
    lookups,
  },
};

const updateClientFields = {
  updateClient: {
    type: 'choice',
    required: true,
    read_only: false,
    label: 'Update client',
    many: false,
    visible: true,
    choices: [
      { value: 'attribute', display_name: 'Update attribute' },
      { value: 'status', display_name: 'Update status' },
    ],
  },
};

const sendToWorkflowFields = {
  workflow: {
    type: 'choice',
    required: true,
    read_only: false,
    label: 'Workflow',
    many: false,
    visible: true,
    choices: [
      { value: 'workflow1', display_name: 'Workflow 1' },
      { value: 'workflow2', display_name: 'Workflow 2' },
      { value: 'workflow3', display_name: 'Workflow 3' },
    ],
  },
};

const applyBonusFields = {
  bonus: {
    type: 'choice',
    required: true,
    read_only: false,
    label: 'Bonus',
    many: false,
    visible: true,
    choices: [
      { value: 'bonus1', display_name: 'Bonus campaign 500 + 100 USD' },
      { value: 'bonus2', display_name: 'Bonus campaign 300 + 200 USD' },
      { value: 'bonus3', display_name: 'Bonus campaign 1000 + 500 USD' },
      { value: 'bonus4', display_name: 'Bonus campaign 1000 + 500 + 100 USD' },
    ],
  },
};

const blockUnblockFields = {
  blockUnblock: {
    type: 'choice',
    required: true,
    read_only: false,
    label: 'Block/Unblock',
    many: false,
    visible: true,
    choices: [
      { value: 'block', display_name: 'Block' },
      { value: 'unblock', display_name: 'Unblock' },
    ],
  },
};

const tradingAccountFields = {
  backend: {
    type: 'choice',
    required: true,
    read_only: false,
    label: 'Service',
    many: false,
    visible: true,
    choices: [
      { value: 'mt4', display_name: 'MT4' },
      { value: 'mt6', display_name: 'MT5' },
      { value: 'ctrader', display_name: 'cTrader' },
    ],
  },
  currency: {
    type: 'choice',
    required: true,
    read_only: false,
    label: 'Service',
    many: false,
    visible: true,
    choices: [
      { value: 'usd', display_name: 'USD' },
      { value: 'eur', display_name: 'EUR' },
    ],
  },
  accountType: {
    type: 'choice',
    required: true,
    read_only: false,
    label: 'Account type',
    many: false,
    visible: true,
    choices: [
      { value: 'acc1', display_name: 'Account type 1' },
      { value: 'acc2', display_name: 'Account type 2' },
      { value: 'acc3', display_name: 'Account type 3' },
    ],
  },
  group: {
    type: 'choice',
    required: true,
    read_only: false,
    label: 'Choose group',
    many: false,
    visible: true,
    choices: [
      { value: 'group1', display_name: 'Group 1' },
      { value: 'group2', display_name: 'Group 2' },
      { value: 'group3', display_name: 'Group 3' },
    ],
  },
  readOnly: {
    type: 'boolean',
    required: true,
    read_only: false,
    label: 'Read only',
    many: false,
    visible: true,
  },
};

const addMockFields = (fields) => {
  const newFields = cloneDeep(fields);
  // Hardcode Email condition block properties
  const emailBranch = cloneDeep(newFields.Branch);
  emailBranch[0].properties = emailConditionProperties;
  newFields['Email Condition Block'] = emailBranch;

  return newFields;
};

const addMockActions = (actions) => {
  const newActions = cloneDeep(actions);
  const fieldExample = cloneDeep(actions.find(action => action.actionID === 'send_email_to_contact'));

  // Add Send to workflow action
  const sendToWorkflow = { ...fieldExample };
  sendToWorkflow.actionID = 'send_to_workflow';
  sendToWorkflow.name = 'Send to Workflow';
  sendToWorkflow.fields = sendToWorkflowFields;

  newActions.push(sendToWorkflow);

  // Add Update client action
  const updateClient = { ...fieldExample };
  updateClient.actionID = 'update_client';
  updateClient.name = 'Update Client';
  updateClient.fields = updateClientFields;
  newActions.push(updateClient);

  // Add Apply bonus action fields
  const actionIndex = newActions.findIndex(action => action.actionID === 'apply_bonus');
  newActions[actionIndex].fields = applyBonusFields;

  // Add Reset User Password
  const resetUserPassword = { ...fieldExample };
  resetUserPassword.actionID = 'reset_user_password';
  resetUserPassword.name = 'Reset User Password';
  resetUserPassword.fields = null;
  newActions.push(resetUserPassword);

  // Add Block/Unblock login
  const blockUnblockLogin = { ...fieldExample };
  blockUnblockLogin.actionID = 'block_unblock_login';
  blockUnblockLogin.name = 'Block/Unblock login';
  blockUnblockLogin.fields = blockUnblockFields;
  newActions.push(blockUnblockLogin);

  // Add Mask personal data
  const maskPersonalData = { ...fieldExample };
  maskPersonalData.actionID = 'mask_personal_data';
  maskPersonalData.name = 'Mask all user attributes';
  maskPersonalData.fields = null;
  newActions.push(maskPersonalData);

  // Add fields for Creating/Editing trading account
  const createTradingAccountIndex = newActions.findIndex(action => action.actionID === 'create_trading_account');
  newActions[createTradingAccountIndex].fields = tradingAccountFields;

  const editTradingAccountIndex = newActions.findIndex(action => action.actionID === 'edit_trading_account');
  newActions[editTradingAccountIndex].fields = tradingAccountFields;

  return newActions;
};

// eslint-disable-next-line import/prefer-default-export
export { addMockFields, addMockActions };
