import React, { useEffect } from 'react';
import { Button } from 'tc-biq-design-system';
import { object } from 'prop-types';

import Page from 'App/components/Page';
import TrackingLinksTable from 'Settings/Sections/TrackingLinks/components/TrackingLinksTable';
import { openCreateEditTrackingLink } from 'Settings/Sections/TrackingLinks/components/overlays/CreateEditTrackingLink';
import { hasAccess } from 'App/services/permissionsService';


const text = {
  TITLE: 'Tracking links',
  CREATE_TRACKING_LINK: 'Create tracking link',
};

const bread = [
  { label: 'Settings', route: '/settings' },
  { label: text.TITLE, route: '/settings/tracking-links' },
];

const TrackingLinksPage = ({ match }) => {
  const routeToCreate = () => openCreateEditTrackingLink(null, false);
  const headerActions = () => (hasAccess('marketing_trackinglink', 'create') ? <Button onClick={routeToCreate}>{text.CREATE_TRACKING_LINK}</Button> : null);
  useEffect(() => {
    const { action } = match.params;
    if (action === 'create') openCreateEditTrackingLink(null, false);
  }, []);
  return (
    <Page title={text.TITLE} bread={bread} headerActions={headerActions} style={{ margin: '0 20px' }}>
      <TrackingLinksTable />
    </Page>
  );
};

TrackingLinksPage.propTypes = { match: object.isRequired };

export default TrackingLinksPage;
