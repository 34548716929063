/* eslint-disable react/prop-types */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Sidepanel, Space, HyperLink, Panel, Pill, Button } from 'tc-biq-design-system';
import { object, bool } from 'prop-types';

import { closeOverlay, openOverlay } from 'App/services/overlayService';
import { ContactCountRenderer } from 'Settings/Sections/TrackingLinks/components/TrackingLinksTable/modifiers';
import RenderIcon from 'App/components/RenderIcon';
import CopyToClipboardWrapper from 'App/components/CopyToClipboardWrapper';
import { UserCell, DateTimeCell } from 'App/components/gridCellRenderers';
import extractDisplayName from 'App/services/utilities/extractDisplayName';

import './PreviewTrackingLink.scss';

const propTypes = {
  parameters: object,
  visible: bool,
};

const defaultProps = {
  parameters: {},
  visible: false,
};

const text = {
  TITLE: 'Preview tracking link',
  PANEL_TITLE: 'Additional info',
  COPY: 'Copy',
  CONTACTS: 'Contacts:',
  LINK_VISITS: 'Link visits:',
  AUDIENCE: 'Audience:',
  CREATED_BY: 'Created by',
  UPDATED_BY: 'Updated by',
};

const audienceTypeIconNameMap = {
  'Facebook Ads': 'Facebook',
  'Google Ads': 'GoogleAds',
};

const fields = ['landing_page', 'utm_campaign', 'utm_content', 'utm_medium', 'utm_source', 'utm_term'];

const SIDEPANEL_ID = 'PREVIEW_TRACKING_LINK';

export const openPreviewTrackingLink = trackingLink => openOverlay(SIDEPANEL_ID, { trackingLink });
export const closePreviewTrackingLink = () => closeOverlay(SIDEPANEL_ID);

const normalizeKey = key => key
  .toLowerCase()
  .split('_')
  .map(s => s.charAt(0).toUpperCase() + s.substring(1))
  .join(' ');

const UserWrapper = ({ title, user, date }) => (
  <div className="user-wrapper">
    <p className="tc-micro-regular text-neutral-700 user-wrapper__title">{title}</p>
    <UserCell
      id={user.id}
      avatar={user.avatar}
      fullName={extractDisplayName(user)}
      subTitle={<DateTimeCell value={date} />}
    />
  </div>
);

const AdditionalDetails = ({ trackingLink }) => (
  <Panel title={text.PANEL_TITLE}>
    {fields.map(item => (
      <div key={item} className="row">
        <div className="text-neutral-700">
          {normalizeKey(item)}
        </div>
        <div className="tc-micro-regular">{trackingLink[item]}</div>
      </div>
    ))}
  </Panel>
);

const ImportantDetails = ({ trackingLink }) => {
  const { audience, link_visits } = trackingLink;
  return (
    <div className="fiq-tracking-link__preview-tracking-link__wrapper">
      <div className="important-details">
        <div className="item">
          <p className="label">{text.CONTACTS}</p>
          <ContactCountRenderer data={trackingLink} />
        </div>
        <div className="item">
          <p className="label">{text.LINK_VISITS}</p>
          <Pill type="neutral">{link_visits}</Pill>
        </div>
      </div>
      {
      audience && (
      <div className="item audience">
        <p className="label">{text.AUDIENCE}</p>
        <RenderIcon icon={audienceTypeIconNameMap[audience.ad_network]} iconSize={20} />
        <HyperLink>
          <Link to={`/automation/segments/preview/${audience.segment.id}`}>{audience.segment.name}</Link>
        </HyperLink>
      </div>
      )}
    </div>
  );
};

const PreviewTrackingLink = inject(stores => ({
  parameters: stores.overlayStore.overlay.parameters,
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
}))(
  observer(({ parameters, visible }) => {
    if (!parameters.trackingLink || !visible) return null;
    const { trackingLink } = parameters;
    const { name, description, url, created, created_by, updated, updated_by } = trackingLink;

    return (
      <Sidepanel
        icon="CalendarCreate"
        title={text.TITLE}
        visible={visible}
        onCloseIconClick={() => closeOverlay(SIDEPANEL_ID)}
        footerRender={() => null}
      >
        <div className="fiq-tracking-link__preview-tracking-link">
          <div className="fiq-tracking-link__preview-tracking-link__wrapper">
            <div className="tc-paragraph-regular">{name}</div>
            <Space size={12} />
            <div className="tc-micro-regular text-neutral-700">{description}</div>
          </div>
          <div className="fiq-tracking-link__preview-tracking-link__wrapper url">
            <div>
              {url}
            </div>
            <CopyToClipboardWrapper textToCopy={url}>
              <Button onClick={e => e.preventDefault()} size="small" icon="Duplicate">
                {text.COPY}
              </Button>
            </CopyToClipboardWrapper>
          </div>
          <ImportantDetails trackingLink={trackingLink} />
          <div className="fiq-tracking-link__preview-tracking-link__wrapper users">
            <UserWrapper user={created_by} date={created} title={text.CREATED_BY} />
            {updated_by && <UserWrapper user={updated_by} date={updated} title={text.UPDATED_BY} />}
          </div>
          <AdditionalDetails trackingLink={trackingLink} />
        </div>
      </Sidepanel>
    );
  }),
);

PreviewTrackingLink.wrappedComponent.propTypes = propTypes;
PreviewTrackingLink.wrappedComponent.defaultProps = defaultProps;

export default PreviewTrackingLink;
