import React, { Fragment, useEffect, useState } from 'react';
import { closeOverlay, openOverlay } from 'App/services/overlayService';
import SidepanelFooter from 'App/components/SidepanelFooter';
import { Select, Sidepanel } from 'tc-biq-design-system';
import { func, object } from 'prop-types';
import { inject, observer } from 'mobx-react';

const SIDEPANEL_ID = 'BULK_MANUAL_ENROLL_TO_WORKFLOW';

export const openBulkManualEnrollToWorkflow = (selectedItems, selectedAll) => {
  openOverlay(SIDEPANEL_ID, { selectedItems, selectedAll });
};

export const closeBulkManualEnrollToWorkflow = () => {
  closeOverlay(SIDEPANEL_ID);
};

const text = {
  SAVE: 'Save',
  DISCARD: 'Discard',
  SIDEPANEL_TITLE: 'Bulk enroll to workflow',
  PLACEHOLDER: 'Workflow',
};

const footerRender = (submit, onClose, submitInProgress) => () => (
  <SidepanelFooter
    execute={submit}
    close={onClose}
    disableSubmit={submitInProgress}
    submitInProgress={submitInProgress}
    buttonLabels={{ confirm: text.SAVE, cancel: text.DISCARD }}
    confirmColor="primary"
  />
);

const BulkManualEnrollToWorkflow = ({
  overlay,
  onSuccess,
  submitInProgress,
  error,
  workflowsForManualEnrollment,
  fetchWorkflowsForManualEnrollment,
  bulkEnrollContactToWorkflow,
}) => {
  const [workflow, setWorkflow] = useState(null);
  const { selectedItems } = overlay.parameters || {};

  const submit = async () => {
    if (selectedItems?.length === 0 || !workflow) return;

    const contactIds = selectedItems.map(item => Number(item.id));
    await bulkEnrollContactToWorkflow(contactIds, workflow.value);

    if (!error) {
      onClose();
    }
  };

  const onClose = () => {
    closeBulkManualEnrollToWorkflow();
    onSuccess();
  };

  useEffect(() => {
    fetchWorkflowsForManualEnrollment();
  }, []);

  return (
    <Sidepanel
      visible={overlay[SIDEPANEL_ID]}
      footerRender={footerRender(submit, onClose, submitInProgress)}
      title={text.SIDEPANEL_TITLE}
      onCloseIconClick={onClose}
      icon="Edit"
    >
      <Fragment>
        <Select
          type="search"
          labelKey="display_name"
          valueKey="value"
          label={text.PLACEHOLDER}
          options={workflowsForManualEnrollment}
          placeholder={text.PLACEHOLDER}
          value={workflow}
          onChange={value => setWorkflow(value)}
        />
      </Fragment>
    </Sidepanel>
  );
};

BulkManualEnrollToWorkflow.prototype = {
  overlay: object.isRequired,
  form: object.isRequired,
  resetFields: func.isRequired,
  onSuccess: func.isRequired,
};

export default inject(stores => ({
  submitInProgress: stores.workflows.requestInProgress.bulkEnrollContactToWorkflow,
  error: stores.workflows.errors.bulkEnrollContactToWorkflow,
  overlay: stores.overlayStore.overlay,
  workflowsForManualEnrollment: stores.workflows.workflowsForManualEnrollment,
  fetchWorkflowsForManualEnrollment: stores.workflows.fetchWorkflowsForManualEnrollment,
  bulkEnrollContactToWorkflow: stores.workflows.bulkEnrollContactToWorkflow,
}))(observer(BulkManualEnrollToWorkflow));
