import React, { useState, useEffect } from 'react';
import { Button, Sidepanel, Select, Space, InfoBox } from 'tc-biq-design-system';

import { func, array, object, bool, string } from 'prop-types';
import Field, { fieldsResolver } from 'App/components/FieldRenderer';

import UpdateClientActionBlock
  from 'Marketing/components/Workflow/overlays/EditActionBlock/UpdateClientActionBlock/UpdateClientActionBlock';

import classnames from 'classnames';

import {
  blockTypeIconMap,
  blockDescriptionMap,
  blockActionDescriptionMap,
} from '../../libs/consts';

const propTypes = {
  block: object,
  hasErrors: bool,
  visible: bool,
  submitInProgress: bool,
  actionChoices: array,
  actions: array,
  actionFieldError: string,
  title: string,
  onSave: func.isRequired,
  onDiscard: func.isRequired,
  formData: object.isRequired,
  resetFieldsData: func.isRequired,
  updateFieldValue: func.isRequired,
  isOverview: bool,
};

const defaultProps = {
  block: null,
  hasErrors: false,
  visible: false,
  submitInProgress: false,
  actionChoices: [],
  actions: [],
  actionFieldError: null,
  title: '',
  isOverview: false,
};

const text = {
  DISCARD: 'Discard',
  SAVE: 'Save',
  SIDEPANEL_TITLE: 'Edit data',
  PLACEHOLDER: 'Select action...',
};

// eslint-disable-next-line react/prop-types
const customFooter = ({ hasErrors, execute, submitInProgress, close, isOverview }) => (
  <div className="fiq-edit-node__footer">
    <Button color="ghost" onClick={close}>
      {text.DISCARD}
    </Button>
    <Button disabled={hasErrors || isOverview} loading={submitInProgress} onClick={execute}>
      {text.SAVE}
    </Button>
  </div>
);

const getChannelValue = (actionId) => {
  if (actionId.includes('sms')) return 'SMS';
  if (actionId.includes('email')) return 'Email';
  if (actionId.includes('whatsapp')) return 'WhatsApp';
  return null;
};

const EditActionBlock = ({
  block,
  hasErrors,
  formData,
  resetFieldsData,
  updateFieldValue,
  visible,
  submitInProgress,
  actionChoices,
  actions,
  onSave,
  onDiscard,
  actionFieldError,
  title,
  isOverview,
}) => {
  const [hasFieldsPopulated, setFieldsPopulated] = useState(false);
  const [fields, setFields] = useState([]);
  const [selectedAction, setAction] = useState(null);
  const [isInitial, setInitial] = useState(true);
  const [isUpdateClientSelected, setUpdateClientSelected] = useState(false);

  useEffect(() => {
    const actionId = block?.actionID || block?.properties?.action_id;
    setUpdateClientSelected(actionId === 'update_client');
    const resolvedActionFields = fieldsResolver(
      actions.find(({ actionID }) => actionID === actionId)?.fields,
    );
    setInitial(true);
    setFields(resolvedActionFields);
  }, [block]);

  useEffect(() => {
    const blockData = isInitial ? block : formData;
    updateFieldValue('name', blockData.name);

    const actionId = formData?.action_id?.value || block?.actionID || block?.properties?.action_id;
    const action = actionChoices.find(({ value }) => value === actionId);

    setAction(action);
    updateFieldValue('action_id', action);

    if (!fields.length) return;

    fields.forEach(({ name }) => {
      updateFieldValue(name, blockData?.properties?.action_parameters?.[name] || null);
    });

    setFieldsPopulated(true);
    setInitial(false);
  }, [fields]);

  const selectAction = (value) => {
    if (value?.value === selectedAction?.value) return;
    resetFieldsData();
    setFields(fieldsResolver(actions.find(({ actionID }) => actionID === value?.value)?.fields));
    setAction(value);
    updateFieldValue('action_id', value);
  };

  return (
    <Sidepanel
      visible={visible}
      title={title}
      size="medium"
      className="fiq-edit-block-sidepanel"
      onCloseIconClick={onDiscard}
      avatar={() => <img src={blockTypeIconMap[block.type]} alt="email-block" />}
      footerRender={() => customFooter({
        execute: onSave,
        close: onDiscard,
        submitInProgress,
        hasErrors,
        isOverview,
      })
      }
    >
      <div className={classnames('fiq-edit-node')}>
        {block && (
          <InfoBox header={(selectedAction && selectedAction.display_name) || block.type}>
            {blockActionDescriptionMap[selectedAction?.value] || blockDescriptionMap[block.type]}
          </InfoBox>
        )}
        <Space size={32} />
        <div className="fiq-edit-node__content">
          <Field label="Name*" id="name" name="name" required formId="editBlock" disabled={isOverview} />
          <Select
            type="search"
            options={actionChoices}
            label="Action*"
            name="action"
            onChange={selectAction}
            value={selectedAction}
            placeholder={text.PLACEHOLDER}
            valueKey="value"
            labelKey="display_name"
            maxMenuHeight={150}
            hasError={!!actionFieldError}
            helpText={actionFieldError}
            disabled={isOverview}
          />
          <Space size={18} />
          {hasFieldsPopulated
          && fields.map(({ id, type, read_only, label, ...props }) => (
            <Field
              {...props}
              label={label}
              type={type}
              key={id}
              disabled={read_only || isOverview}
              formId="editBlock"
              filterParams={{ channel: getChannelValue(selectedAction?.value), is_active: true }}
            />
          ))}

          { isUpdateClientSelected && (
            <div>
              <UpdateClientActionBlock />
            </div>
          ) }
        </div>
      </div>
    </Sidepanel>
  );
};

EditActionBlock.propTypes = propTypes;
EditActionBlock.defaultProps = defaultProps;

export default EditActionBlock;
