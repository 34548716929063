import React from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, notifier } from 'tc-biq-design-system';
import { closeOverlay, openOverlay } from 'App/services/overlayService';

const MODAL_ID = 'CUSTOM_ACTION_CONFIRM_MODAL';

export const openCustomActionConfirmModal = title => openOverlay(MODAL_ID, { title });
export const closeCustomActionConfirmModal = () => closeOverlay(MODAL_ID);

const CustomActionConfirmModal = ({
  visible,
  parameters,
}) => {
  const title = parameters?.title;

  const onConfirm = () => {
    closeCustomActionConfirmModal();
    notifier.success('You have successfully executed custom action!');
  };

  return (
    <Modal
      title={title}
      icon="Info"
      visible={visible}
      confirmText="Confirm"
      onConfirm={onConfirm}
      onCancel={closeCustomActionConfirmModal}
    >
      <p>Are you sure you want to execute custom action?</p>
    </Modal>
  );
};

export default inject(stores => ({
  visible: stores.overlayStore.overlay[MODAL_ID],
  parameters: stores.overlayStore.overlay.parameters,
}))(observer(CustomActionConfirmModal));
