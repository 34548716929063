import React, { useEffect, useState } from 'react';
import { Sidepanel, Spinner } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { bool, object } from 'prop-types';
import classnames from 'classnames';

import { closeOverlay, openOverlay } from 'App/services/overlayService';
import { DateTimeCell } from 'App/components/gridCellRenderers';
import { fetchJourney } from 'Marketing/services/WorkflowService';
import { getBlockIcon } from 'Marketing/components/Workflow/libs/utils';
import { BLOCK_TYPE } from 'Marketing/components/Workflow/libs/consts';

import './JourneyPreviewSidepanel.scss';

const SIDEPANEL_ID = 'JOURNEY_PREVIEW';

export const openJourneyPreviewSidepanel = workflow => openOverlay(SIDEPANEL_ID, { workflow });
export const closeJourneyPreviewSidepanel = () => closeOverlay(SIDEPANEL_ID);

const generateJourney = (data) => {
  const journey = [
    {
      block: {
        type: BLOCK_TYPE.TRIGGER,
      },
    },
  ];

  (data.memberships || []).forEach((membership) => {
    journey.push(membership);
  });

  if (data.next_step) {
    journey.push({
      block: data.next_step,
      nextStep: true,
    });
  }

  return journey;
};

const text = {
  TITLE: 'Journey Steps',
  NEXT: 'Next',
};

const propTypes = {
  visible: bool,
  parameters: object,
};

const defaultProps = {
  visible: false,
  parameters: null,
};

const JourneyPreview = ({ visible, parameters }) => {
  const [journey, setJourney] = useState(null);
  const [fetchInProgress, setFetchInProgress] = useState(false);

  useEffect(() => {
    if (parameters?.workflow?.id) {
      (async () => {
        setFetchInProgress(true);
        const { data } = await fetchJourney(parameters.workflow.id);
        const journeySteps = generateJourney(data);
        setJourney(journeySteps);
        setFetchInProgress(false);
      })();
    }
  }, [parameters]);
  return (
    <Sidepanel
      visible={visible}
      hideFooter
      size="large"
      title={text.TITLE}
      onCloseIconClick={closeJourneyPreviewSidepanel}
      icon="Marketing"
    >
      {fetchInProgress && (
        <div className="fiq-journey-preview__spinner">
          <Spinner />
        </div>
      )}
      <div className="fiq-journey-preview__wrapper">
        {!fetchInProgress && journey && journey.map(({
          block: { name, type, properties }, created, nextStep }) => (
            <div className={classnames('fiq-journey-preview', { 'fiq-journey-preview--trigger': type === BLOCK_TYPE.TRIGGER })}>
              <span className="fiq-journey-preview__date">
                {created && <DateTimeCell value={created} />}
                {nextStep && text.NEXT}
              </span>
              <div className="fiq-journey-preview__block">
                <span className="fiq-journey-preview__icon"><img src={getBlockIcon(type, properties)} alt="" /></span>
                <span className="fiq-journey-preview__name">{name}</span>
              </div>
            </div>
        ))}
      </div>
    </Sidepanel>
  );
};

JourneyPreview.propTypes = propTypes;
JourneyPreview.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
  parameters: stores.overlayStore.overlay.parameters,
}))(observer(JourneyPreview));
