import React from 'react';
import { inject, observer } from 'mobx-react';
import { Sidepanel } from 'tc-biq-design-system';
import { object, bool } from 'prop-types';

import { closeOverlay, openOverlay } from 'App/services/overlayService';
import SidepanelCard from 'App/components/SidepanelCard';

// Card content
import SendTo from './cards/SendTo';
import Timeframe from './cards/Timeframe';
import Goal from './cards/Goal';
import RecurringInfo from './cards/RecurringInfo';

import './PreviewWorkflowDetails.scss';

const propTypes = {
  parameters: object,
  visible: bool,
};

const defaultProps = {
  parameters: {},
  visible: false,
};

const text = {
  TITLE: 'Workflow details',
  TIMEFRAME: 'Timeframe',
  SEND_TO: 'Send to',
  GOAL: 'Goal',
  FREQUENCY: 'Frequency',
};

const cards = [
  {
    title: text.SEND_TO,
    icon: 'Send',
    Component: SendTo,
    isVisible: () => true,
  },
  {
    title: text.GOAL,
    icon: 'Flag',
    Component: Goal,
    isVisible: () => true,
  },
  {
    title: text.FREQUENCY,
    icon: 'Pending',
    Component: RecurringInfo,
    isVisible: workflow => !!workflow.recurring_type,
  },
  {
    title: text.TIMEFRAME,
    icon: 'CalendarDate',
    Component: Timeframe,
    isVisible: () => true,
  },
];

const SIDEPANEL_ID = 'PREVIEW_WORKFLOW_DETAILS';

export const openWorkflowDetails = workflow => openOverlay(SIDEPANEL_ID, { workflow });
export const closeWorkflowDetails = () => closeOverlay(SIDEPANEL_ID);

const PreviewWorkflowDetails = inject(stores => ({
  parameters: stores.overlayStore.overlay.parameters,
  visible: stores.overlayStore.overlay[SIDEPANEL_ID],
}))(
  observer(({ parameters, visible }) => {
    if (!parameters.workflow || !visible) return null;
    return (
      <Sidepanel
        icon="View"
        title={text.TITLE}
        visible={visible}
        onCloseIconClick={() => closeOverlay(SIDEPANEL_ID)}
        hideFooter
      >
        <div>
          {cards
            .filter(card => card.isVisible(parameters.workflow))
            .map(card => (
              <SidepanelCard key={card.title} title={card.title} icon={card.icon}>
                <card.Component workflow={parameters.workflow} />
              </SidepanelCard>
            ))}
        </div>
      </Sidepanel>
    );
  }),
);

PreviewWorkflowDetails.wrappedComponent.propTypes = propTypes;
PreviewWorkflowDetails.wrappedComponent.defaultProps = defaultProps;

export default PreviewWorkflowDetails;
