import actionTypes from 'Contacts/components/ContactActions/actions/types';
import { CONTACT_STATUS } from 'App/enums/contactStatus';
import { hasAccess } from 'App/services/permissionsService';
import TradingAccountForm
  from 'Contacts/components/ContactActions/TradingAccountForm/TradingAccountForm';

const text = {
  LABEL: 'New trading account',
};

const ACTION_ICON = 'Account';

export default {
  name: 'newTradingAccount',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: actionTypes.TRADING_ACCOUNTS,
  hasPermission: status => ([
    CONTACT_STATUS.FULL,
    CONTACT_STATUS.LIMITED,
    CONTACT_STATUS.REVIEW,
    CONTACT_STATUS.DEMO,
  ].includes(status) && hasAccess('contacts_contact', 'update')),
  handler: (sidepanelManager) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      title: text.LABEL,
      type: 'info',
      sidepanelManager,
      visible: true,
      isNew: true,
      key: 'new-trading-account',
    };

    sidepanelManager.add(TradingAccountForm, sidepanelOptions);
  },
};
