import { observable, action, runInAction, makeObservable } from 'mobx';

import {
  fetchTrackingLinkOptions,
  fetchTrackingLink,
} from 'Marketing/services/TrackingLinksService';
import stores from 'App/rootStore';
import extractDisplayName from 'App/services/utilities/extractDisplayName';
import { handleErrorResponse } from 'App/services/utilities/error.utils';

export default class TrackingLinksStore {
  trackingLink = {};

  fieldsDef = {};

  trackingLinksFields = {};

  requestInProgress = {
    fetchCampaign: false,
    fetchFields: false,
  };

  errors = {};

  constructor() {
    makeObservable(this, {
      trackingLink: observable,
      fieldsDef: observable,
      trackingLinksFields: observable,
      requestInProgress: observable,
      errors: observable,
      fetchTrackingLinkFields: action.bound,
      resetTrackingLinksFields: action.bound,
      setTrackingLinkData: action.bound,
    });
  }

  async fetchTrackingLinkFields() {
    const response = await fetchTrackingLinkOptions();
    const fieldsOptions = response.data.actions.POST;
    runInAction(() => {
      this.trackingLinksFields = fieldsOptions;
    });
  }

  resetTrackingLinksFields() {
    const { resetFieldsData } = stores.forms.ruleForm;
    resetFieldsData();
    this.trackingLinksFields = {};
  }

  async setTrackingLinkData(trackingLinkId, isClone) {
    runInAction(() => {
      this.requestInProgress.fetchFields = true;
    });
    try {
      const { data } = await fetchTrackingLink(trackingLinkId);

      runInAction(() => {
        this.trackingLink = data;
      });

      const formatedAudience = data.audience
        ? {
          ...data.audience,
          display_name: extractDisplayName(data.audience),
          value: data.audience.id,
        }
        : null;

      stores.forms.createEditTrackingLink.setFieldsData({
        ...data,
        ...(isClone && { name: `Copy of ${data.name}` }),
        audience: formatedAudience,
      });
    } catch (err) {
      runInAction(() => {
        this.errors = err;
      });
      handleErrorResponse(err);
    } finally {
      runInAction(() => {
        this.requestInProgress.fetchFields = false;
      });
    }
  }
}
