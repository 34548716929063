import SidepanelFooter from 'App/components/SidepanelFooter';
import { bool, func } from 'prop-types';
import React from 'react';

const text = {
  BUTTON_LABELS: {
    confirm: 'Confirm',
    cancel: 'Cancel',
  },
};

const RenderFooter = ({ confirm, cancel, requestInProgress }) => (
  <SidepanelFooter
    submitInProgress={requestInProgress}
    execute={confirm}
    close={cancel}
    cancelColor="ghost"
    confirmColor="primary"
    buttonLabels={text.BUTTON_LABELS}
  />
);

RenderFooter.propTypes = {
  confirm: func.isRequired,
  cancel: func.isRequired,
  requestInProgress: bool.isRequired,
};

export default RenderFooter;
