import React from 'react';
import { object } from 'prop-types';
import moment from 'moment';

import './RecurringInfo.scss';

const propTypes = {
  workflow: object,
};

const defaultProps = {
  workflow: {},
};
const text = {
  INTERVAL: 'Interval',
  DAYS: 'Days',
  TIME: 'Time',
};

const DAYS_MAPPING = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

const RecurringInfo = ({ workflow }) => (
  <div className="recurring-info">
    <div className="info">
      <p className="text-neutral-500">{text.INTERVAL}</p>
      <p>{workflow.recurring_type}</p>
    </div>
    {workflow.recurring_type_value && (
      <div className="info">
        <p className="text-neutral-500">{text.DAYS}</p>
        <div>{workflow.recurring_type_value.map(day => DAYS_MAPPING[day]).join(',')}</div>
      </div>
    )}
    <div className="info">
      <p className="text-neutral-500">{text.TIME}</p>
      <p>
        {moment.utc(workflow.recurring_time, 'HH:mm').local().format('HH:mm')}
      </p>
    </div>
  </div>
);

RecurringInfo.propTypes = propTypes;
RecurringInfo.defaultProps = defaultProps;

export default RecurringInfo;
