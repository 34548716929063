import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { object, func, bool, number, string } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { ComboxProvider, SidepanelProvider } from 'tc-biq-design-system';

import { hasAccess } from 'App/services/permissionsService';
import Navigation from 'App/components/Navigation';
import { ContactHeader, BasicInfo, sections } from 'Contacts/components';
import If from 'App/components/If';
import withErrorBoundary from 'App/components/hoc/withErrorBoundary';

import './ContactProfilePage.scss';
import LoadingPage from 'App/components/LoadingPage';
import SegmentsSidepanel from 'Contacts/components/overlays/Segments/SegmentsSidepanel';
import WorkflowsSidepanel from 'Contacts/components/overlays/Workflows/WorkflowsSidepanel';
import PreviewTrackingLink from 'Settings/Sections/TrackingLinks/components/overlays/PreviewTrackingLink';

const propTypes = {
  fetchContactData: func.isRequired,
  match: object.isRequired,
  history: object.isRequired,
  requestInProgress: bool.isRequired,
  contact: object.isRequired,
  contactId: number,
  resetContactData: func.isRequired,
  external_id: string,
};

const text = {
  CONTACT: 'Contact',
};

const defaultProps = {
  contactId: null,
  external_id: null,
};

class ContactProfile extends PureComponent {
  constructor(props) {
    super(props);
    this.id = props.match.params.contactId;
    this.sections = sections.filter(section => hasAccess(section.permission, 'read'));
    this.state = {
      display: null,
      id: props.match.params.contactId,
    };
  }

  componentDidMount() {
    this.fetchContactProfileData();
    this.setInitialSection();
    document.title = `${text.CONTACT} $`;
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.sectionId !== match.params.sectionId) this.updateSection();
    if (prevProps.match.params.contactId !== match.params.contactId) this.updateId();
  }

  componentWillUnmount() {
    const { resetContactData } = this.props;
    resetContactData();
  }

  setInitialSection = () => {
    const { match } = this.props;
    const { sectionId } = match.params;
    const section = this.sections[0] ? this.sections[0].id : null;
    this.setState({ display: sectionId || section });
  };

  updateId = () => {
    const { match } = this.props;
    this.setState({ id: match.params.contactId }, () => this.fetchContactProfileData());
  };

  updateSection = () => {
    const { match } = this.props;
    this.setState({ display: match.params.sectionId });
  };

  fetchContactProfileData = async () => {
    const { fetchContactData } = this.props;
    const { id } = this.state;
    await fetchContactData(id);
  };

  onSectionChange = (section) => {
    const { history } = this.props;
    const { id } = this.state;
    history.push(`/contacts/${id}/${section}`);
    this.setState({ display: section });
  };

  getSectionComponent = () => {
    const { display } = this.state;
    if (isEmpty(sections) || !display) return null;
    const { Component } = find(sections, { id: display });
    return Component();
  };

  render() {
    const SectionComponent = this.getSectionComponent();
    const { display } = this.state;
    const { requestInProgress, contactId, external_id, contact } = this.props;

    if (requestInProgress) return <LoadingPage />;
    return (
      <ComboxProvider>
        <SidepanelProvider>
          <div className="fiq-profile" key={this.id}>
            <ContactHeader />
            <BasicInfo />
            <If condition={!requestInProgress && this.sections.length > 0}>
              <div className="fiq-profile__sections">
                <div className="nav">
                  <Navigation
                    sections={this.sections}
                    onSectionChange={this.onSectionChange}
                    active={display}
                  />
                </div>

                <div className="section">
                  {SectionComponent
                    && (
                    <SectionComponent
                      id={contactId}
                      external_id={external_id}
                      contact={contact}
                    />
                    )
                  }
                </div>
              </div>
            </If>
          </div>
          <SegmentsSidepanel />
          <WorkflowsSidepanel />
          <PreviewTrackingLink />
        </SidepanelProvider>
      </ComboxProvider>
    );
  }
}

ContactProfile.propTypes = propTypes;
ContactProfile.defaultProps = defaultProps;

export default withRouter(withErrorBoundary(inject(stores => ({
  contact: stores.contact.profile.contactData,
  contactId: stores.contact.profile.contactData.id,
  external_id: stores.contact.profile.contactData.external_id,
  fetchContactData: stores.contact.profile.fetchContactData,
  requestInProgress: stores.contact.profile.requestInProgress.contactData,
  resetContactData: stores.contact.profile.resetContactData,
}))(observer(ContactProfile))));
