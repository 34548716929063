import React, { Fragment, useState, useEffect } from 'react';
import { object } from 'prop-types';
import { inject, observer } from 'mobx-react';

import Field, { fieldsResolver } from 'App/components/FieldRenderer';
import channelEnum from 'App/enums/channelEnum';
import MessageBody from '../MessageBody/MessageBody';
import AddTemplate from './AddTemplate';

const propTypes = {
  actionFields: object.isRequired,
};

const MESSAGE_BODY = 'message_body';

const fieldsToOmit = ['template', 'message_body', 'subject'];

const getChannelValue = (value) => {
  if (value === 'send_sms_to_contact') return channelEnum.SMS;
  if (value === 'send_whatsapp_message_to_contact') return channelEnum.WHATSAPP;
  return channelEnum.EMAIL;
};

const ActionField = inject(stores => ({
  updateAction: stores.customActions.updateActionForm,
}))(
  observer(({ updateAction, field, actionIndex, actionId, value }) => {
    const [val, setVal] = useState(value);
    const onChange = (key, fieldValue) => {
      setVal(fieldValue);
      updateAction({ actionIndex, key: field.id, value: fieldValue });
    };

    useEffect(() => setVal(value), [actionId]);

    if (field.name === MESSAGE_BODY) {
      return (
        <MessageBody field={field} onChange={onChange} value={val} />
      );
    }

    return <Field {...field} onChange={onChange} value={val} />;
  }),
);

const RenderActionFields = inject(stores => ({
  updateAction: stores.customActions.updateActionForm,
  triggerEvent: stores.forms.ruleForm.data.trigger_event,
  actions: stores.customActions.actions,
}))(
  observer(({
    actions,
    updateAction,
    triggerEvent,
    actionFields,
    actionIndex,
    actionId,
    formData,
  }) => {
    const templateId = actions[actionIndex]?.actionForm?.template;
    const eventId = triggerEvent?.id;

    const fields = fieldsResolver(actionFields) || [];
    const showTemplateField = fields.some(field => field.name === 'template');
    const filteredFields = fields.filter(item => (templateId ? !fieldsToOmit.includes(item.id) : item.id !== 'template'));
    const channel = getChannelValue(actionId.value);

    const onSave = (value) => {
      updateAction({ actionIndex, key: 'template', value });
    };

    return (
      <Fragment>
        {showTemplateField
        && (
        <AddTemplate
          templateId={templateId}
          onSave={onSave}
          eventId={eventId}
          channel={channel}
        />
        )}
        {filteredFields.map(field => (
          <Fragment key={field.id}>
            <ActionField
              field={field}
              actionIndex={actionIndex}
              actionId={actionId}
              value={formData[field.id]}
            />
          </Fragment>
        ))}
      </Fragment>
    );
  }),
);

RenderActionFields.propTypes = propTypes;

export default RenderActionFields;
