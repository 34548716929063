import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { object, func } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { CardList, Col, Row, Space } from 'tc-biq-design-system';
import isEmpty from 'lodash/isEmpty';

import Page from 'App/components/Page';
import Loader from 'App/components/Loader';
import { TradeDetails } from 'Transactions/components';
import DetailsCardWrapper from 'App/components/DetailsCardWrapper';
import CodeAccordion from 'App/components/CodeAccordion';

import './TradeSinglePage.scss';
import moment from 'moment';
import { SideCell, StatusCell } from 'App/components/gridCellRenderers';
import { formatKey } from 'App/helpers/format';
import CopyTradesTable from 'Transactions/components/CopyTradesTable';

const propTypes = {
  match: object.isRequired,
  fetchTradeData: func.isRequired,
  tradeData: object.isRequired,
};

const bread = id => [
  { label: 'Transactions' },
  { label: 'Trades', route: '/transactions/trades' },
  { label: id, route: `/transactions/trades/${id}` },
];

const text = {
  TRADE: 'Trade',
};

const renderValue = (key, value) => {
  const date = moment(value, moment.ISO_8601);
  const dateFormat = 'YYYY-MM-DD HH:mm';
  if ((key === 'open_date' || key === 'close_date') && date.isValid()) return date.format(dateFormat);
  if (key === 'side' && value) return <SideCell value={value} />;
  if (key === 'state' && value) return <StatusCell value={value} />;
  if (key === 'pnl') {
    return value < 0 ? (
      <div className="text-status04-500">{value}</div>
    ) : (
      <div className="text-status01-500">{value}</div>
    );
  }
  if (key === 'trading_platform') return formatKey(value);
  if (key === 'wallet') return value.id;
  if (key === 'contact') return `${value.first_name} ${value.last_name}`;
  return value ? value.toString() : '';
};

const generateFields = tradeData => Object.keys(tradeData).map(key => ({
  name: formatKey(key),
  value: renderValue(key, tradeData[key]),
}));

class TradeSinglePage extends PureComponent {
  constructor(props) {
    super(props);
    this.id = props.match.params.tradeId;
  }

  componentDidMount() {
    const { fetchTradeData } = this.props;
    fetchTradeData(this.id);
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.tradeId !== match.params.tradeId) {
      const { fetchTradeData } = this.props;
      fetchTradeData(match.params.tradeId);
    }
  }

  render() {
    const { tradeData } = this.props;
    if (isEmpty(tradeData)) return <Loader visible />;
    const { contact, side, metadata, wallet } = tradeData;
    let data = generateFields(tradeData);
    data = data.filter(e => e.name !== 'Metadata');

    return (
      <Page
        bread={bread(this.id)}
        title={`${text.TRADE} ${tradeData.symbol} ${tradeData.external_id || ''}`}
        style={{ marginTop: '24px', overflow: 'auto' }}
      >
        <div className="fiq-trade-single">
          <Col sm="100%" lg="70%">
            <DetailsCardWrapper contact={contact} wallet={wallet} side={side} title={text.TRADE}>
              <TradeDetails />
            </DetailsCardWrapper>
            <Space size={12} />
            <CodeAccordion data={metadata} toggled />
            <Space size={12} />
            <CopyTradesTable id={tradeData.id} />
          </Col>
          <Col sm="100%" lg="30%">
            <CardList title="Trade details" items={data} />
          </Col>
        </div>
      </Page>
    );
  }
}

TradeSinglePage.propTypes = propTypes;

export default withRouter(inject(stores => ({
  fetchTradeData: stores.transactions.fetchTradeData,
  tradeData: stores.transactions.tradeData,
}))(observer(TradeSinglePage)));
