import React from 'react';
import Field from 'App/components/FieldRenderer';
import { isEmpty } from 'lodash';
import { object, func, bool, string } from 'prop-types';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { hasEnding } from 'Marketing/store/WorkflowsStore';

import { Space, Toggle } from 'tc-biq-design-system';

const propTypes = {
  fieldsProps: object.isRequired,
  sendToType: string,
  recurring_type: object,
  start_datetime: object,
  toggleEndingWorkflow: func.isRequired,
  isRecurringWorkflow: bool.isRequired,
  isEndingWorkflow: bool.isRequired,
  updateFieldValue: func.isRequired,
};

const defaultProps = {
  sendToType: null,
  recurring_type: null,
  start_datetime: null,
};

const text = {
  TOGGLE_DESCRIPTION: 'Should workflow end at a certain date?',
  END_DATE: 'End date',
};

const TimeFrameForm = ({
  fieldsProps,
  isRecurringWorkflow,
  sendToType,
  isEndingWorkflow,
  toggleEndingWorkflow,
  updateFieldValue,
  start_datetime,
}) => {
  const withEnding = hasEnding(sendToType, isRecurringWorkflow);
  // NOTE: Here we provide the default value for the start datetime on the
  // form.
  if (isEmpty(start_datetime)) {
    updateFieldValue('start_datetime', moment().format('YYYY-MM-DD HH:mm'));
  }
  return (
    <div>
      <div style={{ width: '45%' }}>
        <Field
          {...fieldsProps.start_datetime}
          type={isRecurringWorkflow ? 'date' : 'datetime'}
          formId="workflowForm"
          key="start_datetime"
        />
      </div>
      {withEnding && (
        <div>
          <Space size={2} />
          <div className="fiq-workflow-form__description-wrapper">
            <Toggle checked={isEndingWorkflow} onChange={toggleEndingWorkflow} />
            <Space size={12} />
            <span className="tc-paragraph-regular">{text.TOGGLE_DESCRIPTION}</span>
          </div>
        </div>
      )}
      {withEnding && isEndingWorkflow && (
        <div style={{ width: '45%' }}>
          <Space size={20} />
          <Field
            {...fieldsProps.end_datetime}
            type="date"
            label={text.END_DATE}
            formId="workflowForm"
          />
        </div>
      )}
    </div>
  );
};

TimeFrameForm.propTypes = propTypes;
TimeFrameForm.defaultProps = defaultProps;

export default inject(stores => ({
  recurring_type: stores.forms.workflowForm.data.recurring_type,
  start_datetime: stores.forms.workflowForm.data.start_datetime,
  toggleEndingWorkflow: stores.workflows.toggleEndingWorkflow,
  isEndingWorkflow: stores.workflows.isEndingWorkflow,
  isRecurringWorkflow: stores.workflows.isRecurringEnabled,
  updateFieldValue: stores.forms.workflowForm.updateFieldValue,
}))(observer(TimeFrameForm));
