import { bool, func, number, object } from 'prop-types';
import React from 'react';
import { Sidepanel } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import RenderFooter
  from '../components/SidepanelRenderFooter';

/**
 *  BIQ Action Component
 */

const propTypes = {
  contactId: number.isRequired,
  sidepanelManager: object.isRequired,
  requestInProgress: bool.isRequired,
  archive: func.isRequired,
  fetchContactData: func.isRequired,
  errors: object.isRequired,
};

const text = {
  PROMPT: 'Are you sure you want to archive this contact?',
};

const Archive = ({
  contactId,
  sidepanelManager,
  archive,
  fetchContactData,
  requestInProgress,
  errors,
  ...options
}) => {
  const submit = async () => {
    await archive();
    if (!errors.archive) {
      sidepanelManager.close();
      fetchContactData(contactId);
    }
  };

  const cancel = () => sidepanelManager.close();

  return (
    <Sidepanel
      {...options}
      footerRender={() => (
        <RenderFooter
          confirm={submit}
          cancel={cancel}
          requestInProgress={requestInProgress}
        />
      )}
    >
      {text.PROMPT}
    </Sidepanel>
  );
};

Archive.propTypes = propTypes;

export default inject(stores => ({
  contactId: stores.contact.profile.contactData.id,
  errors: stores.contact.profile.errors,
  archive: stores.contact.profile.archive,
  fetchContactData: stores.contact.profile.fetchContactData,
  requestInProgress: stores.contact.profile.requestInProgress.archive,
}))(observer(Archive));
