import React from 'react';
import { inject, observer } from 'mobx-react';
import { object } from 'prop-types';
import RuleList from 'App/components/RuleList';
import { RouteCell } from 'App/components/gridCellRenderers';
import { Space } from 'tc-biq-design-system';

import './SendTo.scss';

const propTypes = {
  workflow: object,
  formattedFields: object,
};

const defaultProps = {
  workflow: {},
  formattedFields: null,
};

const text = {
  TYPE: 'Type',
  SEGMENT: 'Segment',
};

const SegmentNameCell = ({ data, value }) => RouteCell('/automation/segments/preview/')({ hrefValue: data.id, value });

const SendTo = ({ workflow, formattedFields }) => (
  <div className="send-to">
    <div className="send-to__head">
      <div className="info">
        <p className="text-neutral-500">{text.TYPE}</p>
        <p>{workflow.send_to_type}</p>
      </div>
      {workflow.send_to_segment && (
        <div className="info">
          <p className="text-neutral-500">{text.SEGMENT}</p>
          <SegmentNameCell value={workflow.send_to_segment.name} data={workflow.send_to_segment} />
        </div>
      )}
    </div>
    {workflow.send_to_query && (
      <div>
        <Space size={16} />
        <RuleList item={workflow.send_to_query} formattedFields={formattedFields} />
      </div>
    )}
  </div>
);

SendTo.propTypes = propTypes;
SendTo.defaultProps = defaultProps;

export default inject(stores => ({
  formattedFields: stores.workflows.sendToQueryBuilder.fields,
}))(observer(SendTo));
