import React, { useState } from 'react';
import { Accordion, AreaChart } from 'tc-biq-design-system';
import { array } from 'prop-types';

import Loader from 'App/components/Loader';

const text = {
  ACCORDION_TITLE: 'Conversions',
  EMPTY_MESSAGE: 'Conversions will be shown once recipients complete workflow goal.',
};

const propTypes = {
  data: array,
};

const defaultProps = {
  data: null,
};

const WorkflowChart = ({ data }) => {
  const [visible, toggleVisibility] = useState(true);
  if (!data) return <Loader visible />;
  return (
    <div style={{ padding: '20px' }}>
      <Accordion
        title={text.ACCORDION_TITLE}
        visible={visible}
        onClick={() => toggleVisibility(!visible)}
      >
        <AreaChart
          data={data}
          dataKeyX="date"
          dataKeyArea="count"
          aspect={4}
          emptyMessage={text.EMPTY_MESSAGE}
          stroke="#006BE6"
          fill="#006BE6"
          height={190}
          tooltipTitle="Conversion"
        />
      </Accordion>
    </div>
  );
};

WorkflowChart.propTypes = propTypes;
WorkflowChart.defaultProps = defaultProps;

export default WorkflowChart;
