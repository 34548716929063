import { CONTACT_STATUS_LABEL } from 'App/enums/contactStatusLabel';
import { CONTACT_STATUS } from 'App/enums/contactStatus';


export function contactStatusFilter(state, reverse) {
  const searchObject = reverse ? CONTACT_STATUS_LABEL : CONTACT_STATUS;
  const resultObject = reverse ? CONTACT_STATUS : CONTACT_STATUS_LABEL;

  for (const key in searchObject) {
    if (searchObject.hasOwnProperty(key) && resultObject.hasOwnProperty(key) && searchObject[key] === state) {
      return resultObject[key];
    }
  }

  return '';
}
