import { bool, func, number, object } from 'prop-types';
import React from 'react';
import { Sidepanel } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import RenderFooter
  from '../components/SidepanelRenderFooter';

/**
 *  BIQ Action Component
 */

const propTypes = {
  contactId: number.isRequired,
  sidepanelManager: object.isRequired,
  requestInProgress: bool.isRequired,
  addToExclusionList: func.isRequired,
  fetchContactData: func.isRequired,
  errors: object.isRequired,
};

const text = {
  PROMPT: 'Are you sure you want to add this contact to exclusion list?',
};

const AddToExclusionList = ({
  contactId,
  sidepanelManager,
  addToExclusionList,
  fetchContactData,
  requestInProgress,
  errors,
  ...options
}) => {
  const submit = async () => {
    await addToExclusionList();
    if (!errors.addToExclusionList) {
      sidepanelManager.close();
      fetchContactData(contactId);
    }
  };

  const cancel = () => sidepanelManager.close();

  return (
    <Sidepanel
      {...options}
      footerRender={() => (
        <RenderFooter
          confirm={submit}
          cancel={cancel}
          requestInProgress={requestInProgress}
        />
      )}
    >
      {text.PROMPT}
    </Sidepanel>
  );
};

AddToExclusionList.propTypes = propTypes;

export default inject(stores => ({
  contactId: stores.contact.profile.contactData.id,
  errors: stores.contact.profile.errors,
  addToExclusionList: stores.contact.profile.addToExclusionList,
  fetchContactData: stores.contact.profile.fetchContactData,
  requestInProgress: stores.contact.profile.requestInProgress.addToExclusionList,
}))(observer(AddToExclusionList));
