import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';
import { Col, Space, notifier } from 'tc-biq-design-system';

import Page from 'App/components/Page';
import Loader from 'App/components/Loader';
import DetailsCardWrapper from 'App/components/DetailsCardWrapper';
import CodeAccordion from 'App/components/CodeAccordion';
import { fetchSmsLog } from 'Communications/services/CommunicationsService';
import { MessangerLogDetails } from 'Communications/components';
import WithErrorBoundary from 'App/components/hoc/withErrorBoundary';

import './SmsLogSinglePage.scss';
import { handleErrorResponse } from 'App/services/utilities/error.utils';

const propTypes = {
  match: object.isRequired,
};

const bread = id => [
  { label: 'Communications' },
  { label: 'Sms Logs', route: '/communications/sms-logs' },
  { label: id, route: `/communications/sms-logs/${id}` },
];

const text = {
  SMS_LOG: 'SMS Log',
  MESSAGE: 'Message',
};

class SmsLogSinglePage extends PureComponent {
  constructor(props) {
    super(props);
    this.id = props.match.params.smsLogId;
    this.state = {
      data: null,
      requestInProgress: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ requestInProgress: true });
    try {
      const response = await fetchSmsLog(this.id);
      this.setState({ data: response.data, requestInProgress: false });
    } catch (err) {
      handleErrorResponse(err, 'Failed to fetch SMS log data');
      this.setState({ requestInProgress: false });
    }
  };

  render() {
    const { data, requestInProgress } = this.state;
    if (requestInProgress) return <Loader visible />;
    const { contact, status, message_body, id } = data;
    return (
      <Page
        bread={bread(this.id)}
        title={`${text.SMS_LOG} ${id}`}
        style={{ marginTop: '24px', overflow: 'auto' }}
      >
        <div className="fiq-sms-log-single">
          <Col sm="100%" lg="70%">
            <DetailsCardWrapper contact={contact} status={status} title={text.SMS_LOG}>
              <MessangerLogDetails {...data} />
            </DetailsCardWrapper>
            <Space size={12} />
            <CodeAccordion title={text.MESSAGE} data={message_body} toggled />
          </Col>
        </div>
      </Page>
    );
  }
}

SmsLogSinglePage.propTypes = propTypes;

export default WithErrorBoundary(withRouter(SmsLogSinglePage));
