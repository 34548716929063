/* eslint-disable react/prop-types */
import React from 'react';
import { Pill, Detail } from 'tc-biq-design-system';
import './RuleList.scss';

const text = {
  MATCH_ALL: 'Match conditions',
};

const attributeMessageFormatter = (formattedFields, rule) => {
  if (!formattedFields) {
    return null;
  } if (!(rule.field in formattedFields)) {
    return `${rule.field} ${rule.operator} ${rule.value}`;
  }
  return `${formattedFields[rule.field].label} ${
    formattedFields[rule.field].lookups[rule.operator]
  } ${rule.value}`;
};

const RuleComponent = ({ rule, condition, formattedFields }) => {
  if (rule.field) {
    return (
      <div className="rule-list__rule-item__item">
        <CircleComponent color="green" />
        <Pill type="neutral">{condition}</Pill>
        <Detail
          entity="Attribute"
          value={attributeMessageFormatter(formattedFields, rule)}
          reverse
        />
      </div>
    );
  }
  return <RuleList item={rule} formattedFields={formattedFields} />;
};

const RuleList = ({ item, formattedFields }) => (
  <div className="rule-list__rule-item">
    {!!item && <HeaderComponent />}
    {item?.rules.map(rule => (
      <RuleComponent rule={rule} condition={item.condition} formattedFields={formattedFields} />
    ))}
  </div>
);

const HeaderComponent = () => (
  <div className="rule-list__conditions-header text-neutral-500">{text.MATCH_ALL}</div>
);

const CircleComponent = ({ color }) => <div className={`rule-list__circle ${color}`} />;

export default RuleList;
