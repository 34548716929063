import React from 'react';
import { inject, observer } from 'mobx-react';
import { func, array, object } from 'prop-types';
import { Checkbox } from 'tc-biq-design-system';

const propTypes = {
  filteredData: array.isRequired,
  allPermissions: object.isRequired,
  updateAllEndpoints: func.isRequired,
};

const checkBoxArray = [
  { text: 'Read All', key: 'read' },
  { text: 'Create All', key: 'create' },
  { text: 'Update All', key: 'update' },
  { text: 'Delete All', key: 'delete' },
  { text: 'Export All', key: 'export' },
];

const SelectAllPermissions = ({ filteredData, allPermissions, updateAllEndpoints }) => {
  const allNames = filteredData.map(data => data.name);
  return (
    <div className="fiq-roles-single__select-all-permissions">
      {checkBoxArray.map(checkbox => (
        <div className="checkbox-wrapper" key={`${checkbox.text}-${checkbox.key}`}>
          <Checkbox
            onChange={e => updateAllEndpoints(allNames, checkbox.key, e.target.checked)}
            checked={allPermissions[checkbox.key]}
          >
            <span className="tc-paragraph-strong">{checkbox.text}</span>
          </Checkbox>
        </div>
      ))}
    </div>
  );
};

SelectAllPermissions.propTypes = propTypes;

export default inject(stores => ({
  filteredData: stores.roles.filteredData,
  allPermissions: stores.roles.allPermissions,
  updateAllEndpoints: stores.roles.updateAllEndpoints,
}))(observer(SelectAllPermissions));
