import React, { Fragment } from 'react';
import { notifier } from 'tc-biq-design-system';

import GridComponentFactory from 'App/components/grid';
import { hasAccess } from 'App/services/permissionsService';
import {
  startWorkflow,
  endWorkflow,
  deleteWorkflow,
  cloneWorkflow,
} from 'Marketing/services/WorkflowService';
import PreviewWorkflow from 'Marketing/components/overlays/PreviewWorkflow';
import run from 'App/services/utilities/run';
import displayError from 'App/services/utilities/displayError';
import modifiers from './modifiers';

const tableConfig = {
  tableKey: 'workflows',
  tableUrl: '/marketing/workflows/',
};

const text = {
  EDIT: 'Edit',
  CLONE: 'Clone',
  START: 'Start',
  END: 'End workflow',
  DELETE: 'Delete workflow',
  PREVIEW: 'Preview',
  START_SUCCESS: 'Successfully started workflow',
  START_FAILED: 'Failed to start workflow',
  END_SUCCESS: 'Successfully ended workflow',
  END_FAILED: 'Failed to end workflow',
  DELETE_SUCCESS: 'Successfully deleted workflow',
  DELETE_FAILED: 'Failed to delete workflow',
  CLONE_SUCCESS: 'Successfully cloned workflow',
  CLONE_FAILED: 'Failed to clone workflow',
  REQUIRED_FIELDS_MISSING_ERROR: ', required fields are missing',
};

const requiredFieldsError = 'This field is required.';

const listToOmit = ['goal_query',
  'goal_attribution_window',
  'send_to_type',
  'send_to_query',
  'template',
  'send_to_segment',
  'email_message',
  'messanger_message',
  'duration',
  'description',
  'created',
  'updated',
  'recurring_type_value',
];

const customColumns = [
  {
    key: 'actions',
    headerName: '',
  },
];

const singleActions = actions => (data, history) => [
  {
    data: data.id,
    label: text.EDIT,
    action: id => history.push(`/marketing/workflows/${id}`),
    icon: 'Edit',
    visibility: data.status === 'Draft' && hasAccess('marketing_workflow', 'update'),
  },
  {
    data: data.id,
    label: text.CLONE,
    action: workflowId => actions.clone({ history, workflowId }),
    icon: 'Duplicate',
    visibility: hasAccess('marketing_workflow', 'create'),
  },
  {
    data: data.id,
    label: text.START,
    action: workflowId => actions.start(workflowId),
    icon: 'Play',
    visibility: data.status === 'Draft' && hasAccess('marketing_workflow', 'update'),
  },
  {
    data: data.id,
    label: text.END,
    action: workflowId => actions.end(workflowId),
    icon: 'Close',
    visibility: data.status === 'Ongoing' && hasAccess('marketing_workflow', 'update'),
  },
  {
    data: data.id,
    label: text.DELETE,
    action: workflowId => actions.remove(workflowId),
    icon: 'Delete',
    visibility:
        ['Draft', 'Scheduled'].includes(data.status) && hasAccess('marketing_workflow', 'delete'),
  },
].filter(action => action.visibility);

const { GridComponent, gridActions } = GridComponentFactory(tableConfig);

const WorkflowsTable = () => {
  const start = async (id) => {
    const [err] = await run(startWorkflow(id));
    if (err) {
      displayError(err.response.data);
      if (
        // eslint-disable-next-line
        Object.keys(err.response.data).find(key => err.response.data[key].includes(requiredFieldsError))
      ) {
        return notifier.error(text.START_FAILED + text.REQUIRED_FIELDS_MISSING_ERROR);
      }
      return notifier.error(text.START_FAILED);
    }
    gridActions.fetchTableData();
    return notifier.success(text.START_SUCCESS);
  };

  const end = async (id) => {
    try {
      await endWorkflow(id);
      gridActions.fetchTableData();
      notifier.success(text.END_SUCCESS);
    } catch {
      notifier.error(text.END_FAILED);
    }
  };

  const remove = async (id) => {
    try {
      await deleteWorkflow(id);
      gridActions.fetchTableData();
      notifier.success(text.DELETE_SUCCESS);
    } catch {
      notifier.error(text.DELETE_FAILED);
    }
  };

  const clone = async ({ history, workflowId }) => {
    const [err, data] = await run(cloneWorkflow(workflowId));
    if (err) {
      displayError(err.response.data);
      return notifier.error(text.CLONE_FAILED);
    }
    history.push(`/marketing/workflows/${data.id}`);
    return notifier.success(text.CLONE_SUCCESS);
  };

  return (
    <Fragment>
      <GridComponent
        modifiers={modifiers(singleActions({ start, end, remove, clone }))}
        customColumns={customColumns}
        listToOmit={listToOmit}
      />
      <PreviewWorkflow />
    </Fragment>
  );
};

export default WorkflowsTable;
