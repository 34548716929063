import React from 'react';
import { Icon, Input, Select, Space } from 'tc-biq-design-system';

import './AttributeCard.scss';

const attributeOptions = [
  {
    value: 'option1',
    display_name: 'Option 1',
  },
  {
    value: 'option2',
    display_name: 'Option 2',
  },
  {
    value: 'option3',
    display_name: 'Option 3',
  },
  {
    value: 'option4',
    display_name: 'Option 4',
  },
  {
    value: 'option5',
    display_name: 'Option 5',
  },
];

const AttributeCard = ({
  attribute,
  attributeIndex,
  updateAttributeId,
  updateAttributeValue,
  removeAttribute,
}) => {
  const selectedId = attribute.attributeId;

  const handleSelectedId = (data) => {
    updateAttributeId(attribute.id, data ?? '');
  };
  const handleSelectedValue = (data) => {
    updateAttributeValue(attribute.id, data.target.value);
  };

  return (
    <div className="attribute-card">
      <div className="attribute-card__header">
        <span className="action-count tc-paragraph-strong text-primary-500">
          {+attributeIndex + 1}
        </span>
        <Space size={8} />
        <div className="tc-paragraph-strong title">Attribute</div>
        <Icon style={{ cursor: 'pointer' }} size="small" name="Delete" onClick={() => removeAttribute(attribute.id)} />
      </div>

      <div className="attribute-card__body">
        <Select
          type="search"
          label="Attribute"
          options={attributeOptions}
          name="attributeId"
          onChange={handleSelectedId}
          value={selectedId}
          valueKey="value"
          labelKey="display_name"
          style={{ width: '100%' }}
        />

        <Input
          type="text"
          label="Value"
          name="attributeValue"
          value={attribute.attributeValue}
          onChange={handleSelectedValue}
        />
      </div>
    </div>
  );
};

export default AttributeCard;
