import http from 'App/services/http';

/**
 * BIQ actions / endpoints
 */
const fetchTradingBackends = () => http.get('/providers/biq/trading_backends/');
const fetchLeverages = backend_id => http.get(`/providers/biq/trading_backends/${backend_id}/leverages/`);
const fetchTradingAccounts = external_id => http.get(`/providers/biq/users/${external_id}/trading_accounts/`);
const fetchLabels = () => http.get('/providers/biq/trading_account_labels/');
const fetchAllowedAccounts = external_id => http.get(`/providers/biq/users/${external_id}/allowed_trading_accounts/`);
const fetchTradingAccountOptions = external_id => http.options(`/providers/biq/users/${external_id}/trading_accounts/`);
const createTradingAccount = (external_id, request) => http.post(`/providers/biq/users/${external_id}/trading_accounts/`, request);
const editTradingAccount = (external_id, account_id, request) => http.put(`/providers/biq/users/${external_id}/trading_accounts/${account_id}/`, request);

export {
  fetchTradingBackends,
  fetchTradingAccounts,
  fetchLeverages,
  fetchLabels,
  fetchAllowedAccounts,
  fetchTradingAccountOptions,
  createTradingAccount,
  editTradingAccount,
};
