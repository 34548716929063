import React, { Fragment, useState } from 'react';
import { Pill, Tooltip, Space, Button, Icon } from 'tc-biq-design-system';
import { array, string, object } from 'prop-types';
import classNames from 'classnames';

import hideCols from 'App/services/utilities/hideCols';
import extractDisplayName from 'App/services/utilities/extractDisplayName';
import formatDataCyString from 'App/services/utilities/formatDataCyString';
import NoDataDash from 'App/components/NoDataDash';
import {
  UserCell,
  RouteCell,
  DateTimeCell,
  actionWrapper,
  StatusCell,
} from 'App/components/gridCellRenderers';
import { testWebhookConnection } from 'Settings/Sections/Webhooks/services/webhooksService';
import { handleErrorResponse } from 'App/services/utilities/error.utils';

const EVENTS_STYLE = {
  display: 'flex',
  flexDirection: 'column',
};

const text = {
  TEST_CONNECTION: 'Test connection',
  SUCCESS: 'Success',
  FAILED: 'Failed',
  RESEND: 'Resend',
  TRIGGER_EVENT: 'Trigger events',
};

const TriggerEvents = ({ events }) => (
  <div style={EVENTS_STYLE}>
    {events.map(event => (
      <div key={event.id}>{event.name}</div>
    ))}
  </div>
);
TriggerEvents.propTypes = { events: array.isRequired };

const RenderTriggerEvents = ({ value }) => {
  if (!value) return <NoDataDash />;
  return (
    <Tooltip content={<TriggerEvents events={value} />} placement="right">
      <Pill iconPosition="left" type="neutral" icon="Action">
        {` ${value.length}`}
      </Pill>
    </Tooltip>
  );
};
RenderTriggerEvents.propTypes = { value: array };
RenderTriggerEvents.defaultProps = { value: null };

// eslint-disable-next-line react/prop-types
const TestConnection = ({ data }) => {
  const [testStatus, setTestStatus] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const testConnection = () => {
    setLoadingStatus(true);
    testWebhookConnection(data.id)
      .then(() => {
        setTestStatus(true);
      })
      .catch((err) => {
        setTestStatus(false);
        handleErrorResponse(err);
      })
      .finally(() => {
        setLoadingStatus(false);
      });
  };

  return (
    <Fragment>
      {testStatus === null && (
        <div data-cy={`test-connection-btn-${formatDataCyString(data.name)}`}>
          <Button loading={loadingStatus} color="transparent" icon="Play" onClick={testConnection}>
            {text.TEST_CONNECTION}
          </Button>
        </div>
      )}
      {testStatus !== null && (
        <div
          data-cy={`test-cell-${formatDataCyString(data.name)}`}
          style={{ display: 'flex', alignItems: 'center', width: '100%' }}
        >
          <Icon
            colorName={testStatus ? 'text-status01-500' : 'text-status04-500'}
            name={testStatus ? 'Checkmark' : 'Close'}
          />
          <Space size={4} />
          <div
            data-cy={`status-${formatDataCyString(data.name)}`}
            className={classNames('tc-paragraph-strong', {
              'text-status01-500': testStatus,
              'text-status04-500': !testStatus,
            })}
          >
            {testStatus ? text.SUCCESS : text.FAILED}
          </div>
          <Space size={4} />

          <Button
            loading={loadingStatus}
            color="transparent"
            icon="Pending"
            onClick={testConnection}
          >
            {text.RESEND}
          </Button>
        </div>
      )}
    </Fragment>
  );
};

const RenderLastPing = ({ data, value }) => {
  if (value === null) return <NoDataDash />;
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <DateTimeCell value={value} />
      <Space size={12} />
      <StatusCell value={data.last_delivery_status} />
    </div>
  );
};
RenderLastPing.propTypes = { data: object.isRequired, value: string };
RenderLastPing.defaultProps = { value: null };

const modifiers = actions => ({
  ...hideCols(['id', 'created', 'updated', 'url', 'last_delivery_status']),
  trigger_events: {
    cellRendererFramework: RenderTriggerEvents,
    headerName: text.TRIGGER_EVENT,
  },
  last_delivery_time: {
    cellRendererFramework: RenderLastPing,
    headerName: 'Last ping',
    minWidth: 250,
  },
  name: {
    cellRendererFramework: ({ data }) => RouteCell('/settings/webhooks/')({ hrefValue: data.id, value: data.name }),
  },
  updated_by: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => data.updated_by ? (
      <UserCell
        id={data.updated_by.id}
        avatar={data.updated_by.avatar}
        fullName={extractDisplayName(data.updated_by)}
        subTitle={<DateTimeCell value={data.updated} />}
      />
    ) : (
      <NoDataDash />
    ),
  },
  created_by: {
    // eslint-disable-next-line
    cellRendererFramework: ({ data }) => (
      <UserCell
        id={data.created_by.id}
        avatar={data.created_by.avatar}
        fullName={extractDisplayName(data.created_by)}
        subTitle={<DateTimeCell value={data.created} />}
      />
    ),
  },
  test_connection: {
    cellRendererFramework: TestConnection,
    minWidth: 200,
    headerName: 'Test connection',
  },
  actions: {
    cellRendererFramework: actionWrapper(actions),
    unSortIcon: false,
    maxWidth: 80,
  },
});

export default modifiers;
