import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { notifier } from 'tc-biq-design-system';
import { object, func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import withFilters from 'App/components/hoc/withFilters';

import BarChart from 'Dashboard/components/layouts/BarChart';
import { fetchMetricsData } from 'Dashboard/services/dashboardService';
import run from 'App/services/utilities/run';

const text = {
  TITLE: 'Conversions workflow',
  DESCRIPTION: 'Total number of conversions per workflow, in selected time period.',
  TOTAL_LABEL: 'Total',
  ERROR_MESSAGE: 'Something went wrong',
};

const METRIC = 'workflow_conversions';

const propTypes = {
  dashboardInterval: object,
  addNewFilter: func,
  history: object.isRequired,
};

const defaultProps = {
  dashboardInterval: {},
  addNewFilter: null,
};

const filters = (startDate, endDate) => [
  {
    field: { label: 'Created', value: 'created', type: 'datetime' },
    ...(startDate === endDate
      ? { operator: { label: 'on', value: 'date', related_models: null } }
      : { operator: { label: 'between', value: 'range', related_models: null } }),
    ...(startDate === endDate
      ? { value: startDate }
      : { value: [`${startDate}T00:00:00Z`, `${endDate}T23:59:59Z`] }),
  },
];

const valueRenderer = (key, value) => `${key}: ${value}`;

const ConversionWorkflowChart = inject(stores => ({
  dashboardInterval: stores.dashboardStore.dashboardInterval,
  addNewFilter: stores.tables.workflows.filters.addNewFilter,
}))(observer(
  class ConversionWorkflowChart extends Component {
    constructor() {
      super();
      this.state = {
        fetchInProgress: false,
        data: [],
      };
    }

    onQueryReady = async (query) => {
      const { start_date, end_date } = query;

      const formattedQuery = {
        metric: METRIC,
        query: {
          start_of_interval: start_date,
          end_of_interval: end_date,
          granularity: 'all',
          dimensions: ['workflow_id', 'workflow_name'],
        },
      };

      const [err, data] = await run(fetchMetricsData(formattedQuery));

      if (err) {
        notifier.error(text.ERROR_MESSAGE);
      } else {
        this.setState({
          fetchInProgress: false,
          data,
        });
      }
    };

    handleCardClick = () => {
      const { addNewFilter, history, dashboardInterval } = this.props;
      const { startDate, endDate } = dashboardInterval;
      filters(startDate, endDate).forEach(addNewFilter);
      history.push('/marketing/workflows');
    };

    render() {
      const { fetchInProgress, data } = this.state;
      const bars = [
        { label: 'Count', key: 'count', color: 'status01-500' },
        { key: 'total', label: 'Total' },
      ];

      return (
        <BarChart
          title={text.TITLE}
          description={text.DESCRIPTION}
          inProgress={fetchInProgress}
          onChangeDimension={this.onChangeDimension}
          bars={bars}
          data={data}
          xAxisKey="workflow_name"
          handleCardClick={this.handleCardClick}
          valueRenderer={valueRenderer}
        />
      );
    }
  },
));


ConversionWorkflowChart.wrappedComponent.propTypes = propTypes;
ConversionWorkflowChart.wrappedComponent.defaultProps = defaultProps;

export default {
  component: withRouter(withFilters(ConversionWorkflowChart)),
};
