import { action, observable, computed, runInAction, makeObservable } from 'mobx';
import { notifier } from 'tc-biq-design-system';
import find from 'lodash/find';
import { partition } from 'lodash';
import run from 'App/services/utilities/run';

import formatPayload from 'App/services/utilities/formatPayload';
import stores from 'App/rootStore';
import { handleErrorResponse } from 'App/services/utilities/error.utils';
import { formatBiqFields } from 'App/services/utilities/formatters/format-biq-fields';

const initialState = {
  users: [],
};

const ERROR_MESSAGES = {
  REQUIRED: 'This field is required',
  INVITE_FAILED: 'Inviting users failed',
  USER_RETRIEVAL_FAILED: 'Failed to retrieve user data.',
};

const text = {
  PASSWORD_CHANGE_SUCCESS: 'Email sent with password change link!',
  PASSWORD_CHANGE_FAILURE: 'Failed to request password change!',
  ADD_SIGNATURE_SUCECESS: 'Successfully edited signature',
  ADD_SIGNATURE_FAILURE: 'Failed to edit signature',
  REMOVE_USER_SUCCESS: 'Successfully removed user',
};

export class UsersStore {
  constructor(UsersService, manageUsersStore) {
    makeObservable(this, {
      users: observable,
      user: observable,
      requestInProgress: observable,
      errors: observable,
      inviteRequestStatus: observable,
      isInviteUsersTableReady: observable,
      userFields: observable,
      addUser: action.bound,
      checkEmailExists: action.bound,
      removeUser: action.bound,
      editUserDetails: action.bound,
      updateUserDetails: action.bound,
      uploadAvatar: action.bound,
      deleteUser: action.bound,
      resetUsersState: action.bound,
      setInviteGridToReady: action.bound,
      fetchUserFields: action.bound,
      fetchUser: action.bound,
      setEditUserDetailsData: action.bound,
      setAddUserData: action.bound,
      resetUserFields: action.bound,
      inviteUsers: action.bound,
      resendInvite: action.bound,
      setErrors: action.bound,
      resetErrors: action.bound,
      hasUsers: computed,
      initiatePasswordChange: action.bound,
      emailInboxes: observable,
      sharedEmailInboxes: observable,
      loading: observable,
      fetchEmailInboxes: action.bound,
      connectEmailInbox: action.bound,
      deleteEmailInbox: action.bound,
      revokeEmailInbox: action.bound,
      reconnectEmailInbox: action.bound,
      addEmailInboxSignature: action.bound,
    });
    this.usersService = UsersService;
    this.manageUsers = manageUsersStore;
  }

  users = [];

  user = {};

  requestInProgress = {
    user: false,
    updateUser: false,
    uploadAvatar: false,
    addSignature: false,
    initiatePasswordChange: false,
    fetchUserData: false,
  };

  errors = {
    uploadAvatar: null,
    deleteUser: null,
  };

  inviteRequestStatus = 'idle';

  isInviteUsersTableReady = false;

  userFields = {};

  async addUser({ id }) {
    const { role, contact_access_group, ...data } = stores.forms.addUser.data;
    if (contact_access_group == null) {
      stores.forms.addUser.setFieldsErrors({
        contact_access_group: ERROR_MESSAGES.REQUIRED,
      });
      return false;
    }
    if (role == null) {
      stores.forms.addUser.setFieldsErrors({ role: ERROR_MESSAGES.REQUIRED });
      return false;
    }
    if (data.team == null) {
      stores.forms.addUser.setFieldsErrors({ team: ERROR_MESSAGES.REQUIRED });
      return false;
    }
    if (!(await this.checkEmailExists(data.email))) {
      return false;
    }
    const payload = {
      roles: role || [],
      contact_access_groups: contact_access_group || [],
      ...data,
    };

    runInAction(() => {
      if (id != null) {
        this.users[id] = { id, ...payload };
        this.users = this.users.slice();
      } else {
        this.users = [...this.users.slice(), { id: this.users.length, ...payload }];
      }
    });
    stores.forms.addUser.resetFieldsData();
    return true;
  }

  async checkEmailExists(email) {
    try {
      await this.usersService.checkEmailExists({ email });
      return true;
    } catch (err) {
      stores.forms.addUser.setFieldsErrors(err.response.data);
      handleErrorResponse(err);
      return false;
    }
  }

  removeUser(id) {
    this.users = this.users.filter(user => user.id !== id);
  }

  editUserDetails(id, isSelfEdit) {
    const formattedPayload = formatPayload(stores.forms.editUser.data);
    const {
      team_binding__team,
      role_bindings__role,
      contact_access_group_bindings__contact_access_group,
      ...payload
    } = formattedPayload;
    if (!isSelfEdit) {
      if (team_binding__team) {
        payload.team_binding = { team: team_binding__team };
      }
      if (role_bindings__role) {
        payload.role_bindings = role_bindings__role.map(value => ({
          role: value,
        }));
      }
      if (contact_access_group_bindings__contact_access_group) {
        // eslint-disable-next-line
        payload.contact_access_group_bindings =
          contact_access_group_bindings__contact_access_group.map(value => ({
            contact_access_group: value,
          }));
      }
    }
    return this.usersService.editUserDetails(id, payload);
  }

  async updateUserDetails({ id, messages, selfUpdate }) {
    this.requestInProgress.updateUser = true;
    try {
      const formattedPayload = formatPayload(stores.forms.userDetailsForm.data);
      const {
        team_binding,
        role_bindings,
        contact_access_group_bindings,
        ...payload
      } = formattedPayload;
      if (!selfUpdate) {
        if (team_binding) {
          payload.team_binding = { team: team_binding };
        }
        if (role_bindings) {
          payload.role_bindings = role_bindings.map(value => ({
            role: value,
          }));
        }
        if (contact_access_group_bindings) {
          payload.contact_access_group_bindings = contact_access_group_bindings.map(value => ({
            contact_access_group: value,
          }));
        }
      }
      await this.usersService.updateUser(id, payload);
      notifier.success(messages.SUCCESS);
      runInAction(() => {
        this.requestInProgress.updateUser = false;
      });
    } catch (err) {
      handleErrorResponse(err, messages.ERROR);
      if (err.response && err.response.data) {
        stores.forms.userDetailsForm.setFieldsErrors(err.response.data);
      }
      runInAction(() => {
        this.requestInProgress.updateUser = false;
      });
    }
  }

  async uploadAvatar({ id, payload, notifyMsgs }) {
    this.requestInProgress.uploadAvatar = true;
    const { setUserData, user } = stores.loginStore;
    try {
      const response = await this.usersService.uploadAvatar(id, payload);
      notifier.success(notifyMsgs.success);
      if (id === user.id) setUserData(response.data);
      runInAction(() => {
        this.user = response.data;
      });
    } catch (err) {
      handleErrorResponse(err, notifyMsgs.error);
      runInAction(() => {
        this.errors.uploadAvatar = err.response;
      });
    } finally {
      runInAction(() => {
        this.requestInProgress.uploadAvatar = false;
      });
    }
  }

  async deleteUser(id) {
    runInAction(() => {
      this.errors.deleteUser = false;
    });
    try {
      await this.usersService.deleteUser(id);
      notifier.success(text.REMOVE_USER_SUCCESS);
    } catch (err) {
      runInAction(() => {
        this.errors.deleteUser = true;
      });
      handleErrorResponse(err);
    }
  }

  resetUsersState() {
    this.users = initialState.users;
    this.isInviteUsersTableReady = false;
    this.inviteRequestStatus = 'idle';
  }

  setInviteGridToReady() {
    this.isInviteUsersTableReady = true;
  }

  async fetchUserFields() {
    const response = await this.usersService.userOptions();
    const fieldsOptions = response.data.actions.GET;
    const { data } = await this.usersService.fetchBiqTeamOptions();
    const biqFields = formatBiqFields(data.actions.POST.fields);

    const { team, object_acl_rules } = biqFields;
    fieldsOptions.biq_team = { ...team, type: 'choice', label: 'BIQ Team', key: 'biq_team', name: 'biq_team' };
    fieldsOptions.object_acl_rules = { ...object_acl_rules, type: 'choice', multi: true };

    runInAction(() => {
      this.userFields = fieldsOptions;
    });
  }

  async fetchUser(id, include_deleted = false) {
    this.requestInProgress.user = true;
    const { setUserData, user } = stores.loginStore;
    const [error, data] = await run(this.usersService.fetchUser(id, { include_deleted }));
    if (error) {
      notifier.error(ERROR_MESSAGES.USER_RETRIEVAL_FAILED);
      this.requestInProgress.user = false;
      return;
    }
    runInAction(() => {
      this.user = data;
      // eslint-disable-next-line max-len
      if (id === user.id) setUserData(data);
      const { role_bindings, contact_access_group_bindings, team_binding } = data;
      stores.forms.userDetailsForm.setFieldsData({
        ...data,
        role_bindings: role_bindings.map(({ role_name, role }) => ({
          value: role,
          display_name: role_name,
        })),
        contact_access_group_bindings: contact_access_group_bindings.map(
          ({ contact_access_group, contact_access_group_name }) => ({
            value: contact_access_group,
            display_name: contact_access_group_name,
          }),
        ),
        team_binding: team_binding
          ? { value: team_binding.team, display_name: team_binding.team_name }
          : null,
      });
      this.requestInProgress.user = false;
    });
  }

  async setEditUserDetailsData(id) {
    runInAction(() => {
      this.requestInProgress.fetchUserData = true;
    });
    try {
      const response = await this.usersService.fetchUser(id);

      runInAction(() => {
        this.user = response.data;
      });

      const { contact_access_group_bindings, role_bindings, team_binding, ...data } = response.data;
      stores.forms.editUser.setFieldsData({
        team_binding__team:
        team_binding != null
          ? { value: team_binding.team, display_name: team_binding.team_name }
          : null,
        role_bindings__role:
        role_bindings != null
          ? role_bindings.map(role => ({
            value: role.role,
            display_name: role.role_name,
          }))
          : null,
        contact_access_group_bindings__contact_access_group:
        contact_access_group_bindings != null
          ? contact_access_group_bindings.map(cag => ({
            value: cag.contact_access_group,
            display_name: cag.contact_access_group_name,
          }))
          : null,
        ...data,
      });
    } catch (err) {
      runInAction(() => {
        this.errors = err;
      });
      handleErrorResponse(err);
    } finally {
      runInAction(() => {
        this.requestInProgress.fetchUserData = false;
      });
    }
  }

  setAddUserData(userId) {
    const { id, contact_access_groups, roles, ...data } = find(this.users, {
      id: userId,
    });
    stores.forms.addUser.setFieldsData({
      ...data,
      role: roles,
      contact_access_group: contact_access_groups,
    });
  }

  resetUserFields() {
    this.userFields = {};
  }

  async inviteUsers() {
    const payload = this.users.slice().map((user) => {
      const contact_access_groups = user.contact_access_groups.map(
        contact_access_group => contact_access_group.value,
      );
      const roles = user.roles.map(role => role.value);
      return {
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        team: user.team.value,
        contact_access_groups,
        roles,
        biq_object_acl_rules: user.object_acl_rules?.map((acl) => acl.value),
        biq_team: user.biq_team?.value,
      };
    });
    this.usersService
      .inviteUsers({
        users: payload,
        // eslint-disable-next-line no-undef
        client_id: AUTH0_CLIENT_ID,
      })
      .then(() => {
        runInAction(() => {
          this.inviteRequestStatus = 'success';
        });
      })
      .catch((err) => {
        handleErrorResponse(err, ERROR_MESSAGES.INVITE_FAILED);
      });
  }

  resendInvite(id) {
    return this.usersService.resendInvite({ ids: [id] });
  }

  setErrors(key, value) {
    this.errors[key] = value;
  }

  resetErrors(key) {
    this.errors[key] = null;
  }

  get hasUsers() {
    return this.users.length > 0;
  }

  async initiatePasswordChange() {
    try {
      this.requestInProgress.initiatePasswordChange = true;
      await this.usersService.changePassword();
      notifier.success(text.PASSWORD_CHANGE_SUCCESS);
    } catch (err) {
      handleErrorResponse(err, text.PASSWORD_CHANGE_FAILURE);
    } finally {
      runInAction(() => {
        this.requestInProgress.initiatePasswordChange = false;
      });
    }
  }

  // email integrations

  emailInboxes = [];

  sharedEmailInboxes = [];

  loading = {
    fetchEmailInboxes: false,
    actionEmailInboxesId: null,
    connectEmailInboxes: false,
  };

  async fetchEmailInboxes() {
    this.loading.fetchEmailInboxes = true;
    try {
      const response = await this.usersService.fetchEmailInbox();
      runInAction(() => {
        [this.emailInboxes, this.sharedEmailInboxes] = partition(
          response.data.results,
          inbox => !inbox.shared_by,
        );
      });
    } catch (err) {
      handleErrorResponse(err, 'Failed to fetch email inboxes');
    } finally {
      runInAction(() => {
        this.loading.fetchEmailInboxes = false;
      });
    }
  }

  async connectEmailInbox(url) {
    this.loading.connectEmailInboxes = true;
    try {
      const response = await this.usersService.connectEmailInbox(url);
      window.location.href = response.data.auth_url;
    } catch (err) {
      handleErrorResponse(err, 'Failed to connect inbox');
    } finally {
      runInAction(() => {
        this.loading.connectEmailInboxes = false;
      });
    }
  }

  async deleteEmailInbox(id) {
    try {
      await this.usersService.deleteEmailInbox(id);
      runInAction(() => {
        this.emailInboxes = this.emailInboxes.filter(inbox => inbox.id !== id);
      });
      notifier.success('Successfully deleted inbox');
    } catch (err) {
      handleErrorResponse(err, 'Failed to delete inbox');
    }
  }

  async revokeEmailInbox(id, onError) {
    try {
      await this.usersService.revokeEmailInbox(id);
      await this.fetchEmailInboxes();
      notifier.success('Successfully revoked inbox');
    } catch (err) {
      onError();
      handleErrorResponse(err, 'Failed to revoke inbox');
    }
  }

  async reconnectEmailInbox(email, redirectUrl) {
    try {
      const response = await this.usersService.reconnectEmailInbox(email, redirectUrl);
      window.location.href = response.data.auth_url;
    } catch (err) {
      handleErrorResponse(err, 'Failed to reconnect inbox');
    }
  }

  async addEmailInboxSignature(id, signature) {
    this.requestInProgress.addSignature = true;
    try {
      await this.usersService.addEmailInboxSignature(id, signature);
      notifier.success(text.ADD_SIGNATURE_SUCECESS);
    } catch (err) {
      handleErrorResponse(err, text.ADD_SIGNATURE_FAILURE);
    } finally {
      runInAction(() => {
        this.requestInProgress.addSignature = false;
        this.fetchEmailInboxes();
      });
    }
  }
}

export default UsersStore;
