import React from 'react';
import { Icon, Space } from 'tc-biq-design-system';
import { string } from 'prop-types';

const FieldSectionTitle = ({ iconName, title, description }) => (
  <div className="fiq-workflow-form__title">
    {iconName && (
      <>
        <Icon colorName="text-primary-500" bgColorName="bck-primary-100" name={iconName} />
        <Space size={16} />
      </>
    )}
    <div>
      <div className="tc-heading-m">{title}</div>
      {description && <p className="tc-paragraph-regular fiq-workflow-form__title__desc">{description}</p>}
    </div>
  </div>
);

FieldSectionTitle.propTypes = {
  title: string.isRequired,
  iconName: string,
  description: string,
};

FieldSectionTitle.defaultProps = {
  iconName: null,
  description: null,
};

export default FieldSectionTitle;
