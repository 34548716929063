import { observable, action, runInAction, makeObservable } from 'mobx';
import { notifier } from 'tc-biq-design-system';
import run from 'App/services/utilities/run';

import { createDerivedEvent, createCustomEvent } from 'Settings/Sections/Events/services/EventsService';
import stores from 'App/rootStore';
import formatPayload from 'App/services/utilities/formatPayload';

import {
  formatEventLabel,
  formatEventNestedKeys,
} from 'App/components/QueryBuilderFactory/queryBuilderStoreUtils';
import { handleErrorResponse } from 'App/services/utilities/error.utils';

const text = {
  CREATE_SUCCESS: 'Successfully created derived event',
  CREATE_FAILED: 'Failed to create event',
  UPDATE_SUCCESS: 'Successfully edited event',
  UPDATE_FAILED: 'Failed to edit event',
  CREATE_CUSTOM_EVENT_SUCCESS: 'Successfully created custom event',
  PROPERTIES_ERROR: 'Properties are missing',
};

export default class EventsStore {
  constructor(queryBuilderStore) {
    makeObservable(this, {
      fields: observable,
      requestInProgress: observable,
      fetchQueryBuilderMetadata: action.bound,
      createDerivedEvent: action.bound,
      createCustomEvent: action.bound,
    });
    this.queryBuilder = queryBuilderStore;
  }

  fields = {};

  requestInProgress = {
    createDerivedEvent: false,
  };

  async fetchQueryBuilderMetadata(fields) {
    runInAction(() => {
      this.fields = fields;
      this.queryBuilder.setFields({});
      this.queryBuilder.setFieldsMetadata(
        formatEventNestedKeys({ old_payload: fields.old_payload, payload: fields.payload }),
        formatEventLabel,
      );
    });
  }

  async createDerivedEvent(coreEventIds, history) {
    runInAction(() => {
      this.requestInProgress.createDerivedEvent = true;
    });
    const { setFieldsErrors, data } = stores.forms.derivedEventForm;

    const payload = {
      ...data,
      query: this.queryBuilder.queries,
      core_events: coreEventIds,
    };

    const [error, eventData] = await run(createDerivedEvent(payload));

    if (error) {
      setFieldsErrors(error.response.data);
      handleErrorResponse(error);
    }

    if (eventData) {
      notifier.success(text.CREATE_SUCCESS);
      history.push('/settings/events');
    }

    runInAction(() => {
      this.requestInProgress.createDerivedEvent = false;
    });
  }


  async createCustomEvent(history) {
    runInAction(() => {
      this.requestInProgress.createCustomEvent = true;
    });
    const { setFieldsErrors, data } = stores.forms.customEventForm;

    const formatedData = formatPayload(data);

    if (!formatedData?.properties?.length) {
      notifier.error(text.PROPERTIES_ERROR);
      return;
    }

    const payload = {
      ...formatedData,
      is_activity_visible: data.is_activity_visible || false,
    };

    const [error, eventData] = await run(createCustomEvent(payload));
    if (error) {
      setFieldsErrors(error.response.data);
      handleErrorResponse(error);
    }

    if (eventData) {
      notifier.success(text.CREATE_CUSTOM_EVENT_SUCCESS);
      history.push('/settings/events/');
    }

    runInAction(() => {
      this.requestInProgress.createCustomEvent = false;
    });
  }
}
