import React, { Fragment, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { object, func, bool } from 'prop-types';
import classNames from 'classnames';
import { Radio, Space } from 'tc-biq-design-system';

import condition from 'assets/images/entry-condition.svg';
import conditionActive from 'assets/images/entry-condition-active.svg';
import trigger from 'assets/images/entry-trigger.svg';
import triggerActive from 'assets/images/entry-trigger-active.svg';

import If from 'App/components/If';
import Field from 'App/components/FieldRenderer';
import QueryBuilderFactory from 'App/components/QueryBuilderFactory';
import Loader from 'App/components/Loader';
import FieldSectionTitle from '../FieldSectionTitle';
import SectionWrapper from '../SectionWrapper';
import { SEND_TO_TRIGGER_TYPES } from '../../../store/WorkflowsStore';

const propTypes = {
  formData: object.isRequired,
  typeProps: object.isRequired,
  segmentProps: object.isRequired,
  send_to_type: object,
  updateFieldValue: func.isRequired,
  loadingQueryBuilderMetadata: bool.isRequired,
  fetchQueryBuilderMetadata: func.isRequired,
};

const defaultProps = { send_to_type: null };

const text = {
  ENTRY: 'Entry',
  TO: 'To',
  SEGMENT: 'Segment',
  MEET_CONDITIONS: 'They meet conditions',
  MEET_CONDITIONS_DESCRIPTION:
    'Contacts who fulfill specified conditions will enter workflow.',
  TRIGGER: 'Trigger',
  TRIGGER_DESCRIPTION:
    'Once a Contact enters or leaves a segment he will become a part of the workflow',
};

const { QueryBuilder } = QueryBuilderFactory({
  storeKey: 'workflows',
  storeFieldKey: 'sendToQueryBuilder',
  initialConditionPlaceholder: 'Optionally add conditions to filter the selected contacts',
});

const SendToFields = ({
  formData,
  typeProps,
  segmentProps,
  allowManualEnrollmentProps,
  send_to_type,
  updateFieldValue,
  loadingQueryBuilderMetadata,
  fetchQueryBuilderMetadata,
}) => {
  const [isTriggerEntry, setTriggerEntry] = useState(
    SEND_TO_TRIGGER_TYPES.includes(send_to_type?.value) ? 'true' : 'false',
  );
  const [sendToTypeOptions, setSendToTypeOptions] = useState(typeProps.options);
  const [isInitial, setInitial] = useState(true);
  const isTriggerChecked = isTriggerEntry === 'true';
  const [applyOnlyManualEnrollment, setApplyOnlyManualEnrollment] = useState((formData.allow_manual_enrollment && formData.send_to_type === null));

  const getSendToTypeValue = (sendToType, options) => {
    if (!sendToType) return null;
    return options.find(option => option.value === sendToType.value) || options[0];
  };

  const setEntry = (value) => {
    setTriggerEntry(value);
    updateFieldValue('send_to_segment', null);
  };

  const onApplyManualEnrollmentChange = (checked) => {
    setApplyOnlyManualEnrollment(checked);

    if (checked) {
      setManualEnrollment(checked);
      updateFieldValue('send_to_type', null);
    }
  };

  const setManualEnrollment = (value) => {
    updateFieldValue('allow_manual_enrollment', value);
  };

  useEffect(() => {
    if (send_to_type && SEND_TO_TRIGGER_TYPES.includes(send_to_type.value)) {
      setTriggerEntry('true');
    }

    if (!send_to_type || send_to_type.value === 'All contacts') {
      updateFieldValue('send_to_segment', null);
    }
  }, [send_to_type]);

  useEffect(() => {
    if (isTriggerChecked) {
      // eslint-disable-next-line
      const triggerOptions = typeProps.options.filter(option => SEND_TO_TRIGGER_TYPES.includes(option.value));
      setSendToTypeOptions(triggerOptions);
      updateFieldValue('send_to_type', getSendToTypeValue(send_to_type, triggerOptions));
    } else {
      const conditionOptions = typeProps.options.filter(
        option => !SEND_TO_TRIGGER_TYPES.includes(option.value),
      );
      setSendToTypeOptions(conditionOptions);
      updateFieldValue('send_to_type', getSendToTypeValue(send_to_type, conditionOptions));
      if (!isInitial) updateFieldValue('end_datetime', null);
    }
    setInitial(false);
  }, [isTriggerChecked]);

  useEffect(() => {
    fetchQueryBuilderMetadata();
  }, []);

  return (
    <SectionWrapper.FieldWrapper>
      <div className="flex align-flex-start gap-30">
        <FieldSectionTitle title={text.ENTRY} />

        <div className="fiq-workflow-form__entry-checkboxes">
          <Field
            {...allowManualEnrollmentProps}
            description="Enable manual enrolment"
            formId="workflowForm"
            onChange={(name, checked) => setManualEnrollment(checked)}
          />
          <Field
            type="boolean"
            description="Apply only manual enrolment"
            name="apply_only_manual_enrolment"
            key="apply_only_manual_enrolment"
            value={applyOnlyManualEnrollment}
            onChange={(name, checked) => onApplyManualEnrollmentChange(checked)}
          />
        </div>
      </div>

      <Radio.Group
        name="name"
        value={isTriggerEntry}
        onChange={event => setEntry(event.target.value)}
        horizontal
        disabled={applyOnlyManualEnrollment}
      >
        <Radio
          value="false"
          checked={!isTriggerChecked}
          className={classNames('fiq-workflow-form__entry-radio', {
            active: !isTriggerChecked && !applyOnlyManualEnrollment,
          })}
        >
          <img src={!isTriggerChecked && !applyOnlyManualEnrollment ? conditionActive : condition} alt="Condition"/>
          <div className="fiq-workflow-form__entry-radio__content">
            <span className="tc-paragraph-strong">{text.MEET_CONDITIONS}</span>
            <span className="text-neutral-500">{text.MEET_CONDITIONS_DESCRIPTION}</span>
          </div>
        </Radio>
        <Radio
          value="true"
          checked={isTriggerChecked && !applyOnlyManualEnrollment}
          className={classNames('fiq-workflow-form__entry-radio', {
            active: isTriggerChecked && !applyOnlyManualEnrollment,
          })}
        >
          <img src={isTriggerChecked && !applyOnlyManualEnrollment ? triggerActive : trigger} alt="Trigger" />
          <div className="fiq-workflow-form__entry-radio__content">
            <span className="tc-paragraph-strong">{text.TRIGGER}</span>
            <span className="text-neutral-500">{text.TRIGGER_DESCRIPTION}</span>
          </div>
        </Radio>
      </Radio.Group>
      <Space size={12} />

      <If condition={!applyOnlyManualEnrollment}>
        <Field {...typeProps} options={sendToTypeOptions} label={text.TO} formId="workflowForm" />
        {send_to_type && send_to_type.value !== 'All contacts' && (
          <Field {...segmentProps} label={text.SEGMENT} formId="workflowForm" />
        )}
        {send_to_type && (
          <Fragment>
            <Loader visible={loadingQueryBuilderMetadata} />
            {!loadingQueryBuilderMetadata && (
              <QueryBuilder style={{ marginTop: '0', padding: '0', overflow: 'visible' }} />
            )}
          </Fragment>
        )}
      </If>
    </SectionWrapper.FieldWrapper>
  );
};
SendToFields.propTypes = propTypes;
SendToFields.defaultProps = defaultProps;

export default inject(stores => ({
  formData: stores.forms.workflowForm.data,
  send_to_type: stores.forms.workflowForm.data.send_to_type,
  updateFieldValue: stores.forms.workflowForm.updateFieldValue,
  fetchQueryBuilderMetadata: stores.workflows.fetchSendToQueryBuilderMetadata,
  loadingQueryBuilderMetadata: stores.workflows.requestInProgress.contactPayloadMetadata,
}))(observer(SendToFields));
