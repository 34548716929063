import React from 'react';
import { object, number, func, array } from 'prop-types';
import { Space, Select, Icon } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';

import RenderActionFields from './RenderActionFields';

import './ActionCard.scss';

const propTypes = {
  actionsMetadata: object,
  actionIndex: number.isRequired,
  action: object.isRequired,
  updateActionId: func.isRequired,
  removeRuleAction: func.isRequired,
  actionChoices: array.isRequired,
  errors: object,
};

const defaultProps = {
  actionsMetadata: null,
  errors: {},
};

const text = {
  PLACEHOLDER: 'Action triggered by this custom action',
};

const ActionCard = ({
  actionsMetadata,
  action,
  actionChoices,
  actionIndex,
  updateActionId,
  removeRuleAction,
  errors,
}) => {
  const selectAction = value => updateActionId(actionIndex, value);
  const removeAction = () => removeRuleAction(actionIndex);
  const hasError = !isEmpty(errors.action_parameters);

  return (
    <div className="fiq-rule-action-card">
      <div className="fiq-rule-action-card__header">
        <span className="action-count tc-paragraph-strong text-primary-500">
          {+actionIndex + 1}
        </span>
        <Space size={8} />
        <div className="tc-paragraph-strong title">Action</div>
        <Icon style={{ cursor: 'pointer' }} size="small" name="Delete" onClick={removeAction} />
      </div>
      <div className="fiq-rule-action-card__body">
        <Select
          type="search"
          options={actionChoices}
          name="action"
          onChange={selectAction}
          value={action.actionId}
          placeholder={text.PLACEHOLDER}
          hasError={hasError}
          helpText={hasError && errors.action_parameters[0]}
          valueKey="value"
          labelKey="display_name"
          maxMenuHeight={150}
        />
        <Space size={8} />
        {action && action.actionId && (
          <RenderActionFields
            actionId={action.actionId}
            actionIndex={actionIndex}
            formData={action.actionForm}
            actionFields={actionsMetadata[action.actionId.value]}
          />
        )}
      </div>
    </div>
  );
};

ActionCard.propTypes = propTypes;
ActionCard.defaultProps = defaultProps;

export default inject(stores => ({
  actionsMetadata: stores.customActions.actionsMetadata,
  updateActionId: stores.customActions.updateActionId,
  removeRuleAction: stores.customActions.removeRuleAction,
  actionChoices: stores.customActions.actionChoices,
  errors: stores.forms.customActionForm.fieldErrors,
}))(observer(ActionCard));
