import {
  RouteCell,
  StatusCell,
  SideCell,
  WalletCell, ContactCell, AmountCell,
} from 'App/components/gridCellRenderers';
import NoDataDash from 'App/components/NoDataDash';
import React from 'react';

export default {
  id: {
    pinned: 'left',
    cellRendererFramework: RouteCell('/transactions/trades/'),
  },
  side: {
    cellRendererFramework: SideCell,
  },
  position_effect: {
    cellRendererFramework: StatusCell,
  },
  wallet: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => (
      <WalletCell data={value} />
    ),
  },
  contact: {
    // eslint-disable-next-line
    cellRendererFramework: ({ value }) => value ? (
      <ContactCell
        id={value.id}
        avatar={value.avatar}
        fullName={`${value.first_name} ${value.last_name}`}
      />
    ) : (
      <NoDataDash />
    ),
  },
  amount: {
    cellRendererFramework: AmountCell,
  },
};
