import React from 'react';
import {
  withComboxManager,
  withSidepanelManager,
  MultiActions,
  Space,
  Button,
} from 'tc-biq-design-system';
import { object } from 'prop-types';

import ActionItem from './ActionItem';
import ActionItemDropdown from './ActionItemDropdown';
import ContactActions, { actionTypes } from '../../ContactActions';

import './HeaderActions.scss';

const propTypes = {
  comboxManager: object.isRequired,
  sidepanelManager: object.isRequired,
};

const mapActionsAttributes = actions => actions.map((action) => {
  const { icon, label, name, handler } = action;
  return {
    icon,
    label,
    data: name,
    action: handler,
  };
});

const renderActions = actions => actions
  .map(action => (
    <div key={action.name} className="fiq-contact-actions__top">
      {action.handler && (
      <ActionItem icon={action.icon} label={action.label} onClick={action.handler} />
      )}
      {action.dropdownActions && (
      <ActionItemDropdown
        icon={action.icon}
        label={action.label}
        actions={action.dropdownActions}
      />
      )}
    </div>
  ));

const renderDropdownActions = actions => (actions.length ? (
  <div className="fiq-contact-actions__dropdown">
    <MultiActions key="dropdown-actions" actions={mapActionsAttributes(actions)} />
  </div>
) : null);

const HeaderActions = (props) => {
  const { contact } = props;
  const biqContactUrl = `${BIQ_BASE_URL}user/${contact.external_id}/`;
  return (
    <div className="fiq-contact-actions">
      { BINQFUSE_ENABLED && (
        <Button style={{ width: '104px' }} size="small">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={biqContactUrl}
          >
            View in BIQ
          </a>
        </Button>
      ) }
      <ContactActions type={actionTypes.TOP} {...props}>
        {renderActions}
      </ContactActions>
      <Space size={12} />
      <ContactActions type={actionTypes.DROPDOWN} {...props}>
        {renderDropdownActions}
      </ContactActions>
    </div>
  );
};

HeaderActions.propTypes = propTypes;

export default withComboxManager(withSidepanelManager(HeaderActions));
