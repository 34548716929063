import { func, object } from 'prop-types';
import { contactStatusFilter } from 'App/helpers/contactStatusFilter';
import actions from './actions';
import actionTypes from './actions/types';

const getActions = (comboxManager, sidepaneManager, contact) => (type, contactStatus) => {
  const overlayManager = {
    [actionTypes.TOP]: comboxManager,
    [actionTypes.DROPDOWN]: sidepaneManager,
    [actionTypes.TRADING_ACCOUNTS]: sidepaneManager,
  };

  const status = contactStatusFilter(contact.status, true);

  return actions(contactStatus)
    .filter(action => action.type === type)
    .filter(action => ((action.hasPermission) ? action.hasPermission(status) : true))
    .map(action => ({
      ...action,
      label: typeof action.label === 'function' ? action.label() : action.label,
      handler: action.handler ? () => action.handler(overlayManager[type], contact) : null,
      dropdownActions: action.dropdownActions ? action.dropdownActions(overlayManager[type]) : null,
    }));
};

const propTypes = {
  children: func.isRequired,
  comboxManager: object,
};

// eslint-disable-next-line
const ContactActions = ({ type, children, comboxManager, sidepanelManager, contactStatus, contact }) => children(getActions(comboxManager, sidepanelManager, contact)(type, contactStatus));

ContactActions.propTypes = propTypes;
export default ContactActions;
