export const CONTACT_STATUS_LABEL = {
  FULL: 'Full',
  LIMITED: 'Limited',
  PENDING: 'Pending',
  REVIEW: 'Review',
  ALERT: 'Alert',
  KYC_CHECK_IN_PROGRESS: 'KYC checks in progress',
  UNSUITABLE_REFER_DEMO: 'Unsuitable Demo Refer',
  UNSUITABLE_DEMO: 'Unsuitable Demo',
  DEMO: 'Demo',
  PENDING_SMS_VERIFICATION: 'Pending Sms Verification',
  PENDING_EMAIL_VERIFICATION: 'Pending Email Verification',
  INCOMPLETE: 'Incomplete',
  EXCLUDED: 'Excluded',
  BLACKLIST: 'Blacklist',
  UNSUITABLE_REFER: 'Unsuitable Refer',
  UNSUITABLE: 'Unsuitable',
  REJECTED: 'Rejected',
  LEAD: 'Lead',
  ARCHIVED: 'Archived',
  REJECTED_IB: 'Rejected',
  INCOMPLETE_IB: 'Incomplete',
  PENDING_IB: 'Pending',
  APPROVED_IB: 'Approved',
};
