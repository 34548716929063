import React from 'react';
import { withSidepanelManager, notifier } from 'tc-biq-design-system';
import { object, number } from 'prop-types';
import { withRouter } from 'react-router-dom';

import { fetchSingleTask } from 'Tasks/services/tasksService';
import { openComments } from 'App/components/CommonOverlays/Comments';
import { fetchActivity } from 'ActivityStream/services/activityStreamService';
import { openOverlay } from 'App/services/overlayService';
import { fetchSingleDocument } from 'Contacts/components/Sections/Documents/documentsService';
import store from 'App/rootStore';
import { handleErrorResponse } from 'App/services/utilities/error.utils';

const propTypes = {
  linkObject: object.isRequired,
  activity: object.isRequired,
  contactId: number,
  history: object.isRequired,
};

const defaultProps = {
  contactId: null,
};

const text = {
  PREVIEW_ERROR: 'Failed to preview entry!',
};

const overlayActions = {
  // eslint-disable-next-line consistent-return
  tasks_task: async ({ linkObject }) => {
    const { id } = linkObject;
    try {
      const response = await fetchSingleTask(id);
      return openOverlay('PREVIEW_TASK', { task: response.data });
    } catch (err) {
      handleErrorResponse(err, text.PREVIEW_ERROR);
    }
  },
  // eslint-disable-next-line consistent-return
  documents_document: async ({ linkObject, contactId }) => {
    const { id } = linkObject;
    try {
      const response = await fetchSingleDocument(contactId, id);
      return openOverlay('PREVIEW_DOC', { doc: response.data, type: 'preview' });
    } catch (err) {
      handleErrorResponse(err, text.PREVIEW_ERROR);
    }
  },
  events_activitycomment: async ({ linkObject, activity }) => {
    if (activity.object?.metadata?.activity_id) {
      const response = await fetchActivity(activity.object.metadata.activity_id);
      return openComments({ activity: response.data, commentId: linkObject.id });
    }
    return null;
  },
  users_dataexport: ({ linkObject, history }) => {
    const filter = {
      field: { label: 'ID', value: 'id', type: 'integer' },
      operator: { label: 'is equal to', value: 'exact', related_models: null },
      value: linkObject.id,
    };
    store.tables.exportData.filters.addNewFilter(filter);
    const userId = store.loginStore.user.id;
    history.push(`/settings/users/${userId}/export-data`);
  },
};

const OverlayAction = ({ linkObject, contactId, activity, history }) => {
  const actionArgs = { linkObject, contactId, activity, history };
  const action = () => overlayActions[linkObject.type](actionArgs);
  return (
    <span className="like-a-link" onClick={action}>
      {linkObject.displayName}
    </span>
  );
};

OverlayAction.propTypes = propTypes;
OverlayAction.defaultProps = defaultProps;

export default withSidepanelManager(withRouter(OverlayAction));
