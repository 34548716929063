import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'tc-biq-design-system';
import { func, object, bool, string } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { isEditState, states } from 'Marketing/utils/workflow';
import { stepKeys } from 'Marketing/pages/WorkflowsSinglePage/WorkflowsSinglePage';

const propTypes = {
  createWorkflow: func.isRequired,
  updateWorkflow: func.isRequired,
  requestInProgress: bool.isRequired,
  history: object.isRequired,
  match: object.isRequired,
  hasErrorsForStartWorkflow: bool.isRequired,
  hasTemplateErrors: bool.isRequired,
  state: string,
  startWorkflow: func.isRequired,
  validateForm: func.isRequired,
};

const defaultProps = {
  state: states.CREATE,
};

const text = {
  DISCARD: 'Discard',
  START_WORKFLOW: 'Start workflow',
  SAVE_AS_DRAFT: 'Save as draft',
  NEXT: 'Next',
};

const WorkflowFormFooter = ({
  createWorkflow,
  updateWorkflow,
  requestInProgress,
  history,
  match,
  hasErrorsForStartWorkflow,
  hasTemplateErrors,
  state,
  startWorkflow,
  validateForm,
}) => {
  const { workflowId } = match.params;
  const onDiscard = () => history.push('/marketing/workflows');
  const onStart = async () => {
    if (isEditState(state)) {
      if (state === states.DETAILS) {
        const success = await updateWorkflow(workflowId, true);
        if (success) {
          history.push(`/marketing/workflows/${workflowId}/setup/${stepKeys.WORKFLOW}`);
        }
      } else {
        const success = await updateWorkflow(workflowId, false);
        if (success) {
          await startWorkflow({ workflowId, history });
        }
      }
    } else {
      if (!validateForm()) return;
      await createWorkflow({ history });
    }
  };

  const saveAsDraft = () => {
    if (isEditState(state)) {
      updateWorkflow(workflowId, true);
    }
  };

  return (
    <div className="fiq-workflow-form__footer">
      <Button onClick={onDiscard} color="ghost">
        {text.DISCARD}
      </Button>
      {state !== states.CREATE && (
        <div className="draft">
          <Button
            loading={requestInProgress}
            disabled={requestInProgress}
            onClick={saveAsDraft}
            color="ghost"
          >
            {text.SAVE_AS_DRAFT}
          </Button>
        </div>
      )}
      <Button
        disabled={requestInProgress || hasErrorsForStartWorkflow || hasTemplateErrors}
        loading={requestInProgress}
        onClick={onStart}
      >
        {state === states.GOAL ? text.START_WORKFLOW : text.NEXT}
      </Button>
    </div>
  );
};

WorkflowFormFooter.propTypes = propTypes;
WorkflowFormFooter.defaultProps = defaultProps;

export default inject(stores => ({
  createWorkflow: stores.workflows.createWorkflow,
  updateWorkflow: stores.workflows.updateWorkflow,
  startWorkflow: stores.workflows.startWorkflow,
  hasErrorsForStartWorkflow: stores.workflows.hasErrorsForStartWorkflow,
  hasTemplateErrors: !isEmpty(stores.forms.channelEmailForm.fieldErrors),
  requestInProgress: stores.workflows.updateCreateInProgress,
  validateForm: stores.forms.workflowForm.validateForm,
}))(observer(withRouter(WorkflowFormFooter)));
