import actionTypes from 'Contacts/components/ContactActions/actions/types';
import { CONTACT_STATUS } from 'App/enums/contactStatus';
import LoginAsContact from './LoginAsContact';

const text = {
  LABEL: 'Login as contact',
};

const ACTION_ICON = 'User';

export default {
  name: 'loginAsContact',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: actionTypes.DROPDOWN,
  hasPermission: status => ([
    CONTACT_STATUS.FULL,
    CONTACT_STATUS.PENDING,
    CONTACT_STATUS.LIMITED,
    CONTACT_STATUS.REVIEW,
    CONTACT_STATUS.DEMO,
  ].includes(status)),
  handler: (sidepanelManager) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      title: text.LABEL,
      type: 'info',
      sidepanelManager,
      visible: true,
    };

    sidepanelManager.add(LoginAsContact, sidepanelOptions);
  },
};
