import stores from 'App/rootStore';
import formatPayload from 'App/services/utilities/formatPayload';
import { notifier } from 'tc-biq-design-system';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { fetchActions } from 'Automation/services/rulesService';
import { handleErrorResponse } from 'App/services/utilities/error.utils';
import { find } from 'lodash';

const text = {
  CREATE_SUCCESS: 'Successfully created custom action',
  CREATE_FAILED: 'Failed to create custom action',
  UPDATE_SUCCESS: 'Successfully edited custom action',
  UPDATE_FAILED: 'Failed to edit custom action',
  DELETE_SUCCESS: 'Successfully deleted custom action',
};

export default class CustomActionsStore {
  requestInProgress = {
    createCustomAction: false,
    updateCustomAction: false,
    fetchCustomAction: false,
    customActionOptions: false,
    actions: false,
  }

  errors = {
    createCustomAction: null,
    updateCustomAction: null,
    fetchCustomAction: null,
    actions: null,
  }

  customAction = {};

  fieldsDef = {};

  actions = [
    {
      actionId: null,
      actionForm: {},
    },
  ];

  actionsMetadata = null;

  actionChoices = [];

  constructor() {
    makeObservable(this, {
      requestInProgress: observable,
      errors: observable,
      customAction: observable,
      fieldsDef: observable,
      actionsMetadata: observable,
      actions: observable,
      actionChoices: observable,
      createCustomAction: action.bound,
      updateCustomAction: action.bound,
      removeCustomAction: action.bound,
      fetchCustomAction: action.bound,
      fetchTableData: action.bound,
      fetchActions: action.bound,
      addAction: action.bound,
      updateActionId: action.bound,
      removeRuleAction: action.bound,
      updateActionForm: action.bound,
      resetCustomActions: action.bound,
    });
  }

  async createCustomAction(history) {
    this.requestInProgress.createCustomAction = true;

    const { data } = stores.forms.customActionForm;
    let customActions = this.getCustomActions();

    const payload = {
      id: customActions.length + 1,
      name: data.name,
      description: data.description,
      is_active: true,
      actions: this.actions.map(a => a.actionId.value),
      action_parameters: formatActionsPayload(this.actions),
    };

    customActions = [...customActions, payload];
    this.saveCustomActions(customActions);

    notifier.success(text.CREATE_SUCCESS);
    this.requestInProgress.createCustomAction = false;
    history.push('/automation/custom-actions');
  }

  async updateCustomAction({ id, history }) {
    this.requestInProgress.updateCustomAction = true;
    const { data } = stores.forms.customActionForm;
    const customActionId = Number(id);

    const payload = {
      id: customActionId,
      name: data.name,
      description: data.description,
      is_active: true,
      actions: this.actions.map(a => a.actionId.value),
      action_parameters: formatActionsPayload(this.actions),
    };

    const customActions = this.getCustomActions();
    const idx = customActions.findIndex(a => a.id === customActionId);
    customActions[idx] = payload;
    this.saveCustomActions(customActions);

    notifier.success(text.UPDATE_SUCCESS);
    this.requestInProgress.updateCustomAction = false;
    history.push('/automation/custom-actions');
  }

  removeCustomAction(id) {
    const customActions = this.getCustomActions();
    const idx = customActions.findIndex(a => a.id === id);
    customActions.splice(idx, 1);

    this.saveCustomActions(customActions);

    notifier.success(text.DELETE_SUCCESS);
  }

  async fetchCustomAction(id) {
    const customActions = this.getCustomActions();
    const { setFieldsData } = stores.forms.customActionForm;

    const data = customActions.find(customAction => customAction.id === Number(id));

    const { name, description, actions, action_parameters } = data;
    this.customAction = data;

    this.actions = formatCustomActionActions(
      actions,
      action_parameters,
      formatOptions(this.actionsMetadata),
    );

    setFieldsData({
      name,
      description,
    });
  }

  fetchTableData() {
    return this.getCustomActions();
  }

  async fetchActions() {
    this.requestInProgress.actions = true;

    try {
      const response = await fetchActions();
      runInAction(() => {
        this.actionsMetadata = response.data;
        this.actionChoices = formatOptions(this.actionsMetadata);
      });
    } catch (e) {
      runInAction(() => {
        this.errors.actions = e.data;
      });
      handleErrorResponse(e);
    } finally {
      runInAction(() => {
        this.requestInProgress.actions = false;
      });
    }
  }

  addAction() {
    this.actions = [
      ...this.actions,
      {
        actionId: null,
        actionForm: {},
      },
    ];
  }

  removeRuleAction(actionIndex) {
    this.actions[actionIndex] = null;
    this.actions = this.actions.filter(ruleAction => ruleAction);
  }

  updateActionId(actionIndex, data) {
    this.actions[actionIndex].actionId = data;
    this.actions[actionIndex].actionForm = {};
  }

  updateActionForm({ actionIndex, key, value }) {
    this.actions[actionIndex].actionForm[key] = value;
  }

  resetCustomActions() {
    const { resetFieldsData } = stores.forms.customActionForm;
    resetFieldsData();
    this.customAction = {};
    this.actions = [
      { actionId: null, actionForm: {} },
    ];
    this.actionsMetadata = null;
    this.actionChoices = [];
  }

  getCustomActions() {
    const customActions = JSON.parse(localStorage.getItem('customActions'));
    if (!customActions || !Array.isArray(customActions)) {
      return [];
    }

    return customActions;
  }

  saveCustomActions(customActions) {
    localStorage.setItem('customActions', JSON.stringify(customActions));
  }
}

function formatActionsPayload(actions) {
  return actions.map((customAction) => {
    const { actionId, actionForm } = customAction;
    if (!actionId) return null;
    return {
      [actionId.value]: formatPayload(actionForm),
    };
  });
}

function formatCustomActionActions(ruleActions, ruleActionParameters, options) {
  return ruleActions?.map((ruleAction, index) => ({
    actionId: find(options, { value: ruleAction }),
    actionForm: ruleActionParameters[index][ruleAction],
  }));
}

function formatOptions(actionsMeta) {
  return Object.keys(actionsMeta)?.map(key => ({
    value: key,
    display_name: key
      .split('.')[0]
      .toLowerCase()
      .split('_')
      .map((string, index) => {
        if (index === 0) {
          return string.charAt(0).toUpperCase() + string.substring(1);
        }
        return string;
      })
      .join(' '),
  }));
}
