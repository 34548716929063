export default {
  'contact-list': '/contacts/',
  'activity-list': '/activity-stream/',
  'tasks-list': '/tasks/',
  'tradetransaction-list': '/transactions/trades/',
  'tradeposition-list': '/transactions/positions/',
  'wallets-list': 'transactions/wallets',
  'paymenttransaction-list': 'transactions/payments',
  'withdrawrequest-list': 'transactions/withdrawals',
  'emaillog-list': 'communications/email-logs',
  'smslog-list': '/communications/sms-logs',
  'whatsapplog-list': '/communications/whatsapp-logs',
  'calllog-list': '/communications/call-logs',
  'workflow-list': '/marketing/workflows',
  'tracking-links-list': '/marketing/tracking-links',
  'rule-list': '/automation/rules',
  'segment-list': '/automation/segments',
  'user-list': '/settings/users',
  'role-list': '/settings/roles',
  'contactaccessgroup-list': '/settings/contact-access-groups',
  'auditlog-list': '/settings/audit-logs',
  'events-list': '/settings/events',
  'saved-filters-list': '/settings/saved-filters',
  'document-types-list': '/settings/document-types',
  'webhook-list': '/settings/webhooks',
};
