import actionTypes from 'Contacts/components/ContactActions/actions/types';
import { CONTACT_STATUS } from 'App/enums/contactStatus';
import Unarchive from './Unarchive';
import { hasAccess } from 'App/services/permissionsService';

const text = {
  LABEL: 'Unarchive',
};

const ACTION_ICON = 'Delete';

// TODO: Should be added permission 'user.unarchive.*'
export default {
  name: 'unarchive',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: actionTypes.DROPDOWN,
  hasPermission: status => status === CONTACT_STATUS.ARCHIVED && hasAccess('contacts_contact', 'update'),
  handler: (sidepanelManager) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      title: text.LABEL,
      type: 'info',
      visible: true,
      sidepanelManager,
    };

    sidepanelManager.add(Unarchive, sidepanelOptions);
  },
};
