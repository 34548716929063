import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Dropdown } from 'tc-biq-design-system';
import CustomActionConfirmModal, {
  openCustomActionConfirmModal,
} from 'Contacts/components/overlays/CustomActionConfirmModal/CustomActionConfirmModal';
import If from 'App/components/If';

const onCustomActionClicked = (name) => {
  openCustomActionConfirmModal(name);
};

const mapActionAttributes = actions => actions.map(({ name }) => ({
  label: name, icon: '', onClick: () => onCustomActionClicked(name),
}));

const ContactCustomActions = ({ fetchCustomActions }) => {
  const [customActions, setCustomActions] = useState([]);
  useEffect(() => {
    setCustomActions(fetchCustomActions());
  }, []);

  return (
    <If condition={customActions?.length > 0}>
      <Dropdown
        title="Custom Actions"
        list={mapActionAttributes(customActions)}
      />
      <CustomActionConfirmModal />
    </If>
  );
};

export default inject(stores => ({
  fetchCustomActions: stores.customActions.fetchTableData,
}))(observer(ContactCustomActions));
