import React from 'react';
import { Button } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';

import Page from 'App/components/Page';
import WorkflowsTable from 'Marketing/components/WorkflowsTable';
import { hasAccess } from 'App/services/permissionsService';

const propTypes = {
  history: object.isRequired,
};

const text = {
  TITLE: 'Workflows',
  CREATE_WORKFLOW: 'Create workflow',
};

const bread = [
  { label: text.TITLE },
];

const WorkflowsPage = ({ history }) => {
  const routeToCreate = () => history.push('/marketing/workflows/create');
  const headerActions = () => (hasAccess('marketing_workflow', 'create') ? <Button onClick={routeToCreate}>{text.CREATE_WORKFLOW}</Button> : null);
  return (
    <Page title={text.TITLE} bread={bread} headerActions={headerActions} style={{ margin: '0 20px' }}>
      <WorkflowsTable />
    </Page>
  );
};

WorkflowsPage.propTypes = propTypes;

export default withRouter(WorkflowsPage);
