import React from 'react';
import moment from 'moment';
import { object } from 'prop-types';

import './Timeframe.scss';

const propTypes = {
  workflow: object,
};

const defaultProps = {
  workflow: {},
};
const text = {
  START: 'Start',
  END: 'End',
};

const Timeframe = ({ workflow }) => (
  <div className="timeframe">
    <div className="info">
      <p className="text-neutral-500">{text.START}</p>
      <p className="tc-paragraph-strong">
        {moment(workflow.start_datetime).format('MMMM D, YYYY HH:mm')}
      </p>
    </div>
    {workflow.end_datetime && (
      <div className="info">
        <p className="text-neutral-500">{text.END}</p>
        <p className="tc-paragraph-strong">
          {moment(workflow.end_datetime).format('MMMM D, YYYY')}
        </p>
      </div>
    )}
  </div>
);

Timeframe.propTypes = propTypes;
Timeframe.defaultProps = defaultProps;

export default Timeframe;
