import React, { Fragment, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { object, func, bool } from 'prop-types';

import QueryBuilderFactory from 'App/components/QueryBuilderFactory';
import Loader from 'App/components/Loader';

const propTypes = {
  triggerEvent: object,
  fetchEventMetadata: func.isRequired,
  loadingMetadata: bool.isRequired,
};

const defaultProps = {
  triggerEvent: null,
};

const { QueryBuilder } = QueryBuilderFactory({
  storeKey: 'workflows',
  storeFieldKey: 'goalQueryBuilder',
  initialConditionPlaceholder: 'Optionally add conditions to narrow down the event goal',
});

const EventQueryBuilder = ({ triggerEvent, fetchEventMetadata, loadingMetadata }) => {
  const fetchMetadata = async () => {
    await fetchEventMetadata(triggerEvent.value);
  };

  useEffect(() => {
    if (triggerEvent && triggerEvent.value && !loadingMetadata) {
      fetchMetadata();
    }
  }, [triggerEvent]);

  return (
    <Fragment>
      <Loader visible={loadingMetadata} />
      {!loadingMetadata && triggerEvent && (
        <QueryBuilder style={{ marginTop: '0', padding: '0', overflow: 'visible' }} />
      )}
    </Fragment>
  );
};

EventQueryBuilder.propTypes = propTypes;
EventQueryBuilder.defaultProps = defaultProps;

export default inject(stores => ({
  triggerEvent: stores.forms.workflowForm.data.goal_event_type,
  fetchEventMetadata: stores.workflows.fetchEventMetadata,
  loadingMetadata: stores.workflows.requestInProgress.eventMetadata,
}))(observer(EventQueryBuilder));
