import { action, observable, runInAction, makeObservable } from 'mobx';
import { isEmpty } from 'lodash';
import { notifier } from 'tc-biq-design-system';
import stores from 'App/rootStore';
import {
  addApiKey,
  editApiKey,
  deleteApiKey,
  fetchApiKeyData,
} from 'Settings/Sections/ApiKeys/services/ApiKeysService';
import { handleErrorResponse } from 'App/services/utilities/error.utils';


const text = {
  EDIT_SUCCESS: 'Edited API key successfully',
  ADD_SUCCESS: 'Created API key successfully',
  DELETE_API_KEY: 'API key has been deleted',
  ERROR: 'There was an error processing your request',
};


export default class ApiKeysStore {
  requestInProgress = false;

  errors = null;

  plainApiKey = null;

  constructor() {
    makeObservable(this, {
      requestInProgress: observable,
      errors: observable,
      plainApiKey: observable,
      setInitialData: action.bound,
      fetchApiKeyData: action.bound,
      addApiKey: action.bound,
      editApiKey: action.bound,
      deleteApiKey: action.bound,
      resetPlainApiKey: action.bound,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  setInitialData(initialData) {
    const { data, setFieldsData } = stores.forms.apiKeyForm;
    if (isEmpty(data)) setFieldsData(initialData);
  }

  async fetchApiKeyData(id) {
    runInAction(() => {
      this.errors = null;
    });
    try {
      const { data } = await fetchApiKeyData(id);
      this.setInitialData(data);
    } catch (err) {
      runInAction(() => {
        this.errors = err;
      });
      handleErrorResponse(err);
    }
  }

  async addApiKey() {
    const { data, setFieldsErrors } = stores.forms.apiKeyForm;
    runInAction(() => {
      this.requestInProgress = true;
      this.errors = null;
    });
    try {
      const { data: plainApiKey } = await addApiKey(data);
      notifier.success(text.ADD_SUCCESS);
      runInAction(() => {
        this.plainApiKey = plainApiKey;
        this.errors = null;
      });
    } catch (err) {
      runInAction(() => {
        this.errors = err;
      });
      if (err?.response?.data) {
        setFieldsErrors(err.response.data);
      }
      handleErrorResponse(err, text.ERROR);
    } finally {
      runInAction(() => {
        this.requestInProgress = false;
      });
    }
  }

  async editApiKey() {
    runInAction(() => {
      this.requestInProgress = true;
      this.errors = null;
    });
    const { data, setFieldsErrors } = stores.forms.apiKeyForm;
    try {
      await editApiKey(data.id, data);
      notifier.success(text.EDIT_SUCCESS);
    } catch (err) {
      runInAction(() => {
        this.errors = err;
      });
      if (err?.response?.data) {
        setFieldsErrors(err.response.data);
      }
      handleErrorResponse(err, text.ERROR);
    } finally {
      runInAction(() => {
        this.requestInProgress = false;
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async deleteApiKey(id) {
    try {
      await deleteApiKey(id);
      notifier.success(text.DELETE_API_KEY);
    } catch (err) {
      handleErrorResponse(err, text.ERROR);
    }
  }

  resetPlainApiKey() {
    this.plainApiKey = null;
  }
}
