import { action, observable, runInAction, makeObservable } from 'mobx';
import { isEmpty } from 'lodash';
import { notifier } from 'tc-biq-design-system';

import {
  editDocumentType,
  addDocumentType,
  fetchDocumentTypeData,
} from 'Settings/Sections/Documents/services/DocumentsService';
import stores from 'App/rootStore';
import { handleErrorResponse } from 'App/services/utilities/error.utils';

const text = {
  EDIT_SUCCESS: 'Edited Document type successfully',
  ADD_SUCCESS: 'Created Document type successfully',
};

export default class DocumentTypesStore {
  requestInProgress = false;

  errors = null;

  constructor() {
    makeObservable(this, {
      requestInProgress: observable,
      errors: observable,
      setInitialData: action.bound,
      fetchDocumentTypeData: action.bound,
      editDocumentType: action.bound,
      addDocumentType: action.bound,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  setInitialData(initialData) {
    const { data, setFieldsData } = stores.forms.documentTypeForm;
    if (isEmpty(data)) setFieldsData(initialData);
  }

  async fetchDocumentTypeData(id) {
    runInAction(() => {
      this.errors = null;
    });
    try {
      const { data } = await fetchDocumentTypeData(id);
      this.setInitialData(data);
    } catch (err) {
      runInAction(() => {
        this.errors = err;
      });
      handleErrorResponse(err);
    }
  }

  async editDocumentType() {
    runInAction(() => {
      this.requestInProgress = true;
      this.errors = null;
    });
    const { data, setFieldsErrors } = stores.forms.documentTypeForm;
    try {
      await editDocumentType(data.id, data);
      notifier.success(text.EDIT_SUCCESS);
    } catch (err) {
      runInAction(() => {
        this.errors = err;
      });
      if (err?.response?.data) {
        setFieldsErrors(err.response.data);
      }
      handleErrorResponse(err);
    } finally {
      runInAction(() => {
        this.requestInProgress = false;
      });
    }
  }

  async addDocumentType() {
    const { data, setFieldsErrors } = stores.forms.documentTypeForm;
    runInAction(() => {
      this.requestInProgress = true;
      this.errors = null;
    });
    try {
      await addDocumentType(data);
      notifier.success(text.ADD_SUCCESS);
      runInAction(() => {
        this.errors = null;
      });
    } catch (err) {
      runInAction(() => {
        this.errors = err;
      });

      if (err?.response?.data) {
        setFieldsErrors(err.response.data);
      }
      handleErrorResponse(err);
    } finally {
      runInAction(() => {
        this.requestInProgress = false;
      });
    }
  }
}
