import { bool, func, number, object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Sidepanel } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';

/**
 *  BIQ Action Component
 */

const propTypes = {
  sidepanelManager: object.isRequired,
  requestInProgress: bool.isRequired,
  fetchLoginAsContactToken: func.isRequired,
  errors: object.isRequired,
};

const getPortalUrl = (token, domain, isIb) => `${!domain ? DEMO_HOST : `//${domain}`}/#/auth/widget?token=${token}${isIb ? '&type=ib' : ''}`;

const LoginAsContact = ({
  sidepanelManager,
  fetchLoginAsContactToken,
  requestInProgress,
  errors,
  ...options
}) => {
  const [url, setUrl] = useState('');

  useEffect(async () => {
    const urlWithToken = await fetchLoginAsContactToken();
    setUrl(urlWithToken);
  }, []);

  return (
    <Sidepanel
      {...options}
      footerRender={() => null}
    >
      <a target="_blank" rel="noopener noreferrer" href={getPortalUrl(url, '', false)}>
        <span style={{ color: '#006BE6' }}>Click here to login as user.</span>
      </a>
    </Sidepanel>
  );
};

LoginAsContact.propTypes = propTypes;

export default inject(stores => ({
  errors: stores.contact.profile.errors,
  fetchLoginAsContactToken: stores.contact.profile.fetchLoginAsContactToken,
  requestInProgress: stores.contact.profile.requestInProgress.loginAsContactToken,
}))(observer(LoginAsContact));
