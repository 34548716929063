import { action, makeObservable, observable, runInAction } from 'mobx';
import { notifier } from 'tc-biq-design-system';
import { handleErrorResponse } from 'App/services/utilities/error.utils';
import { formatBiqFields } from 'App/services/utilities/formatters/format-biq-fields';
import {
  fetchTradingBackends,
  fetchTradingAccountOptions,
  fetchTradingAccounts,
  fetchAllowedAccounts,
  fetchLeverages,
  fetchLabels,
  createTradingAccount,
  editTradingAccount,
} from './tradingAccountService';

const text = {
  CREATE_TRADING_ACCOUNT_SUCCESS: 'You have successfully created trading account.',
  CREATE_TRADING_ACCOUNT_FAILED: 'Failed to create trading account!',
  EDIT_TRADING_ACCOUNT_SUCCESS: 'You have successfully edited trading account.',
  EDIT_TRADING_ACCOUNT_FAILED: 'Failed to edit trading account!',
  TRADING_BACKENDS_FAILED: 'Failed to fetch trading backends!',
  TRADING_ACCOUNTS_FAILED: 'Failed to fetch trading accounts!',
  LEVERAGES_FAILED: 'Failed to fetch leverages!',
  LABELS_FAILED: 'Failed to fetch labels!',
  ALLOWED_ACCOUNTS_FAILED: 'Failed to fetch allowed accounts!',
  TRADING_ACCOUNTS_OPTIONS_FAILED: 'Failed to fetch trading accounts options!',
};

/**
 * TradingAccountStore used for creating/editing trading accounts
 * Also, used for handling BIQ Actions
 */
export default class TradingAccountStore {
  tradingBackends = [];

  tradingAccounts = [];

  leverages = [];

  labels = [];

  allowedAccounts = [];

  tradingAccountFields = {};

  requestInProgress = {
    createTradingAccount: false,
    editTradingAccount: false,
    tradingBackends: false,
    tradingAccounts: false,
    leverages: false,
    labels: false,
    allowedAccounts: false,
    tradingAccountFields: false,
  };

  errors = {
    createTradingAccount: null,
    editTradingAccount: null,
    tradingBackends: null,
    tradingAccounts: null,
    leverages: null,
    labels: null,
    allowedAccounts: null,
    tradingAccountFields: null,
  };

  constructor() {
    makeObservable(this, {
      tradingAccountFields: observable,
      tradingBackends: observable,
      tradingAccounts: observable,
      leverages: observable,
      labels: observable,
      allowedAccounts: observable,
      requestInProgress: observable,
      errors: observable,
      fetchTradingAccountFields: action.bound,
      fetchTradingBackends: action.bound,
      fetchTradingAccounts: action.bound,
      fetchLeverages: action.bound,
      fetchLabels: action.bound,
      fetchAllowedAccounts: action.bound,
      createTradingAccount: action.bound,
      editTradingAccount: action.bound,
    });
  }

  async fetchTradingAccountFields(external_id) {
    this.requestInProgress.tradingAccountFields = true;

    try {
      const response = await fetchTradingAccountOptions(external_id);
      const formFields = formatBiqFields(response.data.actions.POST.fields);

      runInAction(() => {
        this.tradingAccountFields = formFields;
        this.requestInProgress.tradingAccountFields = false;
      });
    } catch (error) {
      handleErrorResponse(error, text.TRADING_ACCOUNTS_OPTIONS_FAILED);
      runInAction(() => {
        this.requestInProgress.tradingAccountFields = false;
      });
    }
  }

  async fetchTradingBackends() {
    this.requestInProgress.tradingBackends = true;

    try {
      const response = await fetchTradingBackends();

      runInAction(() => {
        this.tradingBackends = response.data.results.map(backend => ({
          ...backend,
          value: backend.backend.id,
          display_name: `${backend.backend.name} (${backend.backend.mode})`,
        }));
        this.requestInProgress.tradingBackends = false;
      });
    } catch (err) {
      handleErrorResponse(err, text.TRADING_BACKENDS_FAILED);
      runInAction(() => {
        this.errors.tradingBackends = err.response.data;
        this.requestInProgress.tradingBackends = false;
      });
    }
  }

  async fetchTradingAccounts(external_id) {
    this.requestInProgress.tradingAccounts = true;

    try {
      const response = await fetchTradingAccounts(external_id);
      runInAction(() => {
        this.tradingAccounts = response.data.results;
        this.requestInProgress.tradingAccounts = false;
      });
    } catch (err) {
      handleErrorResponse(err, text.TRADING_ACCOUNTS_FAILED);
      runInAction(() => {
        this.errors.tradingAccounts = err.response.data;
        this.requestInProgress.tradingAccounts = false;
      });
    }
  }

  async fetchLeverages(backend_id) {
    this.requestInProgress.leverages = true;

    try {
      const response = await fetchLeverages(backend_id);
      runInAction(() => {
        this.leverages = response.data;
        this.requestInProgress.leverages = false;
      });
    } catch (err) {
      handleErrorResponse(err, text.LEVERAGES_FAILED);
      runInAction(() => {
        this.errors.leverages = err.response.data;
        this.requestInProgress.leverages = false;
      });
    }
  }

  async fetchLabels() {
    this.requestInProgress.labels = true;

    try {
      const response = await fetchLabels();
      runInAction(() => {
        this.labels = response.data.results;
        this.requestInProgress.labels = false;
      });
    } catch (err) {
      handleErrorResponse(err, text.LABELS_FAILED);
      runInAction(() => {
        this.errors.labels = err.response.data;
        this.requestInProgress.labels = false;
      });
    }
  }

  async fetchAllowedAccounts() {
    this.requestInProgress.allowedAccounts = true;

    try {
      const response = await fetchAllowedAccounts();
      runInAction(() => {
        this.allowedAccounts = response.data;
        this.requestInProgress.allowedAccounts = false;
      });
    } catch (err) {
      handleErrorResponse(err, text.ALLOWED_ACCOUNTS_FAILED);
      runInAction(() => {
        this.errors.allowedAccounts = err.response.data;
        this.requestInProgress.allowedAccounts = false;
      });
    }
  }

  async createTradingAccount(external_id, request) {
    this.requestInProgress.createTradingAccount = true;

    try {
      await createTradingAccount(external_id, request);
      runInAction(() => {
        this.requestInProgress.createTradingAccount = false;
      });
      notifier.success(text.CREATE_TRADING_ACCOUNT_SUCCESS);
    } catch (err) {
      runInAction(() => {
        this.errors.createTradingAccount = err.response.data;
        this.requestInProgress.createTradingAccount = false;
      });
    }
  }

  async editTradingAccount(external_id, account_id, request) {
    this.requestInProgress.editTradingAccount = true;

    try {
      await editTradingAccount(external_id, account_id, request);
      runInAction(() => {
        this.requestInProgress.editTradingAccount = false;
      });
      notifier.success(text.EDIT_TRADING_ACCOUNT_SUCCESS);
    } catch (err) {
      runInAction(() => {
        this.errors.editTradingAccount = err.response.data;
        this.requestInProgress.editTradingAccount = false;
      });
    }
  }
}
