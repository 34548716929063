import LoginStore from 'Auth/store/LoginStore';
import contact from 'Contacts/store';
import UsersStore from 'Settings/Sections/Users/store/UsersStore';
import TranslateStore from 'App/rootStore/TranslateStore';
import OverlayStore from 'App/rootStore/OverlayStore';
import TransactionsStore from 'Transactions/store/TransactionsStore';
import IntegrationsStore from 'Settings/Sections/Integrations/store/IntegrationsStore';
import TasksStore from 'Tasks/store/TasksStore';
import ActivityStreamStore from 'ActivityStream/store/ActivityStreamStore';
import FiltersStore from 'App/components/Filters/FiltersStore';
import RolesStore from 'Settings/Sections/Roles/store/RolesStore';
import PermissionStore from 'App/rootStore/PermissionStore';
import NavigatorStore from 'App/rootStore/NavigatorStore';
import ContactAccessGroupStore from 'Settings/Sections/ContactAccessGroup/store/ContactAccessGroupStore';
import QueryBuilderStore from 'App/components/QueryBuilderFactory/QueryBuilderStore';
import ManageUsersStore from 'App/components/ManageUsersFactory/ManageUsersStore';
import TeamsStore from 'Settings/Sections/Teams/store/TeamsStore';
import DocumentTypesStore from 'Settings/Sections/Documents/store/DocumentTypesStore';
import RulesStore from 'Automation/store/RulesStore';
import WebhooksStore from 'Settings/Sections/Webhooks/store/WebhooksStore';
import UserNotificationsStore from 'UserNotifications/store/UserNotificationsStore';
import WorkflowsStore from 'Marketing/store/WorkflowsStore';
import RecipientsStore from 'Marketing/store/RecipientsStore';
import TrackingLinksStore from 'Settings/Sections/TrackingLinks/store/TrackingLinksStore';
import SegmentsStore from 'Automation/store/SegmentsStore';
import DashboardStore from 'Dashboard/store/DashboardStore';
import TemplatesStore from 'Communications/store/TemplatesStore';
import CustomPropertiesStore from 'Settings/Sections/CustomProperties/store/CustomPropertiesStore';
import ApiKeysStore from 'Settings/Sections/ApiKeys/store/ApiKeysStore';
import EventsStore from 'Settings/Sections/Events/store/EventsStore';

import * as UsersService from 'Settings/Sections/Users/services/UsersService';

import forms from 'App/rootStore/formsStore';
import tables from 'App/rootStore/tablesStore';
import CustomActionsStore from 'Automation/store/CustomActionsStore';

export interface StoreInterface {
  [key: string]: any
}

export default {
  translateStore: new TranslateStore(),
  permissions: new PermissionStore(),
  navigator: new NavigatorStore(),
  loginStore: new LoginStore(),
  overlayStore: new OverlayStore(),
  contact,
  activityStream: new ActivityStreamStore(new FiltersStore()),
  tasks: new TasksStore(new FiltersStore()),
  usersStore: new UsersStore(UsersService, new ManageUsersStore('communications/email-inbox')),
  transactions: new TransactionsStore(),
  integrations: new IntegrationsStore(),
  teams: new TeamsStore(new ManageUsersStore('users/teams')),
  roles: new RolesStore(new ManageUsersStore('users/roles')),
  documentTypes: new DocumentTypesStore(),
  cag: new ContactAccessGroupStore(
    new QueryBuilderStore(),
    new ManageUsersStore('contact-access-groups'),
  ),
  rules: new RulesStore(new QueryBuilderStore()),
  customActions: new CustomActionsStore(),
  userNotifications: new UserNotificationsStore(new FiltersStore()),
  webhooks: new WebhooksStore(),
  segments: new SegmentsStore(new QueryBuilderStore()),
  workflows: new WorkflowsStore(
    new QueryBuilderStore(),
    new QueryBuilderStore(),
    new QueryBuilderStore(),
  ),
  templates: new TemplatesStore(new QueryBuilderStore()),
  recipients: new RecipientsStore(new QueryBuilderStore()),
  dashboardStore: new DashboardStore(),
  trackingLinks: new TrackingLinksStore(),
  customProperties: new CustomPropertiesStore(),
  apiKeys: new ApiKeysStore(),
  tables,
  forms,
  events: new EventsStore(new QueryBuilderStore()),
};
