import { observable, action, makeObservable } from 'mobx';
import * as CommandbarService from 'App/services/commandbarService';
// NOTE: Namespace defined on the Auth0 that's used on the ID tokens to
// retrieve additional attributes.
const AUTH0_ID_TOKEN_NAMESPACE = 'https://infuse.trdcr.tech/';

type CallbackFunction = () => void;

type Auth0User = {
  email: string,
  email_verified: boolean,
  name: string,
  nickname: string,
  picture: string,
  sub: string,
  updated_at: string,
  'https://infuse.trdcr.tech/team': string,
}

interface InfuseUser {
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  id: number;
  picture: string;
  updated_at: string;
  'https://infuse.trdcr.tech/team': string;
}

export interface ILoginStore {
  requestSuccess: boolean;
  user: InfuseUser;
  getToken: CallbackFunction | null;
  logout: CallbackFunction | null;
}

export default class LoginStore implements ILoginStore {
  requestSuccess = false;

  user: InfuseUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : {};

  getToken: CallbackFunction | null = null;

  logout: CallbackFunction | null = null;

  returnToAfterLogin: string | null = localStorage.getItem('returnToAfterLogin') ?? null;

  constructor() {
    makeObservable(this, {
      requestSuccess: observable,
      user: observable,
      returnToAfterLogin: observable,
      getToken: observable,
      logout: observable,
      setUserData: action.bound,
      setGetTokenFunc: action.bound,
      setLogoutFunc: action.bound,
      setReturnToAfterLogin: action.bound,
    });
  }

  setUserData(auth0_user: Auth0User) {
    const { sub, ...data } = auth0_user;
    // Extracts ID of the user in Infuse system by parsing auth0 format.
    const id = Number(sub.split('|').pop());
    const team = (data as Partial<Auth0User>)[`${AUTH0_ID_TOKEN_NAMESPACE}team` as keyof Auth0User];
    const user = { id, team, ...data };
    this.user = user;
    localStorage.setItem('user', JSON.stringify(user));
    CommandbarService.assignUser(user);
    CommandbarService.addContext({
      userId: user.id,
    });
  }

  setGetTokenFunc(func: CallbackFunction) {
    this.getToken = func;
  }

  setLogoutFunc(func: CallbackFunction) {
    this.logout = func;
  }

  setReturnToAfterLogin(returnToAfterLogin: string | null) {
    this.returnToAfterLogin = returnToAfterLogin;

    if (returnToAfterLogin) {
      localStorage.setItem('returnToAfterLogin', returnToAfterLogin);
    } else {
      localStorage.removeItem('returnToAfterLogin');
    }
  }
}
