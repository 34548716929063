import React, { Fragment } from 'react';
import {
  Space,
  MultiActions,
  Button,
  notifier,
  Icon,
  Tooltip,
} from 'tc-biq-design-system';
import { func, object, bool } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { StatusCell } from 'App/components/gridCellRenderers';
import { isEmpty } from 'lodash';
import { hasAccess } from 'App/services/permissionsService';
import { startWorkflow, endWorkflow, deleteWorkflow, cloneWorkflow } from 'Marketing/services/WorkflowService';
import run from 'App/services/utilities/run';
import displayError from 'App/services/utilities/displayError';

import GoBack from 'App/components/GoBack';

import './WorkflowHeader.scss';

const text = {
  BUTTON: 'Workflow details',
  EDIT: 'Edit',
  CLONE: 'Clone',
  START: 'Start',
  END: 'End workflow',
  DELETE: 'Delete workflow',
  PREVIEW: 'Preview message',
  START_SUCCESS: 'Successfully started workflow',
  START_FAILED: 'Failed to start workflow',
  END_SUCCESS: 'Successfully ended workflow',
  END_FAILED: 'Failed to end workflow',
  DELETE_SUCCESS: 'Successfully deleted workflow',
  DELETE_FAILED: 'Field to delete workflow',
  CLONE_SUCCESS: 'Successfully cloned workflow',
  CLONE_FAILED: 'Failed to clone workflow',
  TOOLTIP_MESSAGE: 'Data inaccuracy is possible, due to setup of mail client or ad block.',
  TOOLTIP_HEADER: 'The workflow KPIs will be updated automatically every 15 min.',
};

const propTypes = {
  workflow: object.isRequired,
  history: object.isRequired,
  openDetails: func,
  goBackIcon: bool,
};

const defaultProps = {
  goBackIcon: false,
  openDetails: () => null,
};

const actions = ({ workflow, history, end, start, remove, clone }) => [
  {
    data: workflow.id,
    label: text.EDIT,
    action: workflowId => history.push(`/marketing/workflows/${workflowId}`),
    icon: 'Edit',
    visibility: workflow.status === 'Draft' && hasAccess('marketing_workflow', 'update'),
  },
  {
    data: workflow.id,
    label: text.CLONE,
    action: workflowId => clone(workflowId),
    icon: 'Duplicate',
    visibility: hasAccess('marketing_workflow', 'create'),
  },
  {
    data: workflow.id,
    label: text.END,
    action: workflowId => end(workflowId),
    icon: 'Close',
    visibility: workflow.status === 'Ongoing' && hasAccess('marketing_workflow', 'update'),
  },
  {
    data: workflow.id,
    label: text.START,
    action: workflowId => start(workflowId),
    icon: 'Play',
    visibility: workflow.status === 'Draft' && hasAccess('marketing_workflow', 'update'),
  },
  {
    data: workflow.id,
    label: text.DELETE,
    action: workflowId => remove(workflowId),
    icon: 'Delete',
    visibility: workflow.status === 'Draft' && hasAccess('marketing_workflow', 'delete'),
  },
].filter(action => action.visibility);


const PageHeader = ({
  goBackIcon,
  openDetails,
  workflow,
  history,
}) => {
  if (isEmpty(workflow)) return null;

  const { name, status } = workflow;

  const start = async (id) => {
    const [err] = await run(startWorkflow(id));
    if (err) {
      displayError(err.response.data);
      return notifier.error(text.START_FAILED);
    }
    history.push('/marketing/workflows');
    return notifier.success(text.START_SUCCESS);
  };

  const end = async (id) => {
    try {
      await endWorkflow(id);
      history.push('/marketing/workflows');
      notifier.success(text.END_SUCCESS);
    } catch {
      notifier.error(text.END_FAILED);
    }
  };

  const clone = async (id) => {
    const [err, data] = await run(cloneWorkflow(id));
    if (err) {
      displayError(err.response.data);
      return notifier.error(text.CLONE_FAILED);
    }
    history.push('/marketing/workflows');
    history.push(`/marketing/workflows/${data.id}`);
    return notifier.success(text.CLONE_SUCCESS);
  };

  const remove = async (id) => {
    try {
      await deleteWorkflow(id);
      history.push('/marketing/workflows');
      notifier.success(text.DELETE_SUCCESS);
    } catch {
      notifier.error(text.DELETE_FAILED);
    }
  };

  return (
    <div className="workflow-header">
      <div className="workflow-header__title">
        {goBackIcon && (
          <Fragment>
            <Space size={12} />
            <GoBack />
          </Fragment>
        )}
        <p className="workflow-header__page-title text-neutral-500">{name}</p>
        <StatusCell value={status} />
        <Tooltip
          title={text.TOOLTIP_HEADER}
          placement="bottom"
        >
          <Icon colorName="text-neutral-500" size="regular" name="Info" />
        </Tooltip>
        <div className="workflow-header__action-wrapper">
          <Button icon="View" size="small" color="transparent" onClick={openDetails}>
            {text.BUTTON}
          </Button>
          <MultiActions
            actions={actions({
              workflow,
              history,
              end,
              remove,
              start,
              clone,
            })}
          />
        </div>
      </div>
    </div>
  );
};

PageHeader.propTypes = propTypes;
PageHeader.defaultProps = defaultProps;

export default withRouter(PageHeader);
