import React, { useEffect, useMemo } from 'react';
import { Space, Row, Col } from 'tc-biq-design-system';
import { func, bool, object, string } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';

import Field, { fieldsResolverObject } from 'App/components/FieldRenderer';
import Loader from 'App/components/Loader';
import { states } from 'Marketing/utils/workflow';

import WorkflowFormFooter from './WorkflowFormFooter';
import SectionWrapper from './SectionWrapper';
import RecurringForm from './RecurringForm';
import FieldSectionTitle from './FieldSectionTitle';
import SendToFields from './SendToFields';
import TimeFrameFrom from './TimeFrameForm';
import EventQueryBuilder from './EventQueryBuilder/EventQueryBuilder';

import './WorkflowForm.scss';

const propTypes = {
  loadingData: bool.isRequired,
  fieldsDef: object.isRequired,
  fetchFieldsDef: func.isRequired,
  match: object.isRequired,
  resetForm: func.isRequired,
  send_to_type: object,
  onToggle: func,
  state: string,
  loadingOptions: bool,
};

const defaultProps = {
  send_to_type: null,
  onToggle: null,
  state: states.CREATE,
  loadingOptions: false,
};

const SEND_TO_RECURRING_TYPES = ['All contacts', 'Contacts in', 'Contacts not in'];

const text = {
  DETAILS: 'Workflow Details',
  DESCRIPTION: 'Define who will enter the workflow journey.',
  NAME: 'Workflow name',
  DESCRIPTION_HELP: 'Brief description for your workflow.',
  MESSAGE: 'Message',
  GOAL: 'Goal',
  HOURS: 'Hour(s)',
  DAYS: 'Day (s)',
  TIMEFRAME: 'Timeframe',
  EVENT: 'Event',
  ATTRIBUTION_WINDOW: 'Attribution Window',
  WHOLE_NUMBER_ERROR: 'Only whole numbers are supported.',
  TO: {
    ALL: 'All contacts',
    IN: 'Contacts in',
    ENTERED: 'Contacts who entered',
    EXITED: 'Contacts who exited',
  },
  FREQUENCY: 'Frequency',
};

const WorkflowForm = ({
  loadingData,
  loadingOptions,
  resetForm,
  fetchFieldsDef,
  fieldsDef,
  send_to_type,
  state,
}) => {
  const fieldsProps = useMemo(() => fieldsResolverObject(fieldsDef), [fieldsDef]);

  useEffect(() => {
    if (isEmpty(fieldsDef) && !loadingOptions) fetchFieldsDef();
  }, [fieldsDef]);

  useEffect(() => () => resetForm(), []);

  // Handle the case of value from the API and from the select (choice) field.
  const sendToType = send_to_type && (send_to_type.value || send_to_type);

  if (loadingData || loadingOptions || isEmpty(fieldsDef)) return <Loader visible />;
  return (
    <Row gutter={0}>
      <div className="fiq-workflow-form">
        <Col xs="100%" md="90%" lg="60%">
          <Space size={20} />
          {state !== states.GOAL && (
            <>
              <FieldSectionTitle title={text.DETAILS} description={text.DESCRIPTION} />
              <SectionWrapper>
                <SectionWrapper.FieldWrapper hideBorder>
                  <Field
                    {...fieldsProps.name}
                    type="text"
                    label={text.NAME}
                    formId="workflowForm"
                  />
                  <Field
                    {...fieldsProps.description}
                    formId="workflowForm"
                    type="textarea"
                    helpText={text.DESCRIPTION_HELP}
                  />
                </SectionWrapper.FieldWrapper>
              </SectionWrapper>
            </>
          )}
          {state === states.DETAILS && (
            <>
              <Space size={30} />
              <SectionWrapper>
                <SendToFields
                  typeProps={fieldsProps.send_to_type}
                  segmentProps={fieldsProps.send_to_segment}
                  allowManualEnrollmentProps={fieldsProps.allow_manual_enrollment}
                />
                {sendToType && SEND_TO_RECURRING_TYPES.includes(sendToType) && (
                  <SectionWrapper.FieldWrapper>
                    <FieldSectionTitle title={text.FREQUENCY} />
                    <RecurringForm fieldsProps={fieldsProps} />
                  </SectionWrapper.FieldWrapper>
                )}
                <SectionWrapper.FieldWrapper hideBorder>
                  <FieldSectionTitle title={text.TIMEFRAME} />
                  <TimeFrameFrom fieldsProps={fieldsProps} sendToType={sendToType} />
                </SectionWrapper.FieldWrapper>
              </SectionWrapper>
            </>
          )}
          {state === states.GOAL && (
            <SectionWrapper>
              <SectionWrapper.FieldWrapper hideBorder>
                <FieldSectionTitle title={text.GOAL} />
                <div style={{ display: 'flex' }}>
                  <Field
                    {...fieldsProps.goal_event_type}
                    label={text.EVENT}
                    style={{ width: '270px' }}
                    formId="workflowForm"
                  />
                  <Space size={16} />
                  <Field
                    {...fieldsProps.goal_attribution_window}
                    label={text.ATTRIBUTION_WINDOW}
                    formId="workflowForm"
                  />
                </div>
                <EventQueryBuilder />
              </SectionWrapper.FieldWrapper>
            </SectionWrapper>
          )}
          <WorkflowFormFooter state={state} />
        </Col>
      </div>
    </Row>
  );
};

WorkflowForm.propTypes = propTypes;
WorkflowForm.defaultProps = defaultProps;

export default inject(stores => ({
  send_to_type: stores.forms.workflowForm.data.send_to_type,
  loadingOptions: stores.workflows.requestInProgress.fetchOptions,
  loadingData: stores.workflows.requestInProgress.fetchWorkflow,
  fetchFieldsDef: stores.workflows.fetchWorkflowsOptions,
  fieldsDef: stores.workflows.fieldsDef,
  resetForm: stores.workflows.resetForm,
  initialChannel: stores.workflows.workflow?.channel,
}))(observer(withRouter(WorkflowForm)));
