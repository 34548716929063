import React from 'react';
import { Button } from 'tc-biq-design-system';
import { withRouter } from 'react-router-dom';
import { object } from 'prop-types';

import Page from 'App/components/Page';
import { hasAccess } from 'App/services/permissionsService';
import CustomActionsTable from 'Automation/components/CustomActionsTable';

const propTypes = {
  history: object.isRequired,
};

const text = {
  TITLE: 'Custom Actions',
  CREATE: 'Create custom action',
};

const bread = [
  { label: 'Automation' },
  { label: text.TITLE },
];

const CustomActionsPage = ({ history }) => {
  const headerActions = () => {
    if (hasAccess('automations_rule', 'create')) {
      return <Button onClick={() => history.push('/automation/custom-actions/create')}>{text.CREATE}</Button>;
    }
    return null;
  };

  return (
    <Page bread={bread} headerActions={headerActions} title={text.TITLE} style={{ margin: '0 20px' }}>
      <CustomActionsTable />
    </Page>
  );
};

CustomActionsPage.propTypes = propTypes;

export default withRouter(CustomActionsPage);
