import actionTypes from 'Contacts/components/ContactActions/actions/types';
import AddToExclusionList
  from 'Contacts/components/ContactActions/actions/biq-actions/AddToExclusionList/AddToExclusionList';
import { CONTACT_STATUS } from 'App/enums/contactStatus';
import { hasAccess } from 'App/services/permissionsService';

const text = {
  LABEL: 'Add to exclusion list',
};

const ACTION_ICON = 'Logs';

export default {
  name: 'addToExclusionList',
  label: text.LABEL,
  icon: ACTION_ICON,
  type: actionTypes.DROPDOWN,
  hasPermission: status => ([
    CONTACT_STATUS.FULL,
    CONTACT_STATUS.PENDING,
    CONTACT_STATUS.LIMITED,
    CONTACT_STATUS.REVIEW,
    CONTACT_STATUS.LEAD,
    CONTACT_STATUS.PENDING_EMAIL_VERIFICATION,
    CONTACT_STATUS.PENDING_SMS_VERIFICATION,
    CONTACT_STATUS.DEMO,
  ].includes(status) && hasAccess('contacts_contact', 'update')),
  handler: (sidepanelManager) => {
    const sidepanelOptions = {
      icon: ACTION_ICON,
      title: text.LABEL,
      sidepanelManager,
      visible: true,
    };

    sidepanelManager.add(AddToExclusionList, sidepanelOptions);
  },
};
