import React, { Fragment, useEffect, useCallback, useMemo, useState } from 'react';
import { Sidepanel, Space, Button, notifier } from 'tc-biq-design-system';
import { inject, observer } from 'mobx-react';
import { any, func, object } from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { closeOverlay } from 'App/services/overlayService';
import Field, { fieldsResolver } from 'App/components/FieldRenderer';


const SIDEPANEL_ID = 'EDIT_USER_DETAILS';

const text = {
  DISCARD: 'Discard',
  EDIT_USER: 'Edit user',
  SUCCESS: 'Successfully edited user details',
};

const IGNORED_FIELDS = ['id', 'is_deleted', 'biq_team', 'object_acl_rules'];

const propTypes = {
  visible: any,
  parameters: object,
  fetchUserFields: func.isRequired,
  setEditUserDetailsData: func.isRequired,
  userFields: object.isRequired,
  editUserDetails: func.isRequired,
  onSuccess: func.isRequired,
  setFieldsErrors: func.isRequired,
  resetFieldsData: func.isRequired,
  resetUserFields: func.isRequired,
  loggedUser: object.isRequired,
};

const defaultProps = { visible: null, parameters: {} };

const fieldNames = {
  TEAM: 'team_binding__team',
  CAG: 'contact_access_group_bindings__contact_access_group',
  ROLE: 'role_bindings__role',
};


// eslint-disable-next-line react/prop-types
const CustomFooter = ({ execute, cancel }) => (
  <Fragment>
    <Button color="ghost" onClick={cancel}>
      {text.DISCARD}
    </Button>
    <Button onClick={execute}>
      {text.EDIT_USER}
    </Button>
  </Fragment>
);


const EditUserDetails = ({
  visible,
  userFields,
  parameters,
  resetUserFields,
  resetFieldsData,
  editUserDetails,
  onSuccess,
  setFieldsErrors,
  loggedUser,
  setEditUserDetailsData,
  fetchUserFields,
}) => {
  const [isSelfEdit, setIsSelfEdit] = useState(false);
  const userId = parameters?.id;
  const close = useCallback(() => {
    closeOverlay(SIDEPANEL_ID);
    resetUserFields();
    resetFieldsData();
  }, [resetFieldsData, closeOverlay, resetUserFields]);

  const edit = useCallback(async () => {
    try {
      await editUserDetails(userId, loggedUser.id === userId);
      await onSuccess();
      notifier.success(text.SUCCESS);
      close();
    } catch (err) {
      if (err.response && err.response.data) setFieldsErrors(err.response.data);
    }
  }, [userId, editUserDetails, onSuccess, close, setFieldsErrors, loggedUser.id]);

  const formattedFields = isEmpty(userFields) ? [] : fieldsResolver(userFields);
  const isEdit = !!userId;

  useEffect(() => {
    if (isEmpty(formattedFields)) {
      fetchUserFields();
    }

    if (isEdit) {
      setEditUserDetailsData(userId);
      setIsSelfEdit(userId === loggedUser.id);
    }
  }, [isEdit, userId, loggedUser.id, formattedFields,
    fetchUserFields, setEditUserDetailsData, setIsSelfEdit]);

  useEffect(() => () => resetFieldsData(), []);
  if (!visible) return null;

  return (
    <Sidepanel
      title={text.EDIT_USER}
      type="info"
      icon="Pen"
      visible={visible}
      onCloseIconClick={close}
      footerRender={() => <CustomFooter execute={edit} cancel={close} />}
    >
      <Space size={18} />
      {formattedFields
        .filter(({ id }) => !IGNORED_FIELDS.includes(id))
        .map(({ id, read_only, ...props }) => {
          if (id === fieldNames.TEAM) {
            return (
              <Field
                {...props}
                disabled={isSelfEdit}
                validate="required"
                key={id}
                formId="editUser"
                label="Team"
              />
            );
          }
          if (id === fieldNames.CAG) {
            return (
              <Field
                {...props}
                disabled={isSelfEdit}
                key={id}
                formId="editUser"
                label="Contact access group"
                validate="required"
                multi
              />
            );
          }
          if (id === fieldNames.ROLE) {
            return (
              <Field
                {...props}
                disabled={isSelfEdit}
                key={id}
                formId="editUser"
                label="Role"
                validate="required"
                multi
              />
            );
          }
          return <Field {...props} disabled={read_only} key={id} formId="editUser" />;
        })}
    </Sidepanel>
  );
};

EditUserDetails.propTypes = propTypes;
EditUserDetails.defaultProps = defaultProps;

export default inject(stores => ({
  visible: stores.overlayStore.overlay.EDIT_USER_DETAILS,
  parameters: stores.overlayStore.overlay.parameters,
  fetchUserFields: stores.usersStore.fetchUserFields,
  setEditUserDetailsData: stores.usersStore.setEditUserDetailsData,
  userFields: stores.usersStore.userFields,
  setFieldsErrors: stores.forms.editUser.setFieldsErrors,
  resetFieldsData: stores.forms.editUser.resetFieldsData,
  editUserDetails: stores.usersStore.editUserDetails,
  resetUserFields: stores.usersStore.resetUserFields,
  loggedUser: stores.loginStore.user,
}))(observer(EditUserDetails));
