import React from 'react';
import { NavCard } from 'tc-biq-design-system';
import { number, oneOfType, string } from 'prop-types';
import WorkflowsTable from 'Marketing/components/WorkflowsTable';
import AudiencesTable from './AudiencesTable';
import SegmentActivityStream from './SegmentActivityStream';

const propTypes = {
  segmentId: oneOfType([number, string]).isRequired,
};

const tabTypes = {
  AD_AUDIENCES: 'ad_audiences',
  WORKFLOWS: 'workflows',
  ACTIVITY_STREAM: 'activity_stream',
};

const tabs = [
  { title: 'Ad Audiences', id: tabTypes.AD_AUDIENCES },
  { title: 'Workflows', id: tabTypes.WORKFLOWS },
  { title: 'Activity Stream', id: tabTypes.ACTIVITY_STREAM },
];

const SegmentTabs = ({ segmentId }) => (
  <div>
    <NavCard tabs={tabs}>
      {(active) => {
        if (active === tabTypes.AD_AUDIENCES) { return <AudiencesTable segmentId={segmentId} />; }
        if (active === tabTypes.WORKFLOWS) return <WorkflowsTable />;
        if (active === tabTypes.ACTIVITY_STREAM) return <SegmentActivityStream id={segmentId} />;
        return null;
      }}
    </NavCard>
  </div>
);

SegmentTabs.propTypes = propTypes;

export default SegmentTabs;
