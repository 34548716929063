import { action, observable, runInAction, makeObservable } from 'mobx';
import { omit } from 'lodash';
import { notifier } from 'tc-biq-design-system';

import stores from 'App/rootStore';
import {
  fetchTeamsData,
  fetchTeamData,
  fetchTeamsOptions,
  createTeam,
  editTeam,
  deleteTeam,
} from 'Settings/Sections/Teams/services/TeamsService';
import { parseParamString } from 'App/components/Filters/filterStoreUtils';
import formatPayload from 'App/services/utilities/formatPayload';
import { handleErrorResponse } from 'App/services/utilities/error.utils';

export default class TeamsStore {
  constructor(ManageUsersStore) {
    makeObservable(this, {
      teamsData: observable,
      teamData: observable,
      teamFieldsDef: observable,
      scrollProps: observable,
      requestInProgress: observable,
      errors: observable,
      fetchTeamsData: action.bound,
      fetchTeamsOptions: action.bound,
      fetchTeamData: action.bound,
      createEditTeam: action.bound,
      deleteTeam: action.bound,
      resetFields: action.bound,
    });
    this.manageUsers = ManageUsersStore;
  }

  teamsData = [];

  teamData = {};

  teamFieldsDef = {};

  scrollProps = {
    hasMore: false,
    query: {
      cursor: '',
      ordering: '-id',
    },
  };

  requestInProgress = {
    teamsData: false,
    teamData: false,
    teamFieldsDef: false,
    createEditTeam: false,
  };

  errors = {
    teamsData: null,
    teamData: null,
    teamsFieldDef: null,
    createEditTeam: null,
    deleteTeam: null,
  };

  async fetchTeamsData(isOnScroll) {
    this.requestInProgress.teamsData = true;
    try {
      const response = await fetchTeamsData();
      const { results, next } = response.data;
      const { cursor } = parseParamString(next);
      runInAction(() => {
        this.scrollProps.hasMore = !!next;
        if (next && cursor) this.scrollProps.query.cursor = cursor;
        this.teamsData = isOnScroll ? [...this.teamsData, ...results] : results;
        this.requestInProgress.teamsData = false;
      });
    } catch (err) {
      runInAction(() => {
        this.requestInProgress.teamsData = false;
      });
      handleErrorResponse(err);
    }
  }

  async fetchTeamsOptions() {
    this.requestInProgress.teamFieldsDef = true;
    try {
      const response = await fetchTeamsOptions();
      runInAction(() => {
        this.teamFieldsDef = omit(response.data.actions.POST, 'id');
        this.requestInProgress.teamFieldsDef = false;
      });
    } catch (err) {
      runInAction(() => {
        this.errors.teamsFieldDef = err.response.data;
        this.requestInProgress.teamFieldsDef = false;
      });
      handleErrorResponse(err);
    }
  }

  async fetchTeamData(teamId) {
    this.requestInProgress.teamData = true;
    try {
      const teamResponse = await fetchTeamData(teamId);
      const teamData = formatTeamData(teamResponse.data);
      stores.forms.createEditTeam.setFieldsData(teamData);
      runInAction(() => {
        this.teamData = teamData;
        this.requestInProgress.teamData = false;
      });
    } catch (err) {
      runInAction(() => {
        this.requestInProgress.teamData = false;
      });
      handleErrorResponse(err);
    }
  }
  // Create/Edit team sidepanel actions

  async createEditTeam({ id, errorMsg, successMsg, cb }) {
    const { data, setFieldsErrors } = stores.forms.createEditTeam;
    this.requestInProgress.createEditTeam = true;
    try {
      if (id) {
        await editTeam(id, formatPayload(data));
      } else {
        await createTeam(formatPayload(data));
      }
      runInAction(() => {
        this.requestInProgress.createEditTeam = false;
      });
      this.fetchTeamsData();
      notifier.success(successMsg);
      cb();
    } catch (err) {
      runInAction(() => {
        if (err.response && err.response.data.detail) {
          notifier.error(err.response.data.detail);
        } else if (err.response) {
          this.errors.createEditTeam = err.response.data;
          setFieldsErrors(err.response.data);
        }
        this.requestInProgress.createEditTeam = false;
        handleErrorResponse(err, errorMsg);
      });
    }
  }

  async deleteTeam(id) {
    this.errors.deleteTeam = null;
    try {
      await deleteTeam(id);
    } catch (err) {
      runInAction(() => {
        this.errors.deleteTeam = err;
      });
      handleErrorResponse(err);
    }
  }

  resetFields() {
    this.teamData = {};
    stores.forms.createEditTeam.resetFieldsData();
  }
}

function formatTeamData(data) {
  if (data.parent) {
    return {
      ...data,
      parent: {
        value: data.parent.id,
        display_name: data.parent.name,
      },
    };
  }
  return data;
}
