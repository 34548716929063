import React from 'react';
import { any, object } from 'prop-types';


const DefaultCell = ({ value }) => {
  const displayValue = (value instanceof Object) ? JSON.stringify(value) : value;

  return (
    <div className="truncate-text">
      {displayValue || '-'}
    </div>
  );
};

DefaultCell.propTypes = { value: any };
DefaultCell.defaultProps = { value: null };
export default DefaultCell;
